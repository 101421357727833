import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import LeadsInsightsChart from '../charts/lead_insights_chart';
import { ILeadTotals, LeadMetric } from '../../interfaces/leads';
import LeadInsightsTabs from '../leads/lead_insights_tabs';
import Select from 'react-select';
import { getTemplateTypes } from '../../api/email';
import {
    getPropertyLeads,
    getLeadTotals
} from '../../api/leads';
import LeadInsightsTable from '../leads/lead_insights_table';
import { ISessionState } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import LeadsInsightsChartAll from '../charts/lead_insights_chart_all';
import dateFormat from 'dateformat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { LoadingDots } from '../common/loading_dots';


type Props = {
    selNHID: number;
    showTable?: boolean;
};

export const LeadsWidget = (props: Props) => {
    const location = useLocation();
    const path = location.pathname;

    const { Session, Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const isOrgMulti = Session?.SessionType === 'org' || Properties?.length > 1;

    const [metric, setMetric] = useState<LeadMetric>('All');

    const [filters, setFilters] = useState<any>({
        DataPoint: 'month'
    });

    const [templateTypes, setTemplateTypes] = useState<any[]>([]);

    const [totals, setTotals] = useState<ILeadTotals>({
        PageViews: 0,
        PhoneNumberViews: 0,
        WebsiteReferrals: 0,
        EmailInquiries: 0,
        BrochureInquiries: 0,
        TourRequests: 0
    });

    const [busy, setBusy] = useState<boolean>(false);

    const dataPointOptions = [
        {
            label: 'Month',
            value: 'month'
        },
        {
            label: 'Week',
            value: 'week'
        },
        {
            label: 'Day',
            value: 'day'
        }
    ];

    const updateFilter = (key: string, value: any) => setFilters((prev: any) => ({
        ...prev,
        [key]: value
    }));

    const resetFilter = (key: string) => {
        const curr = {...filters};
        delete curr[key];
        setFilters(curr);
    };

    const getTotals = async () => {
        const params: any = {};

        if (props.selNHID) {
            params.NHIDs = [props.selNHID];
            params.IsSingleProperty = true;
        }

        if (filters.DateFrom) {
            params.DateFrom = filters.DateFrom;
        }

        if (filters.DateTo) {
            params.DateTo = filters.DateTo;
        }

        const data = await getLeadTotals(params);
        setTotals(data);
    };

    const getTypeOptionsData = async () => {
        const resp = await getTemplateTypes({
            IsLeadEnquiry: true
        });

        const data = resp.map((type: any) => ({
            label: type.Name,
            value: type.TemplateTypeID
        }));

        setTemplateTypes(data);
    };

    const [data, setData] = useState<any[]>([]);

    const getData = async () => {
        setBusy(true);
        const params = {...filters};

        if (props.selNHID) {
            params.NHIDs = [props.selNHID];
            params.IsSingleProperty = true;
        }

        const leadData = await getPropertyLeads(params);
        setData(leadData);
        setBusy(false);
    };

    useEffect(() => {
        getTotals();
        getData();
    }, [filters, props.selNHID]);

    useEffect(() => {
        getTypeOptionsData();
    }, []);

    useEffect(() => {
        if (!props.selNHID) {
            resetFilter('NHIDs');
            return;
        }

        updateFilter('NHIDs', [props.selNHID]);
    }, [props.selNHID]);

    return (
        <>
            { path === '/leads' ? 
                <>

                    <div className="ring-1 ring-brand_grey rounded-xl p-3 sm:p-4 bg-brand_faint-blue mb-4 sm:mb-6 md:mb-8 lg:mb-10 flex flex-wrap gap-3 items-center lg:w-fit lg:mx-auto lg:gap-8">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 items-center sm:gap-4 w-full lg:w-auto">
                            <div className="form-component form-inline form-datepicker lg:min-w-64">
                                <label
                                    htmlFor="DateFrom"
                                >
                                    Date From
                                </label>
                                <DatePicker
                                    id="DateFrom"
                                    selected={filters.DateFrom}
                                    onChange={(date: Date) => {
                                        date.setHours(0, 0, 0, 0);
                                        const dateStr = dateFormat(date, 'yyyy-mm-dd');
                                        updateFilter('DateFrom', new Date(dateStr));
                                    }}
                                    dateFormat="dd MMM yyyy"
                                    placeholderText="Click to select a date"
                                    value={filters.DateFrom as any}
                                    showPopperArrow={false}
                                />
                            </div>
                            <div className="form-component form-inline form-datepicker lg:min-w-64">
                                <label
                                    htmlFor="DateTo"
                                >
                                    Date To
                                </label>
                                <DatePicker
                                    id="DateTo"
                                    selected={filters.DateTo}
                                    onChange={(date: Date) => {
                                        date.setHours(0, 0, 0, 0);
                                        const dateStr = dateFormat(date, 'yyyy-mm-dd');
                                        updateFilter('DateTo', new Date(dateStr));
                                    }}
                                    dateFormat="dd MMM yyyy"
                                    placeholderText="Click to select a date"
                                    value={filters.DateTo as any}
                                    minDate={filters.DateFrom}
                                    showPopperArrow={false}
                                />
                            </div>
                            <div className="form-component form-inline form-select lg:min-w-64">
                                <label
                                    htmlFor="DataPoint"
                                >
                                    Show leads by
                                </label>
                                <Select 
                                    options={dataPointOptions}
                                    id="DataPoint"
                                    onChange={(option) => updateFilter('DataPoint', option.value)}
                                    value={dataPointOptions.find((item) => item.value === filters.DataPoint)}
                                    isSearchable={false}
                                    classNamePrefix="select"
                                    unstyled
                                />
                            </div>
                        </div>
                        <button
                            className="ms-auto btn btn-outline btn-outline-tertiary btn-small flex items-center text-md"
                            onClick={() => {
                                updateFilter('DateFrom', null);
                                updateFilter('DateTo', null);
                            }}
                        >
                            <FontAwesomeIcon icon={faXmark} className="me-1" />Reset
                        </button>
                    </div>
                    <div className="mt-4 sm:mt-6 md:mt-8 2xl:mt-10 ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg p-3 sm:p-4 2xl:p-5">
                        <div className="[&>ul]:flex [&>ul]:flex-wrap [&>ul]:items-stretch [&>ul]:justify-start [&>ul]:gap-2 [&>ul>li]:flex-[1_1] [&>ul>li]:max-w-28 [&>ul>li]:min-w-20 [&>ul>li]:min-h-20 sm:[&>ul]:justify-between sm:[&>ul>li]:max-w-none lg:[&>ul]:justify-center lg:[&>ul>li]:max-w-36 lg:[&>ul]:gap-4">   
                            <LeadInsightsTabs
                                metric={metric}
                                handleChange={(metric: LeadMetric) => setMetric(metric)}
                                totals={totals}
                                selNHID={props.selNHID}
                            />
                        </div>
                        <div className="xl:[&>div]:h-[36rem] 2xl:[&>div]:h-[40rem]">
                            {
                                busy
                                ? <div className="h-full w-full flex items-center justify-center">
                                    <LoadingDots showInPlace={true} show={busy} />
                                </div>
                                : metric === 'All' ? 
                                <LeadsInsightsChartAll
                                    filters={filters}
                                    data={data}
                                />
                                : 
                                <LeadsInsightsChart
                                    data={data}
                                    metric={metric}
                                    filters={filters}
                                />
                            }
                        </div>
                    </div>
                    {
                        props.showTable ? 
                            <LeadInsightsTable
                                data={data}
                                dataPoint={filters.DataPoint}
                                totals={totals}
                                filters={filters}
                                templateTypes={templateTypes}
                                selNHID={props.selNHID}
                            />
                        : null
                    }
                </>
            : 
                <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col">
                    <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 min-h-14 flex items-center justify-between gap-2">
                        <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                            Leads
                        </h4>
                        <div className="form-component form-select !flex-row items-center gap-2">
                            <label
                                className="!font-medium !m-0"
                                htmlFor="DataPoint"
                            >
                                Show leads by
                            </label>
                            <Select 
                                className="w-28 h-10"
                                classNamePrefix="select"
                                options={dataPointOptions}
                                id="DataPoint"
                                onChange={(option) => updateFilter('DataPoint', option.value)}
                                value={dataPointOptions.find((item) => item.value === filters.DataPoint)}
                                isSearchable={false}
                                unstyled
                            />
                        </div>
                    </div>
                    <div className="p-3 sm:p-4 2xl:p-5 h-full">
                        <div className="flex flex-col justify-between gap-3">
                            <div className="[&>ul]:flex [&>ul]:flex-wrap [&>ul]:items-stretch [&>ul]:justify-start [&>ul]:gap-2 [&>ul>li]:flex-[1_1] [&>ul>li]:max-w-28 [&>ul>li]:min-w-20 [&>ul>li]:min-h-20 sm:[&>ul]:justify-between sm:[&>ul>li]:max-w-none lg:[&>ul]:justify-start lg:[&>ul>li]:max-w-28">
                                <LeadInsightsTabs
                                    metric={metric}
                                    handleChange={(metric: LeadMetric) => setMetric(metric)}
                                    totals={totals}
                                    selNHID={props.selNHID}
                                />
                            </div>
                            <div className="xl:[&>div]:h-[29rem] 2xl:[&>div]:h-[28rem]">
                                {
                                    busy
                                    ? <div className="h-full w-full flex items-center justify-center">
                                        <LoadingDots showInPlace={true} show={busy} />
                                    </div>
                                    : metric === 'All' ? 
                                    <LeadsInsightsChartAll
                                        filters={filters}
                                        data={data}
                                    />
                                    : 
                                    <LeadsInsightsChart
                                        data={data}
                                        metric={metric}
                                        filters={filters}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="p-3 sm:p-4 2xl:p-5 !pt-0 mt-auto flex flex-wrap justify-end gap-3">
                        { isOrgMulti ? 
                            <NavLink to="/leadbreakdown" className="btn min-w-44 text-center">
                                View Breakdown
                            </NavLink> : null
                        }
                        <NavLink to="/leads" className="btn min-w-44 text-center">
                            View Overview
                        </NavLink> 
                    </div>
                </div>
            }        
        </>
    );
};
