import React from 'react';
import { FormInput } from '../../../../../../components/common/form_input';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { FormCheckbox } from '../../../../../../components/common/form_checkbox';
import { onlyNumeric, yesNoOptions } from '../../../helpers';
import { useFormContext } from 'react-hook-form';
import { ProfileCompletenessTag } from '../../components/profile_completeness_tag';
import { SectionHeading } from '../../components/section_heading';
import { RoomTypeLink } from '../../components/room_type_link';

const LongTermCareCostTab = () => {
    const { watch, setValue } = useFormContext();

    const isLongTermCareSelected = watch('LongTermCare') === 'Yes';
    const isRoomTypeSharedSelected = watch('RoomTypeShared') === 'Yes';
    const isRoomTypePrivateRoomSelected = watch('RoomTypePrivateRoom') === 'Yes';
    const isRoomTypeSemiPrivateSelected = watch('RoomTypeSemiPrivate') === 'Yes';
    const isRoomTypePrivateSuiteSelected = watch('RoomTypePrivateSuite') === 'Yes';

    const isLongTermSharedCostPerDayNAChecked = watch('LongTermSharedCostPerDayNA');
    const isLongTermPrivateCostPerDayNAChecked = watch('LongTermPrivateCostPerDayNA');
    const isLongTermSemiPrivateCostPerDayNAChecked = watch('LongTermSemiPrivateCostPerDayNA');

    const canUseSharedRoomLongTerm = isLongTermCareSelected && isRoomTypeSharedSelected;
    const canUsePrivateRoomLongTerm = isLongTermCareSelected && isRoomTypePrivateRoomSelected;
    const canUseSemiPrivateRoomLongTerm = isLongTermCareSelected && isRoomTypeSemiPrivateSelected;
    const canUsePrivateSuiteLongTerm = isLongTermCareSelected && isRoomTypePrivateSuiteSelected;
    const allFieldsDisabled = !(
        canUsePrivateRoomLongTerm ||
        canUseSemiPrivateRoomLongTerm ||
        canUseSharedRoomLongTerm ||
        canUsePrivateSuiteLongTerm
    );

    return (
        <>
            <SectionHeading
                title="Long Term Care Cost"
                subtitle={<>To edit the below inputs &lsquo;<a href={'/core-details'} className="link">Long Term Care</a>&rsquo; and the relevant { <RoomTypeLink/> } must be set to &lsquo;Yes&rsquo;.</>}

            />
            <div className="grid gap-3 lg:gap-5 2xl:gap-6 px-3 sm:px-4 2xl:px-5">
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    disabled={!canUseSharedRoomLongTerm || isLongTermSharedCostPerDayNAChecked}
                    info={
                        <>                       
                            <FormCheckbox 
                                name="LongTermSharedCostPerDayNA"
                                id="LongTermSharedCostPerDayNA"
                                label="Not Applicable"
                                disabled={!canUseSharedRoomLongTerm}
                                onChange={() => setValue('LongTermSharedCostPerDay', '')}
                            />
                            <ProfileCompletenessTag
                                isVisible={canUseSharedRoomLongTerm && !isLongTermSharedCostPerDayNAChecked}
                            />
                        </>                     
                    }
                    inlineLabel={false}
                    label="Shared room"
                    maxWidth='5rem'
                    name="LongTermSharedCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    disabled={!canUsePrivateRoomLongTerm || isLongTermPrivateCostPerDayNAChecked}
                    info={
                        <>
                            <FormCheckbox 
                                name="LongTermPrivateCostPerDayNA"
                                id="LongTermPrivateCostPerDayNA"
                                label="Not Applicable"
                                onChange={() => setValue('LongTermPrivateCostPerDay', '')}
                                disabled={!canUsePrivateRoomLongTerm}
                            />
                            <ProfileCompletenessTag
                                isVisible={canUsePrivateRoomLongTerm && !isLongTermPrivateCostPerDayNAChecked}
                            />
                        </>                     
                    }
                    inlineLabel={false}
                    label="Private room"
                    maxWidth='5rem'
                    name="LongTermPrivateCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    disabled={!canUseSemiPrivateRoomLongTerm || isLongTermSemiPrivateCostPerDayNAChecked}
                    info={
                        <>
                            <FormCheckbox 
                                name="LongTermSemiPrivateCostPerDayNA"
                                id="LongTermSemiPrivateCostPerDayNA"
                                label="Not Applicable"
                                onChange={() => setValue('LongTermSemiPrivateCostPerDay', '')}
                                disabled={!canUseSemiPrivateRoomLongTerm}
                            />
                            <ProfileCompletenessTag
                                isVisible={canUseSemiPrivateRoomLongTerm && !isLongTermSemiPrivateCostPerDayNAChecked}
                            />
                        </>                     
                    }
                    inlineLabel={false}
                    label="Semi-private room"
                    maxWidth='5rem'
                    name="LongTermSemiPrivateCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    disabled={!canUsePrivateSuiteLongTerm}
                    info={
                        <ProfileCompletenessTag
                            isVisible={false}
                        />
                    }
                    label="Private suite"
                    maxWidth='5rem'
                    name="LongTermPrivateSuiteCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormRadioGroup
                    disabled={allFieldsDisabled}
                    groupLabel="Rates are all-inclusive?"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    layout="horizontal"
                    name="LongTermRatesAllInclusive"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    disabled={allFieldsDisabled}
                    groupLabel="Rate packages/bundles available?"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    layout="horizontal"
                    name="LongTermRatePackagesAvailable"
                    options={yesNoOptions}
                    showResetButton={true}
                />
            </div>
        </>
    );
};

export { LongTermCareCostTab };
