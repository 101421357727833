import { useState, useEffect } from 'react';
import { getPropertyBrochures } from '../../../../../property_media/property_brochures/utils';

export const usePropertyBrochures = (nhID: number) => {
    const [propertyBrochures, setPropertyBrochures] = useState<any[]>([]);

    useEffect(() => {
        const loadBrochures = async () => {    
            const brochures = await getPropertyBrochures([nhID]);
            setPropertyBrochures(brochures);
        };
        loadBrochures();
    }, [nhID]);

    return { propertyBrochures, setPropertyBrochures };
}; 