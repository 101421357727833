import React, { useState } from 'react';
import { IContentOverviewLinkProps } from '../../../../interfaces/property';
import { Modal } from './profile_content_overview_modals/modal_factory';

const PropertyProfileContentOverviewModalLink = ({ nhID, keyName }: IContentOverviewLinkProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <a 
                className="link text-xs bg-brand_faint-blue px-2 py-1 inline-block leading-none rounded-lg relative -top-px"
                href="#" 
                onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen(true);
                }}>
                Update
            </a>

            {isModalOpen && (
                <Modal 
                    props={{ 
                        nhID, 
                        keyName, 
                        isOpen: isModalOpen, 
                        onClose: () => setIsModalOpen(false) 
                    }} 
                />
            )}
        </>
    );
};

export { PropertyProfileContentOverviewModalLink };
