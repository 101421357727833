import React, { useContext } from 'react';
import FilterMatrix from '../../modules/filter_matrix';
import { FilterType } from '../../interfaces/filters';
import ActiveFilters from '../../components/common/active_filters';
import ReviewFilters from '../../components/reviews/review_list_filters/review_filters';
import { getReviews, getReviewsOverview } from '../../api/review';
import VisibleRatingStars from '../../components/common/visible_rating_stars';
import CsvExport, { DisplayType } from '../../components/csv_export/csv_export';
import moment from 'moment';
import { formatFileNameForCsvExport } from '../../utils/file';
import { get } from 'lodash';
import { ReviewPublicationStatusType, IReviewsOverview, IReviewFilters } from '../../interfaces/review';
import { IPortalReplyItem, IReply } from '../../interfaces/reply';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

import {
    createReply,
    updateReply
} from '../../api/review';

import AppContext from '../../context/app_context';
import { NavLink } from 'react-router-dom';
import type { TableColumn } from 'react-data-table-component';
import ReviewScoreDisplayFormatted from '../../components/common/review_score_display_formatted';
import { FilterButton } from '../../components/common/filter_button';

const replystringTextFormatter = (filter: any) => {

    const filterValue = filter.Value === 'true' 
        ? 'Has a Reply (Published or Pending Verification)'
        : 'Has No Reply';

    return `${filter.FilterFormLabel}: ${filterValue}`;
};

export const createFilters = () => {
    return new FilterMatrix([
        { Key: 'DateSubmittedFrom', FilterFormLabel: 'Date Submitted From' , Type: FilterType.DateFromUTC, FilterPropName: 'DateFrom'},
        { Key: 'DateSubmittedTo', FilterFormLabel: 'Date Submitted To' , Type: FilterType.DateToUTC, FilterPropName: 'DateTo'},
        { Key: 'DatePublishedFrom', FilterFormLabel: 'Date Published From' , Type: FilterType.DateFromUTC, FilterPropName: 'LastPublishedDateFrom'},
        { Key: 'DatePublishedTo', FilterFormLabel: 'Date Published To' , Type: FilterType.DateToUTC, FilterPropName: 'LastPublishedDateTo'},
        { Key: 'PublicationStatus', FilterFormLabel: 'Reason not published' , Type: FilterType.LabelValue, FilterPropName: 'PublicationAuxiliaryStatusID'},
        { Key: 'ReviewerConnections', FilterFormLabel: 'Reviewer Connection' , Type: FilterType.LabelValue, FilterPropName: 'ReviewerConnectionIDs'},
        { Key: 'ReviewExperience', FilterFormLabel: 'Review Experience' , Type: FilterType.LabelValue, FilterPropName: 'ExperienceTypeIDs'},
        { Key: 'CareTypes', FilterFormLabel: 'Reviewer Care Type' , Type: FilterType.LabelValue, FilterPropName: 'CareTypeID'},
        { Key: 'ReviewText', FilterFormLabel: 'Review Text' , Type: FilterType.String, FilterPropName: 'ReviewText'},
        { Key: 'ReviewerDisplayName', FilterFormLabel: 'Reviewer Display Name', Type: FilterType.String, FilterPropName: 'ReviewerDisplayName'},
        { Key: 'HasReply', FilterFormLabel: 'Reply Status', Type: FilterType.String, FilterPropName: 'HasReply', Options: { stringTextFormatter: replystringTextFormatter }},

        { Key: 'OverallRating', FilterFormLabel: 'Overall Experience Rating' , Type: FilterType.Range, FilterPropName: 'OverallRatingExpr'},
        { Key: 'CleanlinessRating', FilterFormLabel: 'Cleanliness Rating' , Type: FilterType.Range, FilterPropName: 'CleanlinessRatingExpr'},
        { Key: 'FriendlinessRating', FilterFormLabel: 'Friendliness Rating' , Type: FilterType.Range, FilterPropName: 'FriendlinessRatingExpr'},
        { Key: 'SafetySecurity', FilterFormLabel: 'Safety / Security Rating' , Type: FilterType.Range, FilterPropName: 'SafetySecurityRatingExpr'},
        { Key: 'NursingCareRating', FilterFormLabel: 'Nursing Care Rating' , Type: FilterType.Range, FilterPropName: 'NursingCareRatingExpr'},
        { Key: 'StaffRating', FilterFormLabel: 'Staff Rating' , Type: FilterType.Range, FilterPropName: 'StaffRatingExpr'},
        { Key: 'DiningRating', FilterFormLabel: 'Meals & Dining Rating' , Type: FilterType.Range, FilterPropName: 'DiningRatingExpr'},
        { Key: 'ActivitiesRating', FilterFormLabel: 'Activities Rating' , Type: FilterType.Range, FilterPropName: 'ActivitiesRatingExpr'},
        { Key: 'RehabTherapyRating', FilterFormLabel: 'Rehab / Therapy Rating' , Type: FilterType.Range, FilterPropName: 'RehabTherapyRatingExpr'},
        { Key: 'FacilitiesRating', FilterFormLabel: 'Facilities Rating' , Type: FilterType.Range, FilterPropName: 'FacilitiesRatingExpr'},

        { Key: 'NHID', FilterFormLabel: 'NHID' , Type: FilterType.Number, FilterPropName: 'NHID', Hidden: true},
        { Key: 'ReviewID', FilterFormLabel: 'Review ID' , Type: FilterType.Number, FilterPropName: 'ReviewID'}
    ]);
};

export const getReviewDataParams = (
    requestParams: any,
    pageOffset: number, 
    pageLimit: number,
    sortField: string,
    sortOrder: string,
    publicationStatusType: ReviewPublicationStatusType,
    forExport: boolean
) => {

    return forExport ? requestParams : {
        ...requestParams,         
        PublicationStatusKeyName: publicationStatusType,
        Offset: pageOffset,
        Limit: pageLimit,
        SortOrder: sortOrder || 'DESC',
        SortField: sortField || 'LastPublishedDate'
    };
};

export const createOrUpdateReply = async (reviewID: number, replyData: IReply)
    : Promise<IPortalReplyItem> => {

    const saveFn = replyData.ReplyID ? updateReply : createReply;

    const savedReply = await saveFn(reviewID, replyData);

    const {
        ReplyID,
        ReplierEmail,
        ReplierFirstName,
        ReplierLastName,
        ReplierPosition,
        ReplyContent,
        ReplyStatusReply
    } = savedReply;

    const AuxiliaryPublicationStatusID = get(ReplyStatusReply, 'ReplyAuxiliaryStatusID', null);
    const AuxiliaryStatusKeyName = get(ReplyStatusReply, 'ReplyAuxiliaryStatus.KeyName');
    const AuxiliaryPublicationStatusName  = get(ReplyStatusReply, 'ReplyAuxiliaryStatus.ExternalDescription');

    const reviewReply: IPortalReplyItem = {
        ReplyID,
        ReplierEmail,
        ReplierFirstName,
        ReplierLastName,
        ReplierPosition,
        ReplyContent,
        AuxiliaryPublicationStatusID,
        AuxiliaryStatusKeyName,
        AuxiliaryPublicationStatusName,
    };

    return reviewReply;
};

export const fetchReviewOverviewData = async (params: IReviewFilters) => {

    const resData = await getReviewsOverview(params);

    return resData.data;
};

export const fetchReviewData = async (params: any): Promise<[any[], number, number]> => {

    const data = await getReviews(params);

    const rowData = get(data, 'data', []);
    const totalFilteredRows = get(data, 'totalFilteredRows', 0);
    const totalRows = get(data, 'totalRows', 0);
    const tableData = rowData.map((item: any) => ({
        id: item.ReviewID,
        ...item,
        defaultExpanded: true
    }));

    return [tableData, totalRows, totalFilteredRows];
};

export const buildColumns = (mode: 'published' | 'pending') => {

    const appContext: any = useContext(AppContext);

    const cols = [
        {
            name: 'Review ID',
            selector: (row: any) => <div>{row.ReviewID} </div>,
            sortable: true,
            sortField: 'ReviewID',
            width: '*'
        },
        {
            name: 'Facility Name',
            selector: (row: any) => <div>{row.Name} </div>,
            sortable: true,
            sortField: 'PropertyName',
            wrap: 1,
            id: 'community',
            width: '*'
        },
        {
            name: 'Display Name',
            selector: (row: any) => <div>{row.ReviewerDisplayName}</div>,
            sortable: true,
            sortField: 'ReviewerDisplayName',
            wrap: 1,
            id: 'name',
            width: '*'
        },
        {
            name: 'Submitted Date',
            cell: (row: any) => <>{ moment(row.CreatedAt).format('MMM DD YYYY') }</>,
            sortable: true,
            sortField: 'CreatedAt',
            id: 'submitted',
            width: '*'
        },
       
       
    ];

    // cols.push( {
    //     name: `${published ? 'Publication Date' : 'Reason Not Published'}`,
    //     cell: (row: any) => <div>
    //         <span className='label label-dot label-success mr-2'></span>
    //         <span>
    //             { published ? moment(row.LastPublishedDate).format('MMMM Do YYYY h:mm a') : row.PublicationAuxiliaryExternalDescription }
    //         </span>
    //     </div>,
    //     sortable: true,
    //     sortField: 'LastPublishedDate',
    //     width: `${published ? '250px' : '220px'}`,
    //     id: 'published'
    // });

    if (mode === 'published') {
        cols.push( {
            name: 'Publication Date',
            cell: (row: any) => <>{ moment(row.LastPublishedDate).format('MMM DD YYYY h:mma') }</>,
            sortable: true,
            sortField: 'LastPublishedDate',
            id: 'published',
            width: '*'
        });
    }

    if (mode === 'pending') {
        cols.push( {
            name: 'Pending Publication Date',
            cell: (row: any) => <>{ moment(row.PendingPublicationDate).utc().format('MMM DD YYYY') }</>,
            sortable: true,
            sortField: 'PendingPublicationDate',
            id: 'pending',
            width: '*'
        });
    }

    cols.push( {
        name: 'Overall Experience',
        cell: (row: any) => 
        <VisibleRatingStars 
            keyProp={row.ReviewID} 
            value={row.OverallRating}
        />,
        sortable: true,
        sortField: 'OverallRating',
        id: 'ratings',
        width: '*'
    });

    return cols;
};

export const buildOverviewColumns = () => {
    const appContext: any = useContext(AppContext);
    const cols: TableColumn<IReviewsOverview>[] = [
        {
            name: 'Facility Name',
            selector: (row) => row.Name,
            cell: (row) => <div className="py-2">
                <div><strong>{row.Name}</strong></div>
                <div>{row.AddressFormatted}</div>
            </div>,
            sortable: true,
            width: appContext.isMobile ? '180px' : '270px',
            wrap: true,
        },
        {
            name: 'Total Number of Published Reviews',
            selector: (row) => row.TotalReviewCount || 0,
            cell: (row) => <div>
                {
                    row.TotalReviewCount ?
                        <NavLink 
                            className="link" 
                            to='/reviews'
                            state={{ 
                                filters: { 
                                    NHID: row.NHID,
                                }
                            }}
                        >
                            {row.TotalReviewCount}
                        </NavLink>
                    : 
                        '0'
                }
            </div>,
            sortable: true,
            width: appContext.isMobile ? '180px' : '200px',
        },
        {
            name: 'Number of Published Reviews in last 12 months',
            selector: (row) => row.TotalReviewWithin12MonthsCount || 0,
            cell: (row) => <div>
                {
                    row.TotalReviewWithin12MonthsCount ?
                        <NavLink 
                            className="link" 
                            to='/reviews'
                            state={{ 
                                filters: { 
                                    NHID: row.NHID,
                                    DatePublishedFrom: moment().subtract(12, 'months').toLocaleString()
                                }
                            }}
                        >
                            {row.TotalReviewWithin12MonthsCount}
                        </NavLink>
                    :
                        '0'
                }
            </div>,
            sortable: true,
            width: appContext.isMobile ? '180px' : '200px',
        },
        {
            name: 'Date of Last Published Review',
            selector: (row) => row.MostRecentReviewDate || '',
            cell: (row) => <div>
                <span className='label label-dot label-success mr-2'></span>
                <span>
                    { moment(row.MostRecentReviewDate).isValid() ? moment(row.MostRecentReviewDate).format('Do MMM YYYY') : 'N/A' }
                </span>
            </div>,
            sortable: true,
            width: '250px',
        },
        {
            name: 'Published Reviews in Last 90 days with No Reply',
            selector: (row) => row.TotalReviewsWithin90DaysNoReply || 0,
            cell: (row) => <div>
                {
                    row.TotalReviewsWithin90DaysNoReply ?
                        <NavLink 
                            className="link"
                            to='/reviews'
                            state={{ 
                                filters: { 
                                    NHID: row.NHID,
                                    HasReply: false,
                                    DatePublishedFrom: moment().subtract(90, 'days').toLocaleString()
                                }
                            }}
                        >
                            {row.TotalReviewsWithin90DaysNoReply}
                        </NavLink>
                    : 
                        '0'
                }
            </div>,
            sortable: true,
            width: appContext.isMobile ? '180px' : '200px',
        },
        {
            name: 'Review Score',
            selector: (row) => row.ReviewScoreDisplay || 0,
            cell: (row) => row.ReviewScoreDisplay ? (
                <div className="flex flex-col justify-items-center items-center py-2">
                    <ReviewScoreDisplayFormatted 
                        value={row.ReviewScoreDisplay}
                        showAsNotApplicableOnNull={true}
                    />
                    <NavLink 
                        className="link"
                        to='/review-score'
                        state={{ selNHID: row.NHID, filters: { NHID: row.NHID } } }
                    >
                        <strong>View Breakdown</strong>
                    </NavLink>
                </div>
            ) : <div className="py-2">{'N/A'}</div>,
            sortable: true,
            width: appContext.isMobile ? '180px' : '200px',
        },
        {
            name: 'Generate Reviews',
            cell: (row) => <div className='py-2' style={{ display: 'flex', flexDirection: 'column' }}>
                <NavLink 
                    className="link"
                    to='/review-cards'
                    state={{ selNHID: row.NHID, NHID: row.NHID } }
                >
                    <strong>Order Cards</strong>
                </NavLink>
                <NavLink 
                    className="link"
                    to='/invite-to-review'
                    state={{ NHID: row.NHID } }
                >
                    <strong>Invite to Review</strong>
                </NavLink>
            </div>,
            sortable: false,
            width: appContext.isMobile ? '180px' : '200px',
        },
    ];
    return cols;
};

export const renderFilters = (
    filterMatrix: FilterMatrix,
    applyFilter: any,
    getReviewData: any,
    published: boolean,
    totalReviewCount: number,
    totalFilteredReviewCount: number,
    busy: boolean,
    pageOffset: number,
    pageLimit: number,
    startExport: any,
    filtersOpen = false,
    resetFilters: () => void,
    setFiltersOpen?: (open: boolean) => void,
    applyReviewScoreFilters?: () => void
) => {

    return (
        <div>
            <input type="checkbox" className="hidden peer/filters" id="filters" />
            <div className="hidden peer-checked/filters:block relative ring-1 ring-brand_grey rounded-xl p-3 sm:p-4 bg-brand_faint-blue mb-4 sm:mb-6 md:mb-8 lg:mb-10">
                <div className="flex items-center mb-6">
                    <strong className="text-lg md:text-xl">
                        Filter Results
                    </strong>
                    <button
                        className="ms-4 btn btn-outline btn-outline-tertiary btn-small flex items-center text-md"
                        onClick={resetFilters}
                    >
                        <FontAwesomeIcon icon={faXmark} className="me-1" />Clear all filters
                    </button>
                    <label htmlFor="filters" className="ms-auto cursor-pointer rounded-full bg-brand_tertiary hover:bg-brand_tertiary-dark text-white w-8 h-8 flex justify-center items-center">
                        <FontAwesomeIcon icon={faXmark} />
                    </label>
                </div>
                <ReviewFilters
                    filterMatrix={filterMatrix}
                    applyFilter={applyFilter}
                    refreshData={getReviewData}
                    published={published}
                    applyReviewScoreFilters={applyReviewScoreFilters}
                />
            </div>
            <div className="flex flex-col items-center text-center gap-3 md:flex-row md:text-start">
                <ActiveFilters 
                    filterMatrices={[filterMatrix]} 
                    totalFilteredSize={totalFilteredReviewCount}
                    totalSize={totalReviewCount}
                    isLoading={busy}
                    pageOffset={pageOffset}
                    pageLimit={pageLimit}
                    singularNoun={'Published Review'}
                    pluralNoun={'Published Reviews'}
                    showTotals={true}
                    applyFilter={applyFilter}
                    showResetLink={true}
                    refreshData={getReviewData}
                />
                <div className="md:ms-auto flex flex-wrap justify-center md:justify-end gap-3 lg:flex-nowrap">
                    <FilterButton />
                    <CsvExport 
                        startExportFunction={startExport}
                        modalTitleSuffix={'Reviews'}
                        label="Export as CSV"
                        displayType={DisplayType.Button}
                        fileName={`${formatFileNameForCsvExport('review_export')}`}
                    />
                </div>
            </div>
        </div>
    );
};

export const buildLoadingMessage = (filterMatrix: FilterMatrix) => {

    const numAppliedFilters = filterMatrix.getActiveCount();

    const msg = numAppliedFilters > 0 ? 
        `Applying ${numAppliedFilters} ${numAppliedFilters === 1 ? 'filter' : 'filters'} - see results below`
        : 'Fetching reviews - see results below';

    return msg;
};