import React from 'react';
import { Modal } from '../../components/common/modal';
import IndividualPropertyBrochures from './individual_property_brochures';

import { 
    IPortalPropertyAsset
 } from '../../interfaces/asset';

interface IProps {
    handleClose(): void;
    handleReloadPropertyBrochures: (nhID: number[], orgNHID?: number) => Promise<IPortalPropertyAsset[]>;
    orgNHID: number | null;
    nhIDs: number[];
    selNHID: number | null;
    propertyBrochures: IPortalPropertyAsset[];
    setPropertyBrochures: (propertyBrochures: IPortalPropertyAsset[]) => void;
    busyLoadingAsset: boolean;
    setBusyLoadingAsset: (busyState: boolean) => void;
}

const BrochureUploadModal = ({
    handleClose,
    handleReloadPropertyBrochures,
    orgNHID,
    nhIDs,
    selNHID,
    propertyBrochures,
    setPropertyBrochures,
    busyLoadingAsset,
    setBusyLoadingAsset 
}: IProps) => {

    const propertyName = (propertyBrochures || []).length > 0 ? propertyBrochures[0].Property.Name : '';

    return (
        <>
            <Modal
                isOpen={true}
                onClose={handleClose}
                title='Upload a New PDF Brochure'
                subTitle={propertyName}
                size='xl'
            >
                <IndividualPropertyBrochures
                    handleReloadPropertyBrochures={handleReloadPropertyBrochures}
                    orgNHID={orgNHID}
                    nhIDs={nhIDs}
                    selNHID={selNHID}
                    propertyBrochures={propertyBrochures}
                    setPropertyBrochures={setPropertyBrochures}
                    busyLoadingAsset={busyLoadingAsset}
                    setBusyLoadingAsset={setBusyLoadingAsset}
                    handleClose={handleClose}
                />
            </Modal>
        </>
    );
};

export default BrochureUploadModal;