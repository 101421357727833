import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { LoadingDots } from '../common/loading_dots';
import DisplayAsset from '../../components/assets/display_asset';
import UploadWidget from '../../components/cloudinary/upload_widget_crop';
import * as assetApi from '../../api/assets';
import Swal from 'sweetalert2';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';


import { 
    NHAssetType,
    ICloudinaryData,
    IAssetUploadLog,
    IAssetPayLoad,
    IGetPortalPropertyWebAndCardLogoAsset
 } from '../../interfaces/asset';
import { updateProfileCompleteness } from '../../utils/profile_completeness';

interface IProps {
    handleReloadPropertyLogos: (nhID: number[], orgNHID?: number) => Promise<IGetPortalPropertyWebAndCardLogoAsset[]>;
    orgNHID?: number | null;
    nhIDs?: number[];
    selNHID: number | null;
    propertyLogos: IGetPortalPropertyWebAndCardLogoAsset[];
    setPropertyLogos: (logos: IGetPortalPropertyWebAndCardLogoAsset[]) => void;
    busyLoadingAsset: boolean;
    setBusyLoadingAsset: (busyState: boolean) => void;
    handleClose?: any;
}

const IndividualPropertyLogo = ({
    handleReloadPropertyLogos,
    orgNHID,
    nhIDs,
    selNHID,
    propertyLogos,
    setPropertyLogos,
    busyLoadingAsset,
    setBusyLoadingAsset,
    handleClose
}: IProps) => {

    const refreshNHID = orgNHID ? [orgNHID] : nhIDs ? nhIDs : [selNHID];

    const [ uploadLogoType, setUploadLogoType ] = useState<number|null>(null);
    const [ isUploadLogoTypeSelected, setIsUploadLogoTypeSelected ] = useState<boolean>(false);
   
    const handleAssetUpload = async(cloudinaryData: ICloudinaryData): Promise<any> => {
        const assetPayLoad: IAssetPayLoad = { 
            NHID: selNHID,
            AssetType: uploadLogoType === NHAssetType.WebAndCardLogos ? NHAssetType.Logos : uploadLogoType,
            ...cloudinaryData,
            CreateCardLogoFromWebLogo: uploadLogoType === NHAssetType.WebAndCardLogos ?  true : false
        };
        const assetID = await assetApi.createAsset(assetPayLoad);
        return {
            AssetID: assetID,
            TransformedAssetUrl: cloudinaryData.TransformedAssetUrl,
            Sequence: cloudinaryData.Sequence,
            FileName: cloudinaryData.TransformedAssetUrl.match(/([^/]+)(?=\.\w+$)/)[0]
        };
    };
    
    const handleShowAssetUploadResult = async(assetUploadLog : IAssetUploadLog[]) => {
        let hasAssetUploaded = false;
        for (const item of assetUploadLog) {
            if(item.Type === 'success') {
                hasAssetUploaded = true;
                break;
            }
        }

        if( !hasAssetUploaded ) {
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while uploading the logo, please try again.',
                icon: 'warning',
                iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
                confirmButtonText: 'Okay',
                allowOutsideClick: false,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success m-1',
                }
            });
        } else {
            try {
                setBusyLoadingAsset(true);
                const updatedPropertyLogos = await handleReloadPropertyLogos(refreshNHID, orgNHID);
                setPropertyLogos(updatedPropertyLogos);
                handleClose ?. ();     
            } catch (err) {
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to upload the logo.',
                    icon: 'warning',
                    iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
                    confirmButtonText: 'Okay',
                    allowOutsideClick: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-success m-1',
                    }
                });
            } finally {
                setBusyLoadingAsset(false);
                updateProfileCompleteness();
            }
        }
    };

    const handleDelete = async(assetType: number) => {
        Swal.fire({
            title: 'Delete Logo',
            text: 'Are you sure you want to delete this logo?',
            icon: 'warning',
            iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-success m-1',
                denyButton: 'btn btn-error m-1',
                cancelButton: 'btn btn-error m-1'
            }
        }).then(async (result: any) => {
            if (result.value) {
                setBusyLoadingAsset(true);
                try {
                    let  deletedAssetID: number;
                    if(assetType === NHAssetType.Logos) {
                        deletedAssetID = await assetApi.deletePropertyLogo(propertyLogos[0]?.NHID);
                    } else if(assetType === NHAssetType.CardLogos) {
                        deletedAssetID = await assetApi.deletePropertyCardLogo(propertyLogos[0]?.NHID);
                    }
                    if (!deletedAssetID) {
                        throw new Error('Failed to delete Logo');
                    }
                    const updatedPropertyLogos = await handleReloadPropertyLogos(refreshNHID, orgNHID);
                    setPropertyLogos(updatedPropertyLogos);
                 
                    handleClose ?. ();
                } catch (err) {
                    Swal.fire({
                        title: 'Error',
                        text: 'Failed to delete logo.',
                        icon: 'warning',
                        iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
                        confirmButtonText: 'Okay',
                        allowOutsideClick: false,
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'btn btn-success m-1',
                        }
                    });
                } finally {
                    setBusyLoadingAsset(false);
                    updateProfileCompleteness();
                }
            }
        });
    };

    const handleCreateLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploadLogoTypeSelected(false);
        setUploadLogoType(parseInt(e.target.value, 10));
    };

    const renderIndividualPropertyLogo = () => {
        const property = (propertyLogos || []).length > 0  ? propertyLogos[0].Property : null;
        const asset = property && (propertyLogos[0].Asset || []).length > 0  ? propertyLogos[0].Asset[0] : null;
        return (
            <div className={cx(!handleClose && 'mt-4 sm:mt-6 md:mt-8 2xl:mt-10')}>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-5 2xl:gap-10">
                    <div className="grid grid-cols-1 gap-4 sm:gap-5 2xl:gap-10">
                        <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                            <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
                                <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                    Website Logo for {property?.Name ?  property.Name : ''}
                                </h4>
                            </div>
                            <div className="p-3 sm:p-4 2xl:p-5 h-full">
                                { asset && asset.WebLogoAssetID > 0 ?
                                    <div key={asset.WebLogoAssetID} className="inline-block min-w-[210px] max-w-[210px]">
                                        <div className="p-2 border-x border-t border-brand_grey rounded-t-md flex justify-center">
                                            <DisplayAsset
                                                TransformedAssetUrl={asset.WebLogoTransformedAssetUrl}
                                                FileName={asset.WebLogoFileName}
                                                AssetType={NHAssetType.Logos} 
                                            />
                                        </div>
                                        <ul className="grid gap-2 p-2 text-2xs border border-brand_grey rounded-b-md mt-auto">
                                            <li>
                                                <a 
                                                    className="link"
                                                    href=""
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleDelete(NHAssetType.Logos);
                                                    }}
                                                >
                                                    Delete
                                                </a>
                                            </li>
                                        </ul>
                                    </div> :
                                    <span className="msg msg-warning">
                                        No logo uploaded
                                    </span>
                                }
                            </div>
                        </div>
                        <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                            <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
                                <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                    Review Card Logo for {property?.Name ?  property.Name : ''}
                                </h4>
                            </div>
                            <div className="p-3 sm:p-4 2xl:p-5 h-full">
                                { asset && asset.CardLogoAssetID > 0 ?
                                    <div key={asset.CardLogoAssetID} className="inline-block min-w-[210px] max-w-[210px]">
                                        <div className="p-2 border-x border-t border-brand_grey rounded-t-md flex justify-center">
                                            <DisplayAsset
                                                TransformedAssetUrl={asset.CardLogoTransformedAssetUrl}
                                                FileName={asset.CardLogoFileName}
                                                AssetType={NHAssetType.CardLogos} 
                                            /> 
                                        </div>
                                        <ul className="grid gap-2 p-2 text-2xs border border-brand_grey rounded-b-md mt-auto">
                                            <li>
                                                <a 
                                                    className="link"
                                                    href=""
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleDelete(NHAssetType.CardLogos);
                                                    }}
                                                >
                                                    Delete
                                                </a>
                                            </li>
                                            <li>
                                                <a 
                                                    className="link"
                                                    rel="noreferrer" 
                                                    target="_blank" 
                                                    href={`https://www.nursinghomes.com/logos/reviewcard/${asset.CardLogoFileName}.pdf`}
                                                >
                                                    Download PDF Print Version
                                                </a> 
                                            </li>
                                            <li>
                                                <Link 
                                                    className="link"
                                                    to="/review-cards"
                                                    state={{ NHID: selNHID }}
                                                >
                                                    Order Review Cards
                                                </Link>
                                            </li>
                                        </ul>
                                    </div> :
                                    <span className="msg msg-warning">
                                        No review card logo uploaded
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
                            <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                Upload a New Logo
                            </h4>
                        </div>
                        <div className="p-3 sm:p-4 2xl:p-5 h-full">
                            <div className="grid gap-3 sm:gap-4 2xl:gap-5">
                                <div className="mb-1.5">
                                    <strong className={cx('text-lg', !isUploadLogoTypeSelected ? '' : 'text-brand_status-error')}>
                                        Please select where you want to use this logo *
                                    </strong>
                                    <div className="mt-3 2xl:mt-4 flex flex-wrap gap-3">
                                        <div className="form-component form-radio">
                                            <label>
                                                <input
                                                    className="peer"
                                                    type="radio"
                                                    name="createLogo"
                                                    value={NHAssetType.WebAndCardLogos}
                                                    onChange={handleCreateLogo}
                                                    defaultChecked={uploadLogoType === NHAssetType.WebAndCardLogos ? true : false}
                                                /> 
                                                <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                                                <span>
                                                    For Website & Review Card
                                                </span>
                                            </label> 
                                        </div>
                                        <div className="form-component form-radio">
                                            <label>
                                                <input
                                                    className="peer"
                                                    type="radio"
                                                    name="createLogo"
                                                    value={NHAssetType.Logos}
                                                    onChange={handleCreateLogo}
                                                    defaultChecked={uploadLogoType === NHAssetType.Logos ? true : false}
                                                /> 
                                                <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                                                <span>
                                                    For Website Only
                                                </span>
                                            </label> 
                                        </div>
                                        <div className="form-component form-radio">
                                            <label>
                                                <input
                                                    className="peer"
                                                    type="radio"
                                                    name="createLogo"
                                                    value={NHAssetType.CardLogos}
                                                    onChange={handleCreateLogo}
                                                    defaultChecked={uploadLogoType === NHAssetType.CardLogos ? true : false}
                                                /> 
                                                <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                                                <span>
                                                    For Review Card Only
                                                </span>
                                            </label> 
                                        </div>
                                    </div>
                                </div>  
                                <UploadWidget 
                                    nHID = {selNHID}
                                    fileName={property?.Slug}
                                    assetTypeName = 'Select Logo to Upload' 
                                    assetType = {uploadLogoType}
                                    onAssetUpload = {handleAssetUpload}
                                    showAssetUploadResult = {handleShowAssetUploadResult}
                                    setIsUploadLogoTypeSelected= {setIsUploadLogoTypeSelected}
                                />
                                <div className="flex flex-col gap-2 text-sm">
                                    <p>
                                        Please note maximum file size 40MB.
                                    </p>
                                    <p>
                                        Large logo file can take up to 5 minutes to upload on slower connections.
                                    </p>
                                    <p>
                                        If in doubt please refresh the page after a couple of minutes.
                                    </p>
                                </div>
                                <div className="ring-1 ring-brand_secondary p-3 sm:p-4 xl:p-6 rounded-md sm:rounded-lg text-md">
                                    <strong className="text-md md:text-lg lg:text-xl">
                                        Uploading Logo - Tips &amp; Terms
                                    </strong>
                                    <ul className="marker:text-brand_grey-medium mt-3 list-disc ps-5 space-y-2 text-sm">
                                        <li>
                                            Your Logo Uploaded will automatically resized and cropped to best fit our logo template (192 pixels width). If you are not happy with the automatic resize and crop please upload a pre-formatted logo to the exact logo template specification.
                                        </li>
                                        <li>
                                            nursinghomes.com reserve the right to remove any logos we deem unsuitable.
                                        </li>
                                        <li>
                                            <strong>
                                                By pressing Upload button, I/we agree to the terms & conditions set out on <a className="link" href="https://www.nursinghomes.com/terms" target="_blank">www.nursinghomes.com/terms</a>
                                            </strong>
                                        </li>
                                    </ul>
                                </div>
                                { asset && (asset?.CardLogoAssetID > 0 || asset?.WebLogoAssetID > 0) ?
                                    <span className="msg msg-warning">
                                        Please note any new logos uploaded will overwrite your existing logo
                                    </span> : null
                                } 
                            </div>                                                       
                        </div>
                    </div>
                </div>
            </div>
        );
        
    };

    return (
        <>
            { busyLoadingAsset ? <LoadingDots show={true} /> : null }
            { renderIndividualPropertyLogo() }
        </>
    );
};

export default IndividualPropertyLogo;