import React, { FC, useState } from 'react';
import { Button } from '../../../../components/common/button';
import { IServiceTotals } from '../../../../interfaces/service';
import { ModalQueryWrapper } from './modal_query_wrapper';

interface IProps {
    row: IServiceTotals;
}

const ModalQueryWrapperButton: FC<IProps> = ({ row }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = (): void => setIsModalOpen((isOpen: boolean) => !isOpen);

    return (
        <>
            <Button onClick={toggleModal} className="ms-auto">
                Edit
            </Button>
            <ModalQueryWrapper 
                row={row} 
                isModalOpen={isModalOpen} 
                onClose={toggleModal} 
            />
        </>
    );
};

export { ModalQueryWrapperButton };
