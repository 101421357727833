import React, { useEffect, useState } from 'react';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import { IPropertyLiveDescription, IPropertyLiveDescriptionParams } from '../../interfaces/property';
import { getPropertyLiveDescriptions } from '../../api/property';
import { LoadingDots } from '../../components/common/loading_dots';
import { formatAddress, getSessionPropertyMap } from '../../utils/property';
import ProfileCompletenessHeader from '../../components/profile_completeness/profile_completeness_header';
import { ProfileScoreType } from '../../interfaces/property';
import ProfileCompletenessText from '../../components/profile_completeness/profile_completeness_text';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { replaceNewlinesWithBreakElements } from '../../utils/text';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyDescriptions = ({ nhIDs, orgNHID }: IProps) => {
    const location = useLocation();
    const singlePropertySession = nhIDs?.length === 1;
    const defaultNHID = singlePropertySession ? nhIDs[0] : get(location, 'state.filters.NHID', undefined);

    const propertyMap = getSessionPropertyMap();

    const [ busy, setBusy ] = useState<boolean>();
    const [ hasLiveDescription, setHasLiveDescription ] = useState<boolean | null>(null);
    const [ data, setData ] = useState<IPropertyLiveDescription[]>();
    const [ selNHID, setSelNHID ] = useState<number>();

    if (!selNHID && defaultNHID) {
        setSelNHID(defaultNHID);
    }

    const showForm = !selNHID && (orgNHID || !singlePropertySession);

    useEffect(() => {
        fetchData();
    }, [selNHID, hasLiveDescription]);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    const fetchData = async () => {
        setBusy(true);

        const params: IPropertyLiveDescriptionParams = {
            NHID: selNHID,
            HasLiveDescription: showForm ? hasLiveDescription : null
        };

        const data = await getPropertyLiveDescriptions(params);


        if (data) {
            setData(data);
        } 

        setBusy(false);
    };

    const renderDescriptionLink = (nhID: number) => {
        const property = propertyMap.get(nhID);

        if (!property) {
            return null;
        }

        const { WebsiteURL, StateSlug, CitySlug, Slug } = property;

        return (
            <a className="link text-xs bg-white px-2 py-1 inline-block leading-none rounded-lg" href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}/`} target="_blank" rel="noreferrer">
                view on website
            </a>
        );
    };

    const renderSection = (data: IPropertyLiveDescription) => {
       const noDescription = <><strong>Currently No Description</strong> - The nursinghomes.com editorial team are writing descriptions for all Premium subscribers</>;

        return (
            <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col" key={`property-description-${data.NHID}`}>
                <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-3 min-h-14 flex items-center">
                    <div className="flex flex-wrap gap-y-1 items-center gap-x-2 w-full [&>em]:w-full">
                        <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                            { data.PropertyName }
                        </h4>
                        <span className="inline-block text-xs lg:text-sm leading-tight text-brand_grey-medium">
                            { formatAddress(data) }
                        </span>
                        { data.Description ? renderDescriptionLink(data.NHID) : null } 
                        { data.IsOrg ? null : 
                            <ProfileCompletenessText
                                NHID={data.NHID}
                                profileScoreType={ProfileScoreType.Description}
                            />
                        }
                    </div>
                </div>
                <div className="p-3 sm:p-4 2xl:p-5">
                    <div className="text-md leading-normal">
                        { replaceNewlinesWithBreakElements(data.Description) || noDescription }
                    </div>
                </div>
            </div>
        );
    };

    const renderDescriptions = () => {
        if (!data) {
            return null;
        }

        return (
            <>  
                <section>
                    <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                        <ProfileCompletenessHeader
                            selNHID={selNHID}
                            nhIDs={nhIDs}
                            orgNHID={orgNHID}
                            profileScoreType={ProfileScoreType.Description}
                            handleProfileCompletenessFilter={setHasLiveDescription}
                            profileCompletenessFilterValue={hasLiveDescription}
                            title="for Descriptions"
                        /> 
                    </div>
                </section>
                <section>
                    <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                        { busy ? <LoadingDots show={true}/> : null }
                        <div className="grid gap-3 sm:gap-5 md:gap-6 2xl:gap-7">
                            { data.map((data: IPropertyLiveDescription) => renderSection(data)) }
                        </div>
                    </div>
                </section>
            </>
        );
    };

    return (
        <>
            <PageCommunitySelectWrapper
                label={'All Descriptions for'}
                handleSelect={handleSelect}
                selNHID={selNHID}
            />
            { renderDescriptions() }
        </>
    );
};

export default PropertyDescriptions;