import React, { FC, useState } from 'react';
import { Button, ButtonProps } from '../../../../components/common/button';
import { ITourDefaultValues } from '../types';
import { prepareDataForForm } from '../helpers';
import { usePremiumStatus } from '../../../../hooks/use_premium_status';
import { EditTourAvailabilityModal } from './edit_tour_availability_modal';

interface Props extends ITourDefaultValues {
    styleType?: ButtonProps['styleType'];
    className?: string;
}

const EditTourAvailabilityModalButton: FC<Props> = (props) => {
    const { styleType = 'solid', className, NHID, UpdatedAt } = props;
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => setIsModalOpen((isOpen: boolean) => !isOpen);
    const defaultValues = prepareDataForForm(props);
    const hasExistingTourAvailability = !!defaultValues?.TourAvailabilityID;

    const { isPremium } = usePremiumStatus(NHID);

    return (
        <>
            <EditTourAvailabilityModal
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
                {...props}
             />

            {isPremium ? (
                <Button
                    styleType={styleType}
                    className="ms-auto"
                    onClick={toggleModal}
                >
                    {hasExistingTourAvailability ? 'Edit' : 'Add'}
                </Button>
            ) : (
                <div className="msg msg-warning">
                    Premium Subscription Required
                </div>
            )}
        </>
    );
};

export { EditTourAvailabilityModalButton };
