import { useQueries } from '@tanstack/react-query';
import {
    getPropertyServiceCustomExtras,
    getPropertyServiceExtras,
    getPropertyServices
} from '../../../api/service';
import { useMemo } from 'react';
import { prepareTabs, getLastUpdatedTextFromQueryResults } from '../property_services_and_amenities/services_and_amenities_modal/helpers';
import { IPropertyService } from '../../../interfaces/service';

interface UsePropertyServicesProps {
    nhid: number;
    enabled: boolean;
    onlyShowProfileCompletenessFields: boolean | null;
}

export const usePropertyServices = ({ nhid, enabled, onlyShowProfileCompletenessFields }: UsePropertyServicesProps) => {
    const [extras, services, customExtras] = useQueries({
        queries: [
            {
                queryKey: ['portal', 'service', 'extras', nhid],
                queryFn: () => getPropertyServiceExtras(nhid),
                enabled
            },
            {
                queryKey: [
                    'portal',
                    'property',
                    'profilecompleteness',
                    nhid
                ],
                queryFn: () => getPropertyServices(nhid),
                enabled
            },
            {
                queryKey: ['portal', 'service', 'extras', 'custom', nhid],
                queryFn: () => getPropertyServiceCustomExtras(nhid),
                enabled
            }
        ]
    });

    const { tabConfig, defaultValues } = useMemo(
        () =>
            prepareTabs(services, extras, customExtras, {
                onlyShowProfileCompletenessFields
            }),
        [services, extras, customExtras, onlyShowProfileCompletenessFields]
    );

    const fieldsIncludedInProfileCompleteness = useMemo(() => services?.data?.map((item: IPropertyService) => {
        return  item.IncludedInProfileCompleteness ? `${item.ResourceID}` : null;
    }).filter(Boolean), [services]);

    const lastUpdatedText = getLastUpdatedTextFromQueryResults(services, extras, customExtras);

    return {
        isLoading: services.isLoading,
        tabConfig,
        defaultValues,
        fieldsIncludedInProfileCompleteness,
        lastUpdatedText
    };
};
