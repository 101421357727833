import * as yup from 'yup';
import moment from 'moment';
import { TourType } from '../types';


const isBefore = (fromTime: string, toTime: string) => {
    return moment(fromTime, 'HH:mm').isBefore(moment(toTime, 'HH:mm'));
};

const validatePhysicalTourFields = (validation: yup.AnySchema) => {
    return yup.mixed().when('TourTypeID', {
        is: (val: TourType) => [TourType.Both_onsite_remote, TourType.Onsite].includes(val),
        then: () => validation,
        otherwise: (schema) => schema.nullable()
    });
};

const validateVirtualTourFields = (validation: yup.AnySchema) => {
    return yup.mixed().when('TourTypeID', {
        is: (val: TourType) => [TourType.Both_onsite_remote, TourType.Remote].includes(val),
        then: () => validation,
        otherwise: (schema) => schema.nullable()
    });
};

const tourDaysValidation = yup
    .array()
    .of(yup.number())
    .test({
        message: 'Please select at least 1 day.',
        test: arr => arr.length !== 0,
    });

const startTimeValidation = yup.string().required('Please select From time.');

const endTimeValidation = yup
    .string()
    .required('Please select To time')
    .test(
        'end_time_test',
        'To time must be after From time.',
        function (value, { path }) {
            const { PhysicalTourStartTime, VirtualTourStartTime } = this.parent;
            if (path === 'VirtualTourEndTime') {
                return isBefore(VirtualTourStartTime, value);
            }
            if (path === 'PhysicalTourEndTime') {
                return isBefore(PhysicalTourStartTime, value);
            }
            return false;
        },
    );

const maxDescriptionLength = 500;
const descriptionValidation = yup
    .string()
    .trim()
    .max(
        maxDescriptionLength,
        `Description must not exceed ${maxDescriptionLength} characters.`,
    )
    .optional()
    .nullable();

const tourTypeValidation = yup
    .mixed<TourType>()
    .oneOf(Object.values(TourType) as TourType[], 'Please select the tour type.')
    .required('Please select the tour type.');

const schema = yup.object().shape({
    TourTypeID: tourTypeValidation,
    PhysicalTourDays: validatePhysicalTourFields(tourDaysValidation).required(),
    PhysicalTourStartTime: validatePhysicalTourFields(startTimeValidation),
    PhysicalTourEndTime: validatePhysicalTourFields(endTimeValidation),
    PhysicalDescription: validatePhysicalTourFields(descriptionValidation).optional(),
    VirtualTourDays: validateVirtualTourFields(tourDaysValidation).required(),
    VirtualTourStartTime: validateVirtualTourFields(startTimeValidation),
    VirtualTourEndTime: validateVirtualTourFields(endTimeValidation),
    VirtualDescription: validateVirtualTourFields(descriptionValidation).optional(),
});

export { schema };
