import React from 'react';
import { Modal } from '../../../../components/common/modal';
import { Button } from '../../../../components/common/button';
import { Form } from '../../../../components/common/form';
import coreDetailsFormSchema from '../property_core_details_form.validation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as nhAPI from '../../../../api/property';
import { mapValuesToFormFields } from './helpers';
import { useCreateTabs } from '../../../../hooks/use_create_tabs';
import { coreDetailsTabSections } from '../tabs/core_details_tab_sections';
import { costFundingTabSections } from '../tabs/cost_funding_tab_sections';
import { longFormatDate } from '../../../../utils/date';
import { ProfileScoreType } from '../../../../interfaces/property';
import { updateProfileCompleteness } from '../../../../utils/profile_completeness'; 
import {  ToastType, showToast } from '../../../../utils/toast';
import { careOfferedFormHasUncompletedProfileCompletenessFields, costFundingFormHasUncompletedProfileCompletenessFields } from '../profile_completeness_manager';
import { incompleteFieldsDialog } from '../../property_services_and_amenities/services_and_amenities_modal/helpers';
interface IProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    row: any;
    hasCoreDetails: boolean;
    profileScoreType: ProfileScoreType
}

const CareOfferedCostFundingModal = ({ isOpen, setIsOpen, row, hasCoreDetails, profileScoreType }: IProps) => {

    const tabContentConfig = profileScoreType === ProfileScoreType.FacilityBaseData ? {
        title: 'Care Offered',
        sections: coreDetailsTabSections,
        profileCompletenessCheck: careOfferedFormHasUncompletedProfileCompletenessFields
    } : {
        title: 'Cost / Funding',
        sections: costFundingTabSections,
        profileCompletenessCheck: costFundingFormHasUncompletedProfileCompletenessFields
    };

    const { TabHeaders, TabContent } = useCreateTabs(tabContentConfig.sections, {
        isScrollTab: true
    });

    const apiCall = hasCoreDetails
        ? nhAPI.updatePropertyCoreDetailData
        : nhAPI.createPropertyCoreDetailData;

    const queryClient = useQueryClient();
    const { mutate, isPending } = useMutation({
        mutationFn: async (payload: any) => apiCall(row.NHID, payload),
        onSuccess: () => {
            showToast(`${tabContentConfig.title} details successfully updated. Please allow up to 60 minutes for the changes to show on the website.`, ToastType.Success);
            queryClient.invalidateQueries({
                queryKey: ['property', 'core', 'details']
            });

            updateProfileCompleteness();
            
            setIsOpen(false);
        }
    });

    const defaultValues = mapValuesToFormFields(row);
    const handleSubmit = async (data: any) => {
        if (tabContentConfig.profileCompletenessCheck(data)) {
            const result = await incompleteFieldsDialog();
            if (result.isDismissed) {
                return;
            }
        }

        mutate(data);
    };

    const formId = String(row.NHID);

    const footerContent = (
        <Button form={formId} type="submit" isLoading={isPending} className="min-w-36">
            Update
        </Button>
    );

    return (
        <>
            <Modal
                onClose={() => setIsOpen(false)}
                isOpen={isOpen}
                title={tabContentConfig.title}
                subTitle={row.Name}
                lastUpdated={row.LastUpdated ? longFormatDate(row.LastUpdated): 'No updates made'}
                tabHeaders={<TabHeaders />}
                size="xl"
                footerContent={footerContent}
                noBodyPadding
            >
                <Form
                    onSubmit={handleSubmit}
                    defaultValues={defaultValues}
                    id={formId}
                    validationSchema={coreDetailsFormSchema}
                >
                    <TabContent />
                </Form>
            </Modal>
        </>
    );
};

export { CareOfferedCostFundingModal };
