import { useQuery } from '@tanstack/react-query';
import { IPropertyVirtualTourResponse } from '../../../../interfaces/asset';

import * as assetApi from '../../../../api/assets';

const usePropertyVirtualTours = (queryKey: string[]) => {
    const { 
        data = { rows: [], totalRows: 0, totalRowsWithVirtualTour: 0 }, 
        isLoading = false 
    }: { data: IPropertyVirtualTourResponse, isLoading: boolean } = useQuery({
        queryKey,
        staleTime: 0,
        queryFn: () => assetApi.getPropertyVirtualTours(),
    },
);

    return { data, isLoading };
};

export { usePropertyVirtualTours };