import React, { FC, InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import { ConnectForm } from '../form/form';
import cx from 'classnames';
import { UseFormReturn, Controller } from 'react-hook-form';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    name: string;
    description?: ReactNode;
    suffix?: string;
    prefix?: string;
    allowedPattern?: string;
    info?: ReactNode;
    inlineLabel?: boolean;
    required?: boolean;
    maxWidth?: string;
    disabled?: boolean;
    layout?: 'normal' | 'horizontal';
}

type FieldRequired = { isRequired: (fieldName: string) => boolean };

const FormInput: FC<Props> = (props) => {
    const {
        id,
        name,
        label,
        description,
        suffix,
        prefix,
        info,
        allowedPattern,
        inlineLabel = true,
        disabled = false,
        required = false,
        maxWidth,
        layout = 'normal',
        onChange,
        ...rest
    } = props;

    return (
        <ConnectForm>
            {({
                formState,
                control,
                isRequired
            }: UseFormReturn & FieldRequired) => {
                const fieldError: { message?: string } =
                    formState?.errors?.[name];

                const errorMessage: ReactElement = (
                    <>
                        { layout === 'horizontal' ?
                            <strong className="text-brand_status-error w-full">
                                {fieldError?.message}
                            </strong> :
                            <span>
                                {fieldError?.message}
                            </span>
                        }
                    </>
                );

                const inputId: string = id || name;

                return (
                    <>
                        { layout === 'horizontal' ?
                            <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap">
                                {label && (
                                    <>
                                        <label className="font-medium lg:min-w-[400px] lg:max-w-[400px] lg:text-lg">
                                            {label}
                                            {isRequired(name) && ' *'}
                                        </label>
                                        {description && (
                                            <span className="block">
                                                {description}
                                            </span>
                                        )}
                                    </>
                                )}
                                <div className={cx('flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10')}>
                                    <div className={cx('form-component form-textbox w-full', { 'form-group' : prefix || suffix, 'form-error' : fieldError })}>
                                        {prefix && (
                                            <em>
                                                {prefix}
                                            </em>
                                        )}
                                        <Controller 
                                            render={({ field }) => (
                                                <input
                                                    id={inputId}
                                                    disabled={disabled}
                                                    required={required}
                                                    style={maxWidth ? { maxWidth } : {}}
                                                    {...rest}
                                                    {...field}
                                                    onKeyDown={(event: any) => {
                                                        if (rest.type === 'number' && ['e'].includes(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                        rest?.onKeyDown?.(event);
                                                    }}
                                                    onChange={(event: any) => {
                                                        const regex = new RegExp(allowedPattern);
                                                        if (!allowedPattern || regex.test(event.target.value) || event.target.value === '') {
                                                            field.onChange(event.target.value);
                                                            onChange?.(event);
                                                        }
                                                    }}
                                                />
                                            )}
                                            control={control}
                                            name={name}
                                        />
                                        {suffix && (
                                            <em>
                                                {suffix}
                                            </em>
                                        )}
                                    </div>
                                    {info || null}
                                </div>
                                { fieldError && errorMessage }
                            </div> :
                            <div className={cx('form-component form-textbox', { 'form-inline' : inlineLabel, 'form-group' : prefix || suffix, 'form-error' : fieldError })}>
                                {label && (
                                    <>
                                        <label>
                                            {label}
                                            {isRequired(name) && ' *'}
                                        </label>
                                    </>
                                )}
                                {prefix && (
                                    <em>
                                        {prefix}
                                    </em>
                                )}
                                <Controller 
                                    render={({ field }) => (
                                        <input
                                            id={inputId}
                                            disabled={disabled}
                                            required={required}
                                            {...rest}
                                            {...field}
                                            style={maxWidth ? { maxWidth } : {}}
                                            onKeyDown={(event: any) => {
                                                if (rest.type === 'number' && ['e'].includes(event.key)) {
                                                    event.preventDefault();
                                                }
                                                rest?.onKeyDown?.(event);
                                            }}
                                            onChange={(event: any) => {
                                                const regex = new RegExp(allowedPattern);
                                                if (!allowedPattern || regex.test(event.target.value) || event.target.value === '') {
                                                    field.onChange(event.target.value);
                                                    onChange?.(event);
                                                }
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={name}
                                />
                                {suffix && (
                                    <em>
                                        {suffix}
                                    </em>
                                )}
                                { description && (
                                    <div className="form-note">
                                        {description}
                                    </div>
                                )}
                                { fieldError && errorMessage }
                            </div>
                        }
                    </>
                );
            }}
        </ConnectForm>
    );
};

export { FormInput };