import { useSelector } from 'react-redux';
import { 
    IContentOverviewFilter,
    IContentOverviewItem,
    IContentOverviewItemData,
    IPortalScoreItem,
    IPropertyScoreExtras,
    IPropertyScores,
    IPropertyScoresItem, 
    ProfileScoreType
} from '../../../../interfaces/property';
import { ISessionProperty } from '../../../../interfaces/session';
import { RootState } from '../../../../store/reducers';
import { getSessionPropertyMap } from '../../../../utils/property';
import { numberSort, removeNullKeys, textSort } from '../../../../utils/common';
import { filterRowByRange, getOpAndQuery } from '../../../../utils/range';
import { ExpressionType } from '../../../../interfaces/filter_matrix';

const profileScoreKeyNames: ProfileScoreType[] = [
    ProfileScoreType.Total,
    ProfileScoreType.FacilityBaseData,
    ProfileScoreType.ServicesAndAmenities,
    ProfileScoreType.CostFunding,
    ProfileScoreType.Photo,
    ProfileScoreType.Description,
    ProfileScoreType.Video,
    ProfileScoreType.MeetTheTeam,
    ProfileScoreType.PdfBrochure,
    ProfileScoreType.TourAvailability,
];

const profileScoreExtraKeys: {
    columnName: string, 
    keyName: keyof IPropertyScoreExtras, 
    updateDateColumn: keyof IPropertyScoreExtras
}[] = [{
    columnName: 'Logo',
    keyName: 'HasLogo', 
    updateDateColumn: 'HasLogoUpdatedAt'
}, {
    columnName: 'Virtual Tour', 
    keyName: 'HasVirtualTour', 
    updateDateColumn: 'HasVirtualTourUpdatedAt'
}, {
    columnName: 'Phone Number', 
    keyName: 'HasPhoneNumber', 
    updateDateColumn: 'HasPhoneNumberUpdatedAt'
}, {
    columnName: 'Email Address', 
    keyName: 'HasEmailAddress', 
    updateDateColumn: 'HasEmailAddressUpdatedAt'
}, {
    columnName: 'Website Address', 
    keyName: 'HasWebsite', 
    updateDateColumn: 'HasWebsiteUpdatedAt'
}, {
    columnName: '1+ Portal Users', 
    keyName: 'HasPortalUsers', 
    updateDateColumn: 'HasPortalUsersUpdatedAt'
}];

const facilityKeys: (keyof IContentOverviewItem)[] = [
    'NHID',
    'Name',
    'Address1', 
    'City',
    'PostCode',
    'RegionCode',
    'StateSlug',
    'CitySlug',
    'Slug',
    'WebsiteURL'
];

const sortFn = (sortField: string) => {
    if (sortField === 'Name') {
        return textSort;
    }

    return numberSort;
};

const useGetContentOverview = (
    nhID?: number,
    filters?: IContentOverviewFilter, 
    sortField: string = 'Name', 
    sortOrder: 'ASC' | 'DESC' = 'ASC'
): { 
    data: IContentOverviewItem[], 
    totalRows: number,
    totalFilteredRows: number,
    facilityKeys: (keyof IContentOverviewItem)[], 
    profileScoreKeyNames: ProfileScoreType[] 
} => {
    const { PropertyScores }: IPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: RootState) => ProfileCompletenessState
    );

    const filteredPropertyScores = nhID 
        ? PropertyScores.filter((property: IPropertyScores) => property.NHID === nhID) 
        : PropertyScores;

    const propertyMap = getSessionPropertyMap();

    const rawData = filteredPropertyScores.map((property: IPropertyScores): IContentOverviewItem => {
        const propertyData = propertyMap.get(property.NHID);

        const tableDataItem = facilityKeys.reduce((acc, key) => {
            acc[key] = propertyData?.[key as keyof ISessionProperty] as unknown as IContentOverviewItemData;
            return acc;
        }, {} as IContentOverviewItem);

        const scoresMap = new Map(
            property.Scores.map((score: IPropertyScoresItem) => [score.KeyName, score])
        );  

        profileScoreKeyNames.forEach((keyName) => {
            const propertyScore = scoresMap.get(keyName);

            const value = keyName === ProfileScoreType.Total 
                ? propertyScore.Score 
                : propertyScore.Score === propertyScore.AvailableScore 
                    ? 1 : 0;

            if (propertyScore) {
                tableDataItem[propertyScore.FieldName] = {
                    Value: value,
                    KeyName: propertyScore.KeyName,
                    UpdatedAt: propertyScore.UpdatedAt
                };
            }
        });

        profileScoreExtraKeys.forEach((extra) => {
            tableDataItem[extra.columnName] = {
                Value: property.ScoreExtras[extra.keyName] ? 1 : 0,
                KeyName: extra.keyName as ProfileScoreType,
                UpdatedAt: property.ScoreExtras[extra.updateDateColumn] as string
            };
        });

        return tableDataItem;
    })
    .sort(sortFn(sortField)(sortField, sortOrder, 'Value'));

    const filteredData = (rows: IContentOverviewItem[]): IContentOverviewItem[] => {
        if (!Object.keys((filters || {})).length) {
            return rows;
        }

        const filterKeys = Object.keys(removeNullKeys(filters));

        let rangeExpressionType: ExpressionType;
        let rangeExpressionValue: string;

        if (filters.ProfileCompletenessExpr) {
            [rangeExpressionType, rangeExpressionValue] = getOpAndQuery(filters.ProfileCompletenessExpr);
        }

        const filteredRows = rows.filter((row: IContentOverviewItem) => {
            let match = true;
            
            const nonRangeFilters = filterKeys.filter((key) => !key.endsWith('Expr'));

            for (let i = 0; i < nonRangeFilters.length; i ++) {
                const filterKey = nonRangeFilters[i] as keyof IContentOverviewFilter;
                const filterValue = filters[filterKey] === 'true' ? 1 : 0;

                if (row[filterKey].Value !== filterValue) {
                    match = false;
                    break;
                }
            }

            if (match && rangeExpressionType && rangeExpressionValue) {
                return filterRowByRange(
                    'Total Score',
                    rangeExpressionType, 
                    rangeExpressionValue, 
                    row,
                    'Value'
                );
            }

            return match;
        });

        return filteredRows;
    };

    const data = filteredData(rawData);
    const totalRows = rawData.length;
    const totalFilteredRows = data.length;

    return { 
        data, 
        totalRows, 
        totalFilteredRows, 
        facilityKeys, 
        profileScoreKeyNames 
    };
};

export { useGetContentOverview };
