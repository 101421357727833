import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import ProfileCompletenessIcon from '../../../../components/profile_completeness/profile_completeness_icon';
import { IContentOverviewLinkProps, ProfileScoreExtraType, ProfileScoreType } from '../../../../interfaces/property';
import { PropertyProfileContentOverviewModalLink } from './property_profile_content_overview_modal_link';
import { PropertyProfileContentOverviewPageLink } from './property_profile_content_overview_page_link';
import { getYear, shortFormatDateNoYear } from '../../../../utils/date';

interface IProps {
    nhID: number;
    keyName: ProfileScoreType | ProfileScoreExtraType;
    Value?: number;
    UpdatedAt?: string;
}

const PropertyProfileContentOverviewTableScoreCell = ({
    nhID,
    Value,
    keyName,
    UpdatedAt
}: IProps) => {
    const LinkComponent = ((): React.ComponentType<IContentOverviewLinkProps> => {
        switch (keyName) {
            case ProfileScoreType.FacilityBaseData:
            case ProfileScoreType.CostFunding:
            case ProfileScoreType.ServicesAndAmenities:
            case ProfileScoreType.Video:
            case ProfileScoreType.Photo:
            case ProfileScoreType.PdfBrochure:
            case ProfileScoreType.TourAvailability:
            case ProfileScoreExtraType.Logo:
            case ProfileScoreExtraType.VirtualTour:
            case ProfileScoreExtraType.EmailAddress:
            case ProfileScoreExtraType.PhoneNumber:
            case ProfileScoreExtraType.Website:
                return PropertyProfileContentOverviewModalLink;
            case ProfileScoreType.MeetTheTeam:
            case ProfileScoreType.Description:
            case ProfileScoreExtraType.PortalUsers:
                return PropertyProfileContentOverviewPageLink;
            default:
                return null;
        }
    })();

    const DateComponent = ({ date }: { date: string }) => {
        if (keyName === ProfileScoreType.Total) {
            return null;
        }

        return (
            <div className="text-xs text-gray-500 leading-tight">
                {date ? shortFormatDateNoYear(date) : ''}<br />
                {date ? getYear(date) : ''}
            </div>
        );
    };

    return (
        <>
            {
                keyName === ProfileScoreType.Total ? (
                    <div className="mx-auto">
                        <ProfileCompletenessIcon pcFull={Value} width={20} />
                    </div>
                ) : (
                    <div className="text-center py-3">
                        <div className="mx-auto pb-1">
                            <FontAwesomeIcon 
                                icon={Value ? faCheck : faXmark} 
                                className={`h-6 w-6 ${Value ? 'text-brand_status-success' : 'text-brand_status-error'}`}
                            /> 
                        </div>
                        { LinkComponent && (
                            <LinkComponent
                                nhID={nhID}
                                keyName={keyName}
                            />
                        )}
                        <DateComponent date={UpdatedAt} />
                    </div>
                )
            }
        </>
    );
};

export { PropertyProfileContentOverviewTableScoreCell };
