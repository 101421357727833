import { IPortalPropertyPhotoAsset, NHAssetType, TagType } from '../../../interfaces/asset';
import { IPortalScoreItem } from '../../../interfaces/property';
import { buildProfileCompleteMapForNHIDs } from '../../../utils/profile_completeness';

import * as assetApi from '../../../api/assets';
import { ITag } from '../../../interfaces/tag';

export const getOrgPropertiesPhotos = async (
    orgNHID: number, 
    hasNonPublishedPhotosFilter: boolean, 
    profileCompletenessState: IPortalScoreItem, 
    profileCompletenessFilterValue: boolean | null
): Promise<IPortalPropertyPhotoAsset[]> => {
    if (!orgNHID) {
        console.error('getOrgPropertiesPhotos: Invalid orgNHID');
        return null;
    }

    const filters = new Map<string, any>();
    if (hasNonPublishedPhotosFilter) {
        filters.set('HasNonPublishedPhotos', hasNonPublishedPhotosFilter);
    }

    const photoData = await assetApi.getOrgPropertiesPhotos(orgNHID, filters);
    return filterByProfileCompleteness(photoData, profileCompletenessState, profileCompletenessFilterValue);
};

export const filterByProfileCompleteness = (
    photoData: IPortalPropertyPhotoAsset[], 
    profileCompletenessState: IPortalScoreItem, 
    profileCompletenessFilterValue: boolean | null
) => {

    if (profileCompletenessFilterValue === null) {
        return photoData;
    }

    const profileMap = buildProfileCompleteMapForNHIDs(profileCompletenessState);

    return photoData.filter((item: IPortalPropertyPhotoAsset) => {
        const profileCompleteItem = profileMap.get(item.NHID);
        return profileCompleteItem?.PhotoComplete === profileCompletenessFilterValue;
    });
};

export const getPropertiesPhotos = async (
    nhIDs: number[], 
    hasNonPublishedPhotosFilter: boolean, 
    profileCompletenessState: IPortalScoreItem, 
    profileCompletenessFilterValue: boolean | null
): Promise<IPortalPropertyPhotoAsset[]> => {
    
    const filters = new Map<string, any>();
    if (hasNonPublishedPhotosFilter) {
        filters.set('HasNonPublishedPhotos', hasNonPublishedPhotosFilter);
    }

    const photoData = await assetApi.getPropertiesPhotos(nhIDs, filters);
    return filterByProfileCompleteness(photoData, profileCompletenessState, profileCompletenessFilterValue);
};

export const getPropertyPhotos = async (
    nhID: number[], 
    orgNHID: number, 
    profileCompletenessState: IPortalScoreItem, 
    hasNonPublishedPhotosFilter: boolean, 
    profileCompletenessFilterValue: boolean | null 
): Promise<IPortalPropertyPhotoAsset[]> => {
    let photoData;
    if(nhID[0] === orgNHID) {
        photoData = await getOrgPropertiesPhotos(orgNHID, hasNonPublishedPhotosFilter, profileCompletenessState, profileCompletenessFilterValue);
    } else {
        photoData = await assetApi.getPropertiesPhotos(nhID);
    }
    return filterByProfileCompleteness(photoData, profileCompletenessState, profileCompletenessFilterValue);
};

export const getPhotoTags  = async () : Promise<ITag[]>  => {
    const data: ITag[] = await assetApi.getTags({
        AssetTypeID: NHAssetType.Photos, 
        WebsiteID: 1, 
        IsTagPublishPortal: true, 
        TagTypeID: TagType.SingleTag
    });
        
    if (data)      {
        return data;
    }

    return [];
};  