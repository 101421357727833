import React, { useState, useEffect, useCallback } from 'react';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import LeadEmailFilters from '../../components/leads/lead_email_filters';
import LeadEmailTable from '../../components/leads/lead_email_table';
import { getTemplateTypes, getWebsiteEnquiries } from '../../api/email';
import FilterMatrix from '../../modules/filter_matrix';
import { FilterType } from '../../interfaces/filters';
import { DataTableSortOrder } from '../../components/common/data_table';
import { SortOrder, TableColumn } from 'react-data-table-component';
import CsvExport, { DisplayType } from '../../components/csv_export/csv_export';
import * as csvEportAPI from '../../api/csv_export';
import { formatFileNameForCsvExport } from '../../utils/file';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';
import { useLocation } from 'react-router-dom';
import ActiveFilters from '../../components/common/active_filters';
import { FilterButton } from '../../components/common/filter_button';


const EmailLeads = () => {
    const [ selNHID, setSelNHID ] = useState<number>(null);
    const [templateTypes, setTemplateTypes] = useState<any[]>([]);
    const [, updateState] = useState<any>();
    const [ data, setData ] = useState<any[]>([]);
    const [ totalFilteredRows, setTotalFilteredRows ] = useState<number>(0);
    const [ totalRows, setTotalRows ] = useState<number>(0);
    const [ offset, setOffset ] = useState<number>();
    const [ limit, setLimit ] = useState<number>(100);
    const [ sortColumn, setSortColumn ] = useState<string>();
    const [ sortOrder, setSortOrder ] = useState<DataTableSortOrder>();
    const [ loading, setLoading ] = useState<boolean>(false);

    const location = useLocation();

    const [ filterMatrix ] = useState(new FilterMatrix([
        {
            Key: 'TemplateTypeID',
            FilterFormLabel: 'Inquiry Type',
            Type: FilterType.LabelValue,
            FilterPropName: 'TemplateTypeID'
        },
        {
            Key: 'SenderName',
            FilterFormLabel: 'Inquirer Name',
            Type: FilterType.String,
            FilterPropName: 'SenderName'
        },
        {
            Key: 'SenderEmail',
            FilterFormLabel: 'Inquirer Email',
            Type: FilterType.String,
            FilterPropName: 'SenderEmail'
        },
        {
            Key: 'DateFrom',
            FilterFormLabel: 'Date From',
            Type: FilterType.DateFromUTC,
            FilterPropName: 'DateFrom'
        },
        { 
            Key: 'DateTo', 
            FilterFormLabel: 'DateTo',
            Type: FilterType.DateToUTC, 
            FilterPropName: 'DateTo', 
        }
    ]));

    
    const getData = async () => {
        setLoading(true);
        const params: any = {
            Offset: offset,
            Limit: limit || 25,
            SortField: sortColumn,
            SortOrder: sortOrder,
            IsLeadEnquiry: true,
            ...filterMatrix.getRequestParams()
        };

        if (selNHID) {
            params.NHIDs = [selNHID];
        }

        const [rows, totalFilteredCount, totalCount] = await getWebsiteEnquiries(params);

        setData(rows);
        setTotalFilteredRows(totalFilteredCount);
        setTotalRows(totalCount);
        setLoading(false);
    };

    const handlePageChange = async (pageNumber: number) => {
        setOffset((pageNumber - 1) * limit);
    };

    const handleLimitChange = async (newPerPage: number, pageNumber: number) => {
        setOffset((pageNumber - 1) * newPerPage);
        setLimit(newPerPage);
    };

    const handleSortChange = (column: TableColumn<any>, sortOrder: SortOrder) => {
        setSortColumn(column.sortField);
        setSortOrder(sortOrder === 'asc' ? DataTableSortOrder.Ascending : DataTableSortOrder.Descending);
    };

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    useEffect(() => {
        getData();
    }, [offset, limit, sortColumn, sortOrder, filterMatrix, selNHID]);

    const forceUpdate = useCallback(() => updateState({}), []);

    const applyFilter = (key: string, value: any, refreshData = true) => {
        filterMatrix.setValue(key, value);
        forceUpdate();

        if (refreshData) {
            getData();
        }
    };

    const getTypeOptionsData = async () => {
        const resp = await getTemplateTypes({
            IsLeadEnquiry: true
        });

        const data = resp.map((type: any) => ({
            label: type.Name,
            value: type.TemplateTypeID
        }));

        setTemplateTypes(data);
    };

    const startExport = async (): Promise<string> => {
        const params = filterMatrix.getRequestParams();
        params.IsLeadEnquiry = true;

        return csvEportAPI.startExport('/website/enquiry/export', params);
    };

    const resetFilters = () => {
        filterMatrix.reset();
        getData();
    };

    useEffect(() => {
        getTypeOptionsData();
    }, []);

    useEffect(() => {
        if (location?.state && location.state.filters) {
            for (const i in location.state.filters) {
                if (!location.state.filters[i]) {
                    continue;
                }

                applyFilter(i, location.state.filters[i]);
            }

            getData();
        }
    }, [location]);

    return (
        <>
            <PageCommunitySelectWrapper
                label="All Leads for"
                handleSelect={handleSelect}
                selNHID={selNHID}
            />
            <section>
                <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                    <LeadEmailFilters
                        data={data}
                        templateTypes={templateTypes}
                        pageOffset={offset}
                        pageLimit={limit}
                        totalFilteredRows={totalFilteredRows}
                        totalRows={totalRows}
                        filterMatrix={filterMatrix}
                        applyFilter={applyFilter}
                        resetFilters={resetFilters}
                    />
                    <div className="flex flex-col items-center text-center gap-3 md:flex-row md:text-start">
                        <ActiveFilters 
                            filterMatrices={[filterMatrix]} 
                            isLoading={false}
                            pageOffset={offset}
                            pageLimit={limit}
                            singularNoun="Inquiry"
                            pluralNoun="Inquiries"
                            totalSize={totalRows}
                            totalFilteredSize={totalFilteredRows}
                            showTotals={true}
                            applyFilter={applyFilter}
                        />
                        <div className="md:ms-auto flex flex-wrap justify-center md:justify-end gap-3 lg:flex-nowrap">
                            <FilterButton />
                            <CsvExport 
                                startExportFunction={startExport}
                                modalTitleSuffix={'Review Score'}
                                label="Export as CSV"
                                displayType={DisplayType.Button}
                                fileName={`${formatFileNameForCsvExport('email_log')}`}
                            />
                        </div>
                    </div>
                    <div className="mt-4 sm:mt-6 md:mt-8 lg:mt-10">
                        <div data-table="Email Inquiry & Brochure Inquiry Log" className="relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none">
                            <LeadEmailTable
                                filterMatrix={filterMatrix}
                                data={data}
                                totalRows={totalRows}
                                handleLimitChange={handleLimitChange}
                                handlePageChange={handlePageChange}
                                handleSortChange={handleSortChange}
                                limit={limit}
                                loading={loading}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default EmailLeads;
