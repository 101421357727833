import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { IMultiFacilityChartLargeProps } from '../types';
import { ExpressionType, SqlRange } from '../../../../interfaces/filter_matrix';

const chartContainer = 'publishedReviewsLastYearMultiFacility';

export const MultiFacilityChartLarge = ({ 
    chartData,
}: IMultiFacilityChartLargeProps) => {

    const navigate = useNavigate();

    const labelBullet = (root: any) => {

        const label = am5.Label.new(root, {
            text: '',
            fontSize: 16,
            fill: am5.color(0x000000),
            centerX: am5.percent(5),
            centerY: am5.percent(100),
            populateText: true,
            background: am5.Rectangle.new(root, {
                fill: am5.color(0xff0000),
                fillOpacity: 0,
            }),
            cursorOverStyle: 'pointer',
            interactive: true,
        });

        label.states.create('hover', {
            scale: 1.05,
        });

        label.events.on('click', (ev) => {
            const filters: { NumberOfReviewsWithin12MonthsRange?: SqlRange } = {};

            if (typeof ev.target.dataItem.dataContext === 'object' && 'threshold' in ev.target.dataItem.dataContext) {
                switch (ev.target.dataItem.dataContext.threshold) {
                    case '1-4':
                        filters.NumberOfReviewsWithin12MonthsRange = new SqlRange(ExpressionType.Between, '1', '4');
                        break;

                    case '5-11':
                        filters.NumberOfReviewsWithin12MonthsRange = new SqlRange(ExpressionType.Between, '5', '11');
                        break;

                    case '12+':
                        filters.NumberOfReviewsWithin12MonthsRange = new SqlRange(ExpressionType.GreaterThan, '12', undefined);
                        break;

                    default: // 0
                        filters.NumberOfReviewsWithin12MonthsRange = new SqlRange(ExpressionType.Equal, '0', undefined);
                        break;
                }
            }

            navigate('/reviews-overview', { 
                state: {
                    filters
                }
            });
        });

        label.template = am5.Template.new({}); 

        label.template.adapters.add('text', (text, target: any) => {
            
            const valueX = (target.dataItem && target.dataItem.get('valueX')) ?? 0;
            
            if (!valueX) {
                return '';
            }

            const labelText = `${valueX} ${valueX === 1 ? 'Facility' : 'Facilities'}`;

            return target.isHover() ? `[#004d99]${labelText}[/]` : `[#006edb]${labelText}[/]`;
        });

        return am5.Bullet.new(root, {
            locationX: 1,
            locationY: 0.85,
            sprite: label,
        });
    };

    useLayoutEffect(() => {

        const root = am5.Root.new(chartContainer);

        const customTheme = am5.Theme.new(root);

        customTheme.rule('Label').setAll({
            fill: am5.color(0x262626),
            fontSize: '0.85rem',
            fontFamily: 'Poppins'
        });

        root.setThemes([am5themes_Animated.new(root), customTheme]);
        root._logo.dispose();

        const chart = root.container.children.push( 
            am5xy.XYChart.new(root, {
                panY: false,
                layout: root.horizontalLayout,
                paddingTop: 15,
                x: am5.percent(50),
                centerX: am5.percent(50),
            })
        );        

        // Create Y-axis
        const xAxisProps: any = {
            min: 0,
            renderer: am5xy.AxisRendererX.new(root, {})
        };
        const xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, xAxisProps)
        );

        const xRenderer = xAxis.get('renderer');
        xRenderer.grid.template.setAll({
            stroke: am5.color(0x000000),
            strokeWidth: 0.5
        });
        xRenderer.labels.template.setAll({
            visible: false,
        });
      
        // Create X-Axis
        const yRenderer = am5xy.AxisRendererY.new(root, { minGridDistance: 30 });

        // Rotate X_AXIS Labels
        yRenderer.labels.template.setAll({
            text: '{category} Reviews',
            textAlign: 'center',
            oversizedBehavior: 'wrap',
            fontSize: 14,
            breakWords: true,
            maxWidth: 70,
            paddingTop: 10,
            paddingBottom: 10,
            templateField: 'threshold'
        });

        yRenderer.grid.template.setAll({
            stroke: am5.color(0x000000),
            strokeWidth: 0.5
        });

        const yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: yRenderer,
                categoryField: 'threshold'
            })
        );
     
        yAxis.data.setAll(chartData);

        // Create series
        const reviewSeries = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: 'Reviews',
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: 'facilities',
                categoryYField: 'threshold',
            })
        );
        
        reviewSeries.set('fill', am5.color(0x007791));
        reviewSeries.set('stroke', am5.color(0x007791));

        reviewSeries.bullets.push(() => labelBullet(root));

        reviewSeries.data.setAll(chartData);
      
        reviewSeries.columns.template.setAll({ 
            cornerRadiusTR: 5,
            cornerRadiusBR: 5,
            tooltipY: 0 
        });
        
        root.tapToActivate = true;
        root.tapToActivateTimeout = 2000;

        return () => {
            root.dispose();
        };
    }, [chartData]);

    return (
        <div id={chartContainer} className="w-full h-80"></div>
    );
};
