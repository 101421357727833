import { ExpressionType } from '../interfaces/filter_matrix';

const rangeFilter = (expressionType: ExpressionType): any => {
    switch (expressionType) {
        case ExpressionType.Equal:
            return (a: number, b: number) => a === b;
        case ExpressionType.NotEqual:
            return (a: number, b: number) => a !== b;
        case ExpressionType.GreaterThan:
            return (a: number, b: number) => a > b;
        case ExpressionType.GreaterThanOrEqual:
            return (a: number, b: number) => a >= b;
        case ExpressionType.LessThan:
            return (a: number, b: number) => a < b;
        case ExpressionType.LessThanOrEqual:
            return (a: number, b: number) => a <= b;
        case ExpressionType.Between:
            return (value: number, min: number, max: number) => value >= min && value <= max;
        case ExpressionType.In:
            return (value: number, valuesArray: number[]) => valuesArray.includes(value);
        case ExpressionType.NotEntered:
            return (value: number) => value === null;
        default:
            throw new Error(`Unsupported expression type: ${expressionType}`);
    }
};

export const getOpAndQuery = (ratingStr: string): [ExpressionType, string] => {
    const len = ratingStr.length;
    const opCode = ratingStr.slice(0, 2) as ExpressionType;
    const opQuery = ratingStr.slice(2, len);

    return [opCode, opQuery];
};

export const filterRowByRange = (
    columnName: string, 
    rangeExpressionType: ExpressionType, 
    rangeExpressionValue: string,
    row: any,
    rowValueKey?: string 
): boolean => {
    const rowValue = rowValueKey ? row[columnName][rowValueKey] : row[columnName];

    if (rangeExpressionType === ExpressionType.Between) {
        const [min, max] = rangeExpressionValue.split(',');
        return rangeFilter(rangeExpressionType)(rowValue, Number(min), Number(max));
    }

    if (rangeExpressionType === ExpressionType.In) {
        const valuesArray = rangeExpressionValue.split(',').map(Number);
        return rangeFilter(rangeExpressionType)(Number(rowValue), valuesArray);
    }

    if (rangeExpressionType === ExpressionType.NotEntered) {
        return rangeFilter(rangeExpressionType)(Number(rowValue));
    }

    return rangeFilter(rangeExpressionType)(Number(rowValue), Number(rangeExpressionValue));
};