import React from 'react';
import { LoadingDots } from '../common/loading_dots';
import UploadWidget from '../cloudinary/upload_widget';
import * as assetApi from '../../api/assets';
import Swal from 'sweetalert2';
import { updateProfileCompleteness } from '../../utils/profile_completeness';

import { 
    NHAssetType,
    ICloudinaryData,
    IAssetUploadLog,
    IAssetPayLoad,
    IPortalPropertyAsset
 } from '../../interfaces/asset';


interface IProps {
    handleReloadPropertyBrochures: (nhID: number[], orgNHID?: number) => Promise<IPortalPropertyAsset[]>;
    orgNHID?: number | null;
    nhIDs?: number[];
    selNHID: number | null;
    propertyBrochures: IPortalPropertyAsset[];
    setPropertyBrochures: (propertyBrochures: IPortalPropertyAsset[]) => void;
    busyLoadingAsset: boolean;
    setBusyLoadingAsset: (busyState: boolean) => void;
    handleClose?: any;
}

const IndividualPropertyLogo = ({
    handleReloadPropertyBrochures,
    orgNHID,
    nhIDs,
    selNHID,
    propertyBrochures,
    setPropertyBrochures,
    busyLoadingAsset,
    setBusyLoadingAsset,
    handleClose
}: IProps) => {

    const refreshNHID = orgNHID ? [orgNHID] : nhIDs ? nhIDs : [selNHID];

    const handleAssetUpload = async(cloudinaryData: ICloudinaryData): Promise<any> => {
        const assetPayLoad: IAssetPayLoad = { 
            NHID: selNHID,
            AssetType: NHAssetType.BrochurePdfs,
            ...cloudinaryData
        };
        const assetID = await assetApi.createAsset(assetPayLoad);

        return {
            AssetID: assetID,
            TransformedAssetUrl: cloudinaryData.TransformedAssetUrl,
            Sequence: cloudinaryData.Sequence,
            FileName: cloudinaryData.TransformedAssetUrl.match(/([^/]+)(?=\.\w+$)/)[0],
            PageCount: cloudinaryData.PageCount
        };
    };
    
    const handleShowAssetUploadResult = async(assetUploadLog : IAssetUploadLog[]) => {
        let hasAssetUploaded = false;
        for (const item of assetUploadLog) {
            if(item.Type === 'success') {
                hasAssetUploaded = true;
                break;
            }
        }

        if( !hasAssetUploaded ) {
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while uploading the PDF Brochure, please try again.',
                icon: 'warning',
                iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
                confirmButtonText: 'Okay',
                allowOutsideClick: false,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success m-1',
                }
            });
        } else {
            try {
                setBusyLoadingAsset(true);
                const updatedPropertyBrochures = await handleReloadPropertyBrochures(refreshNHID, orgNHID);
                setPropertyBrochures(updatedPropertyBrochures);    
                handleClose ?. ();     
            } catch (err) {
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to fetch the uploaded PDF Brochure.',
                    icon: 'warning',
                    iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
                    confirmButtonText: 'Okay',
                    allowOutsideClick: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-success m-1',
                    }
                });
            } finally {
                setBusyLoadingAsset(false);
            }
        }
    };

    const handleDelete = async() => {
        Swal.fire({
            title: 'Delete PDF Brochure',
            text: 'Are you sure you want to delete this PDF Brochure?',
            icon: 'warning',
            iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-success m-1',
                denyButton: 'btn btn-error m-1',
                cancelButton: 'btn btn-error m-1'
            }
        }).then(async (result: any) => {
            if (result.value) {
                setBusyLoadingAsset(true);
                try {
                    const deletedAssetID = await assetApi.deletePropertyBrochure(selNHID);
                   
                    if (!deletedAssetID) {
                        throw new Error('Failed to delete PDF Brochure');
                    }
                    const updatedPropertyBrochures = await handleReloadPropertyBrochures(refreshNHID, orgNHID);
                    setPropertyBrochures(updatedPropertyBrochures);
                    handleClose ?. ();
                } catch (err) {
                    Swal.fire({
                        title: 'Error',
                        text: 'Failed to delete the PDF Brochure.',
                        icon: 'warning',
                        iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
                        confirmButtonText: 'Okay',
                        allowOutsideClick: false,
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'btn btn-success m-1',
                        }
                    });
                } finally {
                    setBusyLoadingAsset(false);
                }
            }
        });
    };

    const renderIndividualPropertyBrochure = () => {
        const property = (propertyBrochures || []).length > 0  ? propertyBrochures[0].Property : null;
        const asset = property && (propertyBrochures[0]?.Asset || []).length > 0  ? propertyBrochures[0].Asset[0] : null;

        const pdfThumbnailsLink = [];
        if( asset && asset.TransformedAssetUrl && asset.PageCount) {
            for (let i = 0; i < asset.PageCount; i++) {
                pdfThumbnailsLink.push(
                    <a className="ring-1 ring-brand_grey-light px-1 py-2" href={asset.TransformedAssetUrl.replace(/image\/upload\/.*?\/brochure_pdfs/, `pg_${i+1}/brochure_pdfs`)} target='_blank' key={i} rel='noreferrer'>
                        <img src={asset.TransformedAssetUrl.replace(/image\/upload\/.*?\/brochure_pdfs/, `c_scale,w_100,h_100,pg_${i+1}/f_jpg/brochure_pdfs`)} alt={`${property?.Name} PDF Brochure page ${i+1}`} />
                    </a>
                );
            }
        }

        return (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-5 2xl:gap-10">
                <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                    <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
                        <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                            PDF Brochure for {property?.Name ?  property.Name : ''}
                        </h4>
                    </div>
                    <div className="p-3 sm:p-4 2xl:p-5 h-full">
                        { asset && asset.AssetID > 0 ?
                            <div key={asset.AssetID} className="inline-block">
                                <div className="p-2 border-x border-t border-brand_grey rounded-t-md flex flex-wrap gap-2">
                                    { pdfThumbnailsLink }
                                </div>
                                <ul className="flex gap-2 justify-between p-2 text-2xs border border-brand_grey rounded-b-md mt-auto">
                                    <li>
                                        <a 
                                            className="link"
                                            href=""
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleDelete();
                                            }}
                                        >
                                            Delete
                                        </a>
                                    </li>
                                </ul>
                            </div> :
                            <span className="msg msg-warning">
                                No PDF Brochure uploaded
                            </span>
                        }
                    </div>
                </div>
                <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                    <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
                        <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                            Upload a New PDF Brochure
                        </h4>
                    </div>
                    <div className="p-3 sm:p-4 2xl:p-5 h-full">
                        <div className="grid gap-3 sm:gap-4 2xl:gap-5">
                            <UploadWidget 
                                nHID = {selNHID}
                                fileName={property?.Slug}
                                assetTypeName = 'Select PDF Brochure to Upload' 
                                assetType = {NHAssetType.BrochurePdfs}
                                onAssetUpload = {handleAssetUpload}
                                showAssetUploadResult = {handleShowAssetUploadResult}
                            />
                            <div className="grid gap-2 text-sm">
                                <p>
                                    Please note maximum file size 40MB.
                                </p>
                                <p>
                                    Large PDF Brochure file can take up to 5 minutes to upload on slower connections.
                                </p>
                                <p>
                                    If in doubt please refresh the page after a couple of minutes.
                                </p>
                            </div>         
                            <div className="ring-1 ring-brand_secondary p-3 sm:p-4 xl:p-6 rounded-md sm:rounded-lg text-md">
                                <strong className="text-md md:text-lg lg:text-xl">
                                    Uploading PDF Brochures - Tips &amp; Terms
                                </strong>
                                <ul className="marker:text-brand_grey-medium mt-3 list-disc ps-5 space-y-2 text-sm">
                                    <li>
                                        Max PDF Brochure upload size 40MB.
                                    </li>
                                    <li>
                                        nursinghomes.com reserve the right to remove any PDF Brochures we deem unsuitable.
                                    </li>
                                    <li>
                                        <strong>By pressing Upload button, I/we agree to the terms & conditions set out on <a className="link" href="https://www.nursinghomes.com/terms" target="_blank">www.nursinghomes.com/terms</a></strong>
                                    </li>
                                </ul>
                            </div>        
                            { asset && asset?.AssetID > 0 ?
                                <span className="msg msg-warning">
                                    Please note any new PDF Brochure uploaded will overwrite your existing PDF Brochure
                                </span> : null
                            }
                        </div>                   
                    </div>
                </div>
            </div>
        );
        
    };

    return (
        <>
            <LoadingDots show={busyLoadingAsset} /> 
            { renderIndividualPropertyBrochure() }
        </>
    );
};

export default IndividualPropertyLogo;