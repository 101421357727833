import React, { useState } from 'react';
import { SortOrder } from 'react-data-table-component';
import { IContentOverviewFilter, IContentOverviewItem } from '../../../../interfaces/property';
import { useGetContentOverview } from '../hooks/use_content_overview';
import { TableColumn } from 'react-data-table-component';
import DataTable, { DataTableSortOrder } from '../../../../components/common/data_table';
import { formatAddress } from '../../../../utils/property';
import { SyncScrollBar } from '../../../../components/common/sync_scroll_bar';
import { PropertyProfileContentOverviewTableScoreCell } from './property_profile_content_overview_table_cell';
import { ProfileContentOverviewFilters } from './property_profile_content_overview_filters';
import { FilterButton } from '../../../../components/common/filter_button';
import FilterMatrix from '../../../../modules/filter_matrix';
import { useContentOverviewFilterMatrix } from '../hooks/use_content_overview_filter_matrix';
import ActiveFilters from '../../../../components/common/active_filters';

interface IProps {
    selNHID?: number;
    isSinglePropertySelected?: boolean;
}

const PropertyProfileContentOverviewTable = ({ selNHID, isSinglePropertySelected }: IProps) => {
    const { filterMatrix, rangeFilterMatrix } = useContentOverviewFilterMatrix();

    const [sortField, setSortField] = useState<string>('Name');
    const [sortOrder, setSortOrder] = useState<DataTableSortOrder>(DataTableSortOrder.Ascending);
    const [ filters, setFilters ] = useState<IContentOverviewFilter>(null);
    
    const {
        data, 
        totalRows, 
        totalFilteredRows, 
        facilityKeys, 
        profileScoreKeyNames 
    } = useGetContentOverview(selNHID, filters, sortField, sortOrder);

    const columns: TableColumn<IContentOverviewItem>[] = data.length ?
        Object.keys(data[0]).filter((key) => !facilityKeys.includes(key)).map((key) => {
            const name = key === 'Total Score' ? 'Profile Completeness Score' : key.replace('Score', '');
            
            return {
                name,
                cell: ({ NHID, [key]: { KeyName, Value, UpdatedAt }}: IContentOverviewItem) => {
                    return <PropertyProfileContentOverviewTableScoreCell {...{ nhID: NHID, keyName: KeyName, Value, UpdatedAt }} />;
                },
                conditionalCellStyles: [{
                    when: (row) => profileScoreKeyNames.includes(row[key].KeyName),
                    style: { backgroundColor: 'ghostwhite' },
                }],
                width: '*',
                sortable: true,
                sortField: key
            };
        }) : null;

    if (columns) {
        columns.unshift({
            name: 'Facility Name',
            width: '*',
            cell: (row: IContentOverviewItem) => {
                const { WebsiteURL, StateSlug, CitySlug, Slug } = row;
        
                return (
                    <div className="flex flex-wrap gap-2 break-words py-1">
                        <strong className="block leading-tight lg:text-lg">
                            {row.Name}
                        </strong>
                        <span className="inline-block text-xs lg:text-sm me-3 leading-tight text-brand_grey-medium">
                            {formatAddress(row, ['Address1', 'City', 'RegionCode', 'PostCode'])}
                        </span>
                        <a
                            target="_blank"
                            className="link text-xs bg-brand_faint-blue px-2 py-1 inline-block leading-none rounded-lg relative -top-px"
                            href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                        >
                            view on website
                        </a>
                    </div>
                );
            },
            sortable: true
        });
    }
    
    const handleTableSortChange = (
        column: TableColumn<IContentOverviewItem>, 
        sortOrder: SortOrder 
    ) => {
        setSortField(column.sortField);
        setSortOrder(sortOrder === 'asc' ? DataTableSortOrder.Ascending : DataTableSortOrder.Descending);
    };

    const resetFilters = () => {
        filterMatrix.reset();
        rangeFilterMatrix.reset();
        setFilters(null);
    };

    const applyFilter = (filterMatrix: FilterMatrix, key: string, value: any) => {
        filterMatrix.setValue(key, value);

        setFilters({
            ...filterMatrix.getRequestParams(), 
            ...rangeFilterMatrix.getRequestParams()
        });
    };

    return (
        <section>   
            <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                { !isSinglePropertySelected && (
                    <>
                        <div className="mt-4 sm:mt-4 md:mt-6 lg:mt-8">
                            <ProfileContentOverviewFilters 
                                filterMatrix={filterMatrix}
                                rangeFilterMatrix={rangeFilterMatrix}
                                applyFilter={applyFilter}
                                resetFilters={resetFilters}
                            />
                        </div>
                        <div className="flex flex-col items-center text-center gap-3 md:flex-row md:text-start md:justify-between">
                            <ActiveFilters 
                                filterMatrices={[filterMatrix, rangeFilterMatrix]} 
                                isLoading={false}
                                pageOffset={null}
                                pageLimit={null}
                                singularNoun="Facility"
                                pluralNoun="Facilities"
                                totalSize={totalRows}
                                totalFilteredSize={totalFilteredRows}
                                showTotals={true}
                                applyFilterWithMatrix={applyFilter}
                            />
                            <div className="mx-auto text-center w-fit flex flex-col gap-2 lg:flex-row lg:gap-10 items-center">
                                <div className="flex items-center">
                                    <div className="bg-brand_faint-blue rounded-sm me-2 h-7 w-7"></div>
                                    <strong className="inline-block font-medium">Included in Profile Completeness</strong>
                                </div>
                                <span className="inline-block font-medium">Last update date shown if available</span>                              
                            </div>
                            <div className="flex flex-wrap justify-center md:justify-end gap-3 lg:flex-nowrap">
                                <FilterButton />
                            </div>
                        </div>
                    </>
                )}
                
                {
                    data.length ? (
                        <>
                            <div className="mt-4 sm:mt-4 md:mt-6">
                                <SyncScrollBar syncedSelector='[data-table="Content Overview"] > :first-child' />
                            </div>
                            <div 
                                data-table="Content Overview" 
                                className="relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none"
                            >
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination={true}
                                    paginationTotalRows={totalFilteredRows}
                                    onSort={handleTableSortChange}
                                />
                            </div>
                        </>
                    ) : (
                        <span className="msg msg-warning !flex mx-auto w-fit">
                            There are no results for this search
                        </span>
                    )
                }
            </div>
        </section>
    );
};


export { PropertyProfileContentOverviewTable };
