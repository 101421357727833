import { useState, useEffect } from 'react';
import { usePropertyVirtualTours } from '../../../../../property_media/property_virtual_tour/hooks/usePropertyVirtualTours';
import { IPropertyVirtualTourRow } from '../../../../../../interfaces/asset';

export const usePropertyVirtualTour = (nhID: number) => {
    const [selectedFacility, setSelectedFacility] = useState<IPropertyVirtualTourRow>();
    const { data, isLoading } = usePropertyVirtualTours(['virtual-tours']);

    useEffect(() => {
        if (!data || isLoading) {
            return;
        }

        const selectedFacility = data?.rows.find(row => row.NHID === nhID);
        setSelectedFacility(selectedFacility);
    }, [data, nhID, isLoading]);

    const formData = !selectedFacility || isLoading ? null : {
        TourURL: selectedFacility.TourURL,
        NHID: selectedFacility.NHID
    };

    return { selectedFacility, formData, isLoading };
}; 