import { useState, useEffect } from 'react';
import { IPortalScoreItem } from '../../../../../../interfaces/property';
import { getPropertyVideos } from '../../../../../property_media/property_videos/utils';

export const usePropertyVideos = (nhID: number, profileCompletenessState: IPortalScoreItem) => {
    const [propertyVideos, setPropertyVideos] = useState<any[]>([]);

    useEffect(() => {
        const loadVideos = async () => {
            const videos = await getPropertyVideos([nhID], profileCompletenessState, null);
            setPropertyVideos(videos);
        };
        loadVideos();
    }, [nhID, profileCompletenessState]);

    return { propertyVideos, setPropertyVideos };
}; 