import React, { FC } from 'react';
import { Button, ButtonProps } from '../../../../components/common/button';
import { Modal } from '../../../../components/common/modal';
import BookATourForm from '../book_a_tour_form/book_a_tour_form';
import { ITourDefaultValues } from '../types';
import { failedToSaveTourError, prepareDataForForm } from '../helpers';
import { ModalTitle } from '../../../../components/common/modal/modalTitle';
import { useSessionPropertiesMap } from '../../../../hooks/use_session_properties_map';
import { longFormatDate } from '../../../../utils/date';
import { useUpdateTourDetails } from '../hooks/useUpdateTourDetails';

interface Props extends ITourDefaultValues {
    styleType?: ButtonProps['styleType'];
    className?: string;
    isModalOpen: boolean;
    toggleModal: () => void;
}

const EditTourAvailabilityModal: FC<Props> = (props) => {
    const { NHID, UpdatedAt, isModalOpen, toggleModal } = props;

    const defaultValues = prepareDataForForm(props);
    const hasExistingTourAvailability = !!defaultValues?.TourAvailabilityID;
    const formId = 'update-team-member';
    const propertyDictionary = useSessionPropertiesMap();
    const propertyName = propertyDictionary?.[NHID]?.Name || '';
    const { isPending, mutate } = useUpdateTourDetails(NHID);

    const actionButtons = (
        <Button type="submit" form={formId} isLoading={isPending}>
            Save Changes
        </Button>
    );

    const handleSubmit = (values: any) => {
        mutate(values, { onError: failedToSaveTourError });
        toggleModal();
    };

    const lastUpdatedText = `Last Updated: ${
        UpdatedAt ? longFormatDate(UpdatedAt) : 'No updates made'
    }`;
    const modalTitle = (
        <ModalTitle
            title={`${
                hasExistingTourAvailability ? 'Edit' : 'Add'
            } Tour Availabilty`}
            rightSideText={propertyName}
            subtitle={hasExistingTourAvailability ? lastUpdatedText : ''}
        />
    );

    return (
        <>
            <Modal
                isOpen={isModalOpen}
                onClose={toggleModal}
                title={modalTitle}
                footerContent={actionButtons}
                size="xl"
            >     
                <BookATourForm
                    id={formId}
                    defaultFormValues={defaultValues}
                    handleSubmit={handleSubmit}
                />
            </Modal>
        </>
    );
};

export { EditTourAvailabilityModal };
