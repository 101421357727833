import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { ISessionOrg, ISessionProperty } from '../../interfaces/session';
import ReviewScoreDisplayFormatted from './review_score_display_formatted';
import { useSelector } from 'react-redux';
import { IPortalScoreItem } from '../../interfaces/property';
import { 
    getTotalScoreForNHIDFromProfileData,
    getAverageScoreForNHIDsFromProfileData 
} from '../../utils/profile_completeness';

interface IProps {
    properties: ISessionProperty[];
    org: ISessionOrg;
    selNHID: number;
    handleCommunitySelect: (option: any) => void;
    hide: boolean;
    disabled?: boolean;
}

const renderReviewScore = (reviewScore: number) => {
    if (!reviewScore) {
        return;
    }

    return <ReviewScoreDisplayFormatted value={reviewScore} />;
};

const renderProfileCompleteness = (profileCompleteScore: number) => {

    return (
        <>
            <svg viewBox="0 0 36 36" className="h-10 w-10 min-h-10 min-w-10">
                <path className="fill-none stroke-brand_grey-light stroke-[4]" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                <path className="fill-none stroke-brand_secondary stroke-[4]" strokeDasharray={`${profileCompleteScore || 0},100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                <text x="18" y="22" className="text-[11.5px] fill-brand_secondary font-bold tracking-tighter text-anchor-middle">{ profileCompleteScore || 0 }<tspan className="text-[10px]">%</tspan></text>
            </svg>
        </>
    );
};

const renderOptionContent = (
    title: string,
    subTitle: string,
    reviewScore: number,
    profileCompleteScore: number
) => {
    return (
        <>
            <div className="flex items-center gap-2 lg:gap-3 whitespace-nowrap">
                <span>{title}</span>
                { subTitle ? <span className="text-brand_grey-medium">{subTitle}</span> : null }
                {renderProfileCompleteness(profileCompleteScore)}
                {renderReviewScore(reviewScore)}
            </div>
        </>
    );
};

const SingleValue = (props: any) => {
    const { title, subTitle, reviewScore, profileCompleteScore } =
        props.getValue()[0];

    return (
        <components.SingleValue {...props}>
            {renderOptionContent(
                title,
                subTitle,
                reviewScore,
                profileCompleteScore
            )}
        </components.SingleValue>
    );
};

const Option = (props: any) => {
    const { title, subTitle, reviewScore, profileCompleteScore } = props.data;

    return (
        <components.Option {...props}>
            {renderOptionContent(
                title,
                subTitle,
                reviewScore,
                profileCompleteScore
            )}
        </components.Option>
    );
};

const CommunitySelect = ({
    properties,
    org,
    selNHID,
    handleCommunitySelect,
    hide,
    disabled = false
}: IProps) => {
    
    const [communitySelectOptions, setCommunitySelectOptions] = useState<any[]>([]);

    const profileCompletenessState: IPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    useEffect(() => {
        const optionProperties = (properties || []).map((item: any) => {
            const subTitle = [
                item.Address1,
                item.City,
                item.PostCode,
                item.RegionCode
            ].join(', ');

            return {
                title: item.Name,
                value: { Type: 'property', ...item },
                subTitle: subTitle,
                reviewScore: item.ReviewScoreDisplay,
                profileCompleteScore: getTotalScoreForNHIDFromProfileData(profileCompletenessState, item.NHID)
            };
        });

        const options: any = [
            {
                label: 'Facilities',
                options: optionProperties
            }
        ];

        if (org) {
            options.unshift({
                title: org.Name,
                value: { Type: 'org', ...org },
                subTitle: '(Organization)',
                reviewScore: org.ReviewScoreDisplay,
                profileCompleteScore: profileCompletenessState.TotalOrgProfileScore || 0
            });
        } else {

            const NHIDs = properties.map((property: ISessionProperty) => property.NHID);

            options.unshift({
                title: `All ${properties?.length} Facilities`,
                value: { Type: 'allproperties' },
                subTitle: '',
                profileCompleteScore: getAverageScoreForNHIDsFromProfileData(profileCompletenessState, NHIDs)
            });
        }

        setCommunitySelectOptions(options);
    }, [properties, org, profileCompletenessState]);

    const getCurCommunitySelectOption = (): any => {
        if (selNHID) {
            let curOption = null;
            for (
                let orgIdx = 0;
                orgIdx < communitySelectOptions.length;
                orgIdx++
            ) {
                const orgOptions = communitySelectOptions[orgIdx];
                if (orgOptions.options) {
                    const foundOption: any = orgOptions.options.find(
                        (item: any) =>
                            (item.value || {}).Type === 'property' &&
                            selNHID === item.value.NHID
                    );

                    if (foundOption) {
                        curOption = foundOption;
                        break;
                    }
                }
            }

            if (curOption) {
                return curOption;
            }
        }
        return communitySelectOptions[0];
    };

    if (hide) {
        return null;
    }

    return (
        <div className="form-component form-select">
            <Select
                id="CommunitySelect"
                options={communitySelectOptions}
                getOptionLabel={(options) => {
                    return `${options.title} ${options.subTitle}`;
                }}
                name="CommunitySelect"
                classNamePrefix="select"
                value={getCurCommunitySelectOption()}
                onChange={(options: any) => handleCommunitySelect(options)}
                components={{ SingleValue, Option }}
                isDisabled={disabled}
                /*menuIsOpen={true}*/
                unstyled
            />
        </div>
    );
};

export default CommunitySelect;
