import React, { FC, InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import { ConnectForm } from '../form/form';
import cx from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    name: string;
    children?: ReactNode;
    disabled?: boolean;
}

const FormCheckbox: FC<Props> = (props) => {
    const {
        name,
        id,
        disabled = false,
        label,
        ...rest
    } = props;

    return (
        <ConnectForm>
            {({ register, formState }: UseFormReturn) => {
                const fieldError: { message?: string } =
                    formState?.errors?.[name];

                const errorMessage: ReactElement = (
                    <span>
                        {fieldError?.message}
                    </span>
                );


                const inputId: string = id || name;

                const inputProps = register?.(name);

                return (
                    <div className={cx('form-component form-checkbox', { 'form-error' : fieldError })}>
                        <label>
                            <input
                                className="peer"
                                type="checkbox"
                                id={inputId}
                                disabled={disabled}
                                {...(inputProps || {})}
                                {...rest}
                                onChange={(args: any) => {
                                    inputProps?.onChange?.(args);
                                    rest.onChange?.(args);
                                }}
                            />
                            <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                            {label}
                        </label>
                        {fieldError && errorMessage}
                    </div>
                );
            }}
        </ConnectForm>
    );
};

export { FormCheckbox };
