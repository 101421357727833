import React, { useEffect, useState } from 'react';
import { ISessionState } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import { PropertyMode } from '../../interfaces/session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck, faSlashForward } from '@fortawesome/pro-solid-svg-icons';

import { 
    IPortalScoreItem,
    IScoreExtraDisplayItem
} from '../../interfaces/property';

import { 
    mapProfileExtraDatatoDisplay,
} from '../../utils/profile_completeness';
import { LoadingDots } from '../common/loading_dots';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
    selNHID: number | null;
    setIsLoading?: any;
    isLoading?: boolean;
    propertyMode: PropertyMode,
    widgetSize: 'small' | 'large';
}

export const ProfileCompletnessWidgetOther = ({  
    nhIDs, 
    orgNHID, 
    selNHID, 
}: IProps) => {

    const [ scoreExtra, setScoreExtra ] = useState<IScoreExtraDisplayItem>();

    const profileCompletenessState: IPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    useEffect(() => {        
        const scoreExtra = mapProfileExtraDatatoDisplay(profileCompletenessState, selNHID);
        setScoreExtra(scoreExtra);

}, [ orgNHID, nhIDs, selNHID, profileCompletenessState ]);

    if (!scoreExtra) {
        return <LoadingDots/>;
    }

    const {
        FacilityCount,
        HasPhoneNumberCount,
        HasEmailAddressCount,
        HasWebsiteCount,
        HasPortalUsersCount,
    } = scoreExtra;

    const scoreExtraArray = [
        [HasPhoneNumberCount, FacilityCount, 'Inquiry Phone Number', '/contacts'],
        [HasEmailAddressCount, FacilityCount, 'Inquiry Email Address(es)', '/contacts'],
        [HasWebsiteCount, FacilityCount, 'Website Address', '/contacts'],
        [HasPortalUsersCount, FacilityCount, 'Covered by a 1+ Portal Users', '/users'],
    ];

    const orgIsSelected = orgNHID && !selNHID;

    const renderWidget = () => {
        return (
            <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col h-full">
                <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
                    <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                        Contact Details &amp; Portal Users
                    </h4>
                </div>
                <div className="p-3 sm:p-4 2xl:p-5 pb-0 sm:pb-1 2xl:pb-2 h-full">
                    <div className="[&>ul>li>a]:py-1.5 [&>ul>li]:border-t first:[&>ul>li]:border-none [&>ul>li]:border-brand_grey-light [&>ul>li>a]:flex [&>ul>li>a]:items-center [&>ul>li>a]:gap-2 sm:[&>ul>li>a]:gap-3 sm:[&>ul>li>a]:px-3">
                        <ul>
                            { 
                                scoreExtraArray.map((item: any) => (
                                    <li>
                                        <a href={item[3]}>
                                            <FontAwesomeIcon icon={item[0] === FacilityCount ? faCheck : faXmark} className={`min-w-6 min-h-6 ${item[0] === FacilityCount ? 'text-brand_secondary' : 'text-brand_status-error'}`} />
                                            <div className="min-w-16"></div>
                                            { FacilityCount > 1 ?
                                                <div className="flex xs_max:flex-col gap-1 flex-row sm:gap-3">
                                                    <div className="flex justify-center items-center bg-brand_faint-blue p-1 rounded-md link min-w-16 max-w-16 h-8">
                                                        <span className="w-4 text-center">{item[0]}</span>
                                                        <FontAwesomeIcon icon={faSlashForward} className="min-w-3 max-w-3 min-h-3 max-h-3 mx-0.5" />
                                                        <span className="w-4 text-center">{item[1]}</span>
                                                    </div>
                                                </div> : null
                                            }
                                            <span className="link">
                                                {item[2]}
                                            </span>
                                            <span className="ms-auto link">
                                                View
                                            </span>
                                        </a> 
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );    
    };

    return (
        <>
            { renderWidget() }
        </>
    );
};
