import * as yup from 'yup';
import { validationHelpers } from '../../../helpers';

const {
    yupNullableStringToNumberCost,
    yupNullableStringToNumberLT1000,
    yupNullableStringToBool
} = validationHelpers;

const schema = yup.object().shape({
    RespiteMinStay: yupNullableStringToNumberLT1000,
    RespiteMinStayRequirement: yupNullableStringToBool,
    RespitePrivateCostPerDay: yupNullableStringToNumberCost,
    RespitePrivateCostPerDayNA: yup.boolean().nullable(),
    RespitePrivateSuiteCostPerDay: yupNullableStringToNumberCost,
    RespiteRatePackagesAvailable: yupNullableStringToBool,
    RespiteRatesAllInclusive: yupNullableStringToBool,
    RespiteSemiPrivateCostPerDay: yupNullableStringToNumberCost,
    RespiteSemiPrivateCostPerDayNA: yup.boolean().nullable(),
    RespiteSharedCostPerDay: yupNullableStringToNumberCost,
    RespiteSharedCostPerDayNA: yup.boolean().nullable(),
});

export default schema;