import React from 'react';
import Select from 'react-select';
import FilterMatrix from '../../../modules/filter_matrix';
import { ISelectOption } from '../../../interfaces/form';
import { ExpressionType, rangeSelectOptions, SqlRange } from '../../../interfaces/filter_matrix';
import { FilterType, IFilterSet } from '../../../interfaces/filters';

interface IProps {
    filterMatrix: FilterMatrix;
    apply: ApplyFilter;
    notApplicableOption?: boolean;
}

export type ApplyFilter = (filter: FilterMatrix, key: string, value: unknown) => void;

export const RangeFilters = ({ filterMatrix, apply }: IProps) => {
    const resetRangeFilter = (filterKey: string) => {
        apply(filterMatrix, filterKey, new SqlRange(undefined));
    };

    const onRangeTypeChange = (key: string, operation: ISelectOption): void => {
        const targetFilter = filterMatrix.findFilter(key).Value as SqlRange || new SqlRange(undefined);
        const newOpcode = operation.value as ExpressionType || undefined;
        
        if (
            targetFilter.operation && 
            ((newOpcode === ExpressionType.In && targetFilter.operation !== ExpressionType.In) ||
            (newOpcode !== ExpressionType.In && targetFilter.operation === ExpressionType.In))
        ) {
            apply(filterMatrix, key, new SqlRange(newOpcode));
            return;
        }
        apply(filterMatrix, key, new SqlRange(newOpcode, targetFilter.from, targetFilter.to));
    };

    const onRangeFromChange = (key: string, value: string, maxAllowedLength?: number): void => {
        const existingFilter = filterMatrix.findFilter(key).Value as SqlRange;

        if (typeof existingFilter === 'string') {
            if (validateRangeFilterValue(value, ExpressionType.Equal, maxAllowedLength)) {
                apply(filterMatrix, key, new SqlRange(ExpressionType.Equal, value));
            }
            return;
        }

        if (!validateRangeFilterValue(value, existingFilter.operation, maxAllowedLength)) {
            return;
        }

        apply(filterMatrix, key, new SqlRange(existingFilter.operation, value, existingFilter.to));
    };

    const onRangeToChange = (key: string, newToValue: string, maxAllowedLength?: number): void => {
        const existingFilter = filterMatrix.findFilter(key).Value as SqlRange;
        if (!validateRangeFilterValue(newToValue, existingFilter.operation, maxAllowedLength)) {
            return;
        }

        apply(filterMatrix, key, new SqlRange(existingFilter.operation, existingFilter.from, newToValue));
    };

    const validateRangeFilterValue = (val: string, operation: string, maxAllowedLength: number): boolean => {
        if (!val.length) {
            return true;
        }

        if (operation === ExpressionType.In) {
            const splitArray = (val || '')
            .split(',')
            .filter(item => item && item.length)
            .map(item => item.replace(/\s+/g, ''));

            if (!splitArray.length) {
                return false;
            }

            const valArray = splitArray.map((item: any) => !!(isFinite(item) && item !== ''));
            return !valArray.includes(false);
        } else {
            const input: any = val.replace(/\s+/g, '');
            return !!(isFinite(input) && input !== '' && (maxAllowedLength === undefined || input.length <= maxAllowedLength));
        }
    };

    return (
        <>
            {
                filterMatrix.filters
                    .filter(filter => filter.Type === FilterType.Range)
                    .map((filter: IFilterSet) => {
                        const notEnteredOption = filterMatrix.getNotEnteredOption(filter.Key, false);
                        const rangeOptions = rangeSelectOptions(notEnteredOption);
                        const { operation, from, to } = filterMatrix.getRangeValue(filter.Key) || {};
                        const currentValue = rangeOptions.find(
                            (o: ISelectOption) => o.value === operation
                        );

                        return (
                            <div key={filter.Key} className="flex flex-wrap items-end gap-2">
                                <div className="form-component form-inline form-select min-w-[19rem] md:min-w-60">
                                    <label htmlFor="ProfileCompletenessExpr">
                                        Profile Completeness %
                                    </label>
                                    <Select
                                        options={rangeOptions}
                                        name={filter.Key}
                                        classNamePrefix="select"
                                        value={currentValue || null}
                                        onChange={(e) => onRangeTypeChange(filter.Key, e)}
                                        unstyled
                                    />
                                </div>
                                { !!operation && (
                                    <div className="flex gap-2 items-center">
                                        <div className="form-component form-textbox min-w-20 max-w-20">
                                            <input
                                                value={from || ''}
                                                onChange={(e) => onRangeFromChange(filter.Key, e.target.value)}
                                            />
                                        </div>
                                        {
                                            operation === ExpressionType.Between && (
                                                <div className="form-component form-textbox min-w-20 max-w-20">
                                                    <input
                                                        value={to}
                                                        onChange={(e) => onRangeToChange(filter.Key, e.target.value)}
                                                    />
                                                </div>
                                            )
                                        }
                                        <a
                                            className="text-xs link"
                                            onClick={() => resetRangeFilter(filter.Key)}
                                        >
                                            Clear
                                        </a>                        
                                    </div>
                                )}  
                            </div>
                        );
                        }
                    )
                }
            </>
        );
    };

