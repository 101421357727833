import React, { FC, useCallback, useState } from 'react';
import { Button } from '../../../../components/common/button';
import { ServicesAndAmenitiesModal } from './services_and_amenities_modal';
import { IServiceTotals } from '../../../../interfaces/service';
import { ProfileCompletenessFilter } from './components/profile_completeness_filter';
import { usePropertyServices } from '../../hooks/use_property_services';

interface IProps {
    row: IServiceTotals;
    isModalOpen: boolean;
    onClose: () => void;
}

const ModalQueryWrapper: FC<IProps> = ({ row, isModalOpen, onClose }) => {
    const [
        onlyShowProfileCompletenessFields,
        setOnlyShowProfileCompletenessFields
    ] = useState(null);

    const { isLoading, tabConfig, defaultValues, lastUpdatedText, fieldsIncludedInProfileCompleteness } = usePropertyServices({
        nhid: row.NHID,
        enabled: isModalOpen,
        onlyShowProfileCompletenessFields,
    });

    const handleFilterChange = useCallback(
        (filterProfileCompletnessFields: boolean | null) => {
            setOnlyShowProfileCompletenessFields(
                filterProfileCompletnessFields
            );
        },
        []
    );

    return (
        <>
            <ServicesAndAmenitiesModal
                defaultFormValues={defaultValues}
                fieldsIncludedInProfileCompleteness={fieldsIncludedInProfileCompleteness}
                isLoading={isLoading}
                isOpen={isModalOpen}
                onReturnToFormWithIncompleteFields={handleFilterChange}
                onClose={onClose}
                lastUpdated={lastUpdatedText}
                row={row}
                headerActions={
                     !isLoading && <div className="my-3 sm:my-4 2xl:my-5 flex items-center justify-center gap-3">
                        <ProfileCompletenessFilter onChange={handleFilterChange} value={onlyShowProfileCompletenessFields} />
                    </div>
                }
                tabConfig={tabConfig}
            />
        </>
    );
};

export { ModalQueryWrapper };
