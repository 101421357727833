import { usePropertyServices } from '../../../../hooks/use_property_services';
import { usePropertyServiceTotals } from '../../../../../../hooks/use_property_service_totals';

export const usePropertyServicesAndAmenities = (nhID: number, isOpen: boolean) => {
    const { isLoading, tabConfig, defaultValues, lastUpdatedText } = usePropertyServices({
        nhid: nhID,
        enabled: isOpen,
        onlyShowProfileCompletenessFields: null
    });

    const { data: propertyServiceTotals } = usePropertyServiceTotals({
        ResourceProfileCompleted: null
    });

    const row = propertyServiceTotals?.[0]?.find(item => item.NHID === nhID);

    return { isLoading, tabConfig, defaultValues, lastUpdatedText, row };
}; 