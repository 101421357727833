import React from 'react';
import { useRef } from 'react';
import moment from 'moment';
import VisibleRatingStars from '../../components/common/visible_rating_stars';
import { getWebsiteUrlDomain } from '../../utils/common';

interface IProps {
    data: any;
    published?: boolean;
}

const ReviewTableExpandableRows = ({ data, published }: IProps) => {

    const ref = useRef();
    const inViewport = true; //intersectionObserverAvailable() ? useIntersection(ref) : true; 

    const renderReviewContent = () => {

        if (!data.ReviewContent) return <></>;

        return (
            <p className="mt-2">
                <strong className="me-2">Review:</strong>
                <span className="text-brand_grey-medium">{ data.ReviewContent }</span>
            </p>
        );
    };

    const renderRatings = () => {

        if (!data.ReviewRating || !data.ReviewRating.length) return <></>;

        return (
            <div className="p-2 md:p-3 lg:p-4 md:border-s border-brand_grey">
                <ul className="flex flex-col gap-1.5 [&>li]:flex [&>li]:gap-3 [&>li>div]:w-[130px] [&>li>div>div>svg]:text-xl [&>li>div>div>span]:ms-auto">
                    {
                        data.ReviewRating.map((rating: any) => {
                            return (
                                <li key={rating.RatingName}>
                                    <span className="ms-auto whitespace-nowrap">
                                        { rating.RatingName }
                                    </span>
                                    {
                                        rating.RatingValueName === 'N/A'
                                        ? <div>no rating given</div>
                                        :                                     
                                        <VisibleRatingStars
                                            keyProp={rating.RatingName}
                                            value={rating.RatingValue}
                                        />
                                    }
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        );
    };

    const renderSubmittedBy = () => {
        return (
            <>
                {
                    !data.ExperienceTypeName
                    ? null
                    : <p className="mt-2">
                        <strong className="me-2">Reviewer Experience Type:</strong>
                        <span className="text-brand_grey-medium">{ data.ExperienceTypeName }</span>
                    </p>
                }
                {
                    !data.ConnectionName
                    ? null
                    : <p className="mt-2">
                        <strong className="me-2">Reviewer relationship to the resident or client:</strong>
                        <span className="text-brand_grey-medium">{ data.ConnectionName }</span>
                    </p>
                }
            </>
        );
    };

    const renderResponse = () => {

        const reply = data.Reply;

        if (reply) {
            return (
                <p className="mt-2">
                    <strong className="me-2">Reply:</strong>
                    <span className="text-brand_grey-medium">{ reply.ReplyContent } </span>
                { 
                    reply.AuxiliaryStatusKeyName === 'awaiting-moderation' ?
                        <strong>(Awaiting Moderation)</strong> : null
                } 
                </p> 
            );
        } else {
            return (
                <p className="mt-2">
                    <strong className="me-2">Reply:</strong>
                    <span className="text-brand_grey-medium">Not present</span>
                </p> 
            );
        }
    };

    const renderPropertyAddress = () => {
        const { Name } = data;
        const keys = [
            'PropertyAddress1', 
            'PropertyAddress2', 
            'City', 
            'StateCode', 
            'Zip' 
        ];

        const address =  keys.reduce((acc: string, key: string, index: number) => acc + 
            (data[key] ? data[key] + `${index < keys.length-1 ? ', ' : ''}` : ''), '');

        return (
            <>
                <p>
                    <strong className="me-2">{ Name }</strong>
                    <span className="text-brand_grey-medium">({ address })</span>
                    { renderWebsiteUrl() }
                </p> 
            </>
        );
    };

    const renderWebsiteUrl = () => {
        const { ReviewID, WebsiteUrl, StateSlug, CitySlug, PropertySlug } = data;

        if (!WebsiteUrl || !StateSlug || !CitySlug || !PropertySlug) {
            return null; 
        }

        return (
            <a className="block" key={`review-property-link-${ReviewID}`} href={`${WebsiteUrl}/${StateSlug}/${CitySlug}/${PropertySlug}/`} target="_blank" rel="noreferrer">
                View on { getWebsiteUrlDomain(WebsiteUrl) }
            </a>            
        );

    };

    const renderReviewHeadings = () => {

        return (
            <>
                <p className="mt-2">
                    <strong className="me-2">Display Name:</strong>
                    <span className="text-brand_grey-medium">{ data.ReviewerDisplayName }</span>
                </p>
                <p className="mt-2">
                    <strong className="me-2">Submitted Date:</strong>
                    <span className="text-brand_grey-medium">{ moment(data.CreatedAt).format('MMMM Do YYYY h:mma') }</span>
                </p>
                {
                    published ?
                    <p className="mt-2">
                        <strong className="me-2">Publication Date</strong>
                        <span className="text-brand_grey-medium">{ moment(data.LastPublishedDate).format('MMMM Do YYYY h:mma') }</span>
                    </p> : null
                } 
            </>
        );
    };

    const render = () => {

        return (
            <div className="flex flex-col gap-4 md:flex-row lg:gap-12">
                <div className="p-2 md:p-3 lg:p-4 w-full">
                    { renderPropertyAddress() }
                    { renderReviewHeadings() }
                    { renderReviewContent() }
                    { renderSubmittedBy() }
                    { renderResponse() }
                </div>
                { renderRatings() }
            </div>
        );
    };

    return (
        <div ref={ref}>
            {
                inViewport ? render() : null
            }
        </div>
    );
};
export default ReviewTableExpandableRows;
