import React, { ReactNode } from 'react';
import { Modal } from '../../../../components/common/modal';
import { Button } from '../../../../components/common/button';
import { Form } from '../../../../components/common/form';
import { ITab, useCreateTabs } from '../../../../hooks/use_create_tabs';
import {
    incompleteFieldsDialog,
    prepareCustomExtrasFormValuesForSubmission,
    prepareExtrasFormValuesForSubmission,
    prepareServicesFormValuesForSubmission
} from './helpers';
import { IServiceTotals } from '../../../../interfaces/service';
import { getDeepDiff } from '../../../../utils/common';
import { useUpdatePropertyServices } from '../../hooks/use_update_property_services';
import { showToast, ToastType } from '../../../../utils/toast';


interface IProps {
    defaultFormValues: Record<string, boolean | any>;
    headerActions?: ReactNode;
    fieldsIncludedInProfileCompleteness: string[],
    isLoading: boolean;
    isOpen: boolean;
    lastUpdated?: string;
    onClose: () => void;
    onReturnToFormWithIncompleteFields: (showOnlyProfileCompletenessFields: boolean) => void;
    row: IServiceTotals;
    tabConfig: ITab[];
}

const ServicesAndAmenitiesModal = (props: IProps) => {
    const {
        defaultFormValues,
        headerActions,
        fieldsIncludedInProfileCompleteness,
        isLoading,
        isOpen,
        lastUpdated,
        onClose,
        onReturnToFormWithIncompleteFields,
        row,
        tabConfig,
    } = props;
    const { TabHeaders, TabContent } = useCreateTabs(tabConfig, {
        isScrollTab: true
    });

    const { mutate, isPending } = useUpdatePropertyServices(row.NHID, {
        onSuccess: () => {
            onClose();
            showToast('Services & Amenities updated successfully. Please allow up to 60 minutes for the changes to show on the website.', ToastType.Success);
        }
    });

    const handleSubmit = async (data: Record<string, any>) => {
        const hasUncompletedProfileCompletenessFields = fieldsIncludedInProfileCompleteness.some((fieldKey: string) => data.services[fieldKey] === null);
        if (hasUncompletedProfileCompletenessFields) {
            const result = await incompleteFieldsDialog();
            if (result.isDismissed) {
                onReturnToFormWithIncompleteFields(true);
                return;
            }
        }

        const onlyChangedFields = getDeepDiff(defaultFormValues, data);
        
        await mutate({
            // For Services and ServiceCustomExtras, only send field values that have changed
            Services: prepareServicesFormValuesForSubmission(onlyChangedFields?.services || []),
            ServiceCustomExtras: prepareCustomExtrasFormValuesForSubmission(
                onlyChangedFields?.customExtras || []
            ),
            // ServiceExtras works differently than the others and needs all of the existing values to be sent along with changes
            ServiceExtras: prepareExtrasFormValuesForSubmission(data?.extras || []),
        });
    };

    const formId = String(row.NHID);

    const footerContent = (
        <Button form={formId} type="submit" isLoading={isPending} className="min-w-36">
            Update
        </Button>
    );

    const modalContent = !isLoading && defaultFormValues ? (
            <Form
                onSubmit={handleSubmit}
                defaultValues={defaultFormValues}
                id={formId}
            >
                <TabContent />
            </Form>
        ) : (
            <TabContent />
        );

        return (
            <>
            <Modal
                onClose={onClose}
                isOpen={isOpen}
                title='Services & Amenities'
                subTitle={row.PropertyName}
                lastUpdated={lastUpdated || '-'}
                tabHeaders={<TabHeaders />}
                size='xl'
                footerContent={footerContent}
                noBodyPadding
            >
                {headerActions}
                {modalContent}
            </Modal>
        </>
    );
};

export { ServicesAndAmenitiesModal };
