import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { getReviewReplyData } from '../../../../api/charts';
import { SingleFacilityChartLarge } from './single_facility_chart';
import { ISingleFacilityChartControllerProps } from '../types';
import { formatFromDateForAPIRequestUTC, formatToDateForAPIRequestUTC } from '../../../../utils/date';
import { getSingleProperty } from '../../../../utils/common';
import { useSelector } from 'react-redux';
import { ISessionProperty, ISessionState } from '../../../../interfaces/session';
import { LoadingDots } from '../../../common/loading_dots';
import moment from 'moment';

export const SingleFacilityChartController = ({ 
    selNHID,
}: ISingleFacilityChartControllerProps) => { 
    
    const { Session, Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    if (!Session) {
        return null;
    }

    const { SessionType } = Session;
    const singleProperty: ISessionProperty | null = getSingleProperty(SessionType, selNHID, Properties);
    const propertyName = singleProperty.Name;

    const now = new Date();
    const startDate = moment().subtract(365, 'days');

    // api sets start of month >= startDate
    if (startDate.date() !== 1) {
        startDate.subtract(1, 'months');
    }

    const filters = {
        DateFrom: formatFromDateForAPIRequestUTC(startDate.format()),
        DateTo: formatToDateForAPIRequestUTC(now),
        NHID: selNHID,
    };

    const { data: reviewReplyData, isLoading, isFetching } = useQuery({ 
        queryKey: [ 'chart', 'reviewreply',  filters ], 
        queryFn: async () => {
                        
            const data: any[] = await getReviewReplyData(
                filters.DateFrom, 
                filters.DateTo,
                filters.NHID
            ); 

            return data;
        },
    });

    if (isLoading || isFetching || !reviewReplyData) { 
        return <div className="flex h-full w-full justify-center items-center">
            <LoadingDots show={true} showInPlace={true} />
        </div>;
    }

    const totalReviewCount = reviewReplyData.length ? Math.max(...reviewReplyData.map(reviewReply => reviewReply.Reviews)) : 0;

    return <>
        <div className="flex justify-self-center">
            <strong>
                {propertyName} has {totalReviewCount} reviews in the last 12 months
            </strong> 
        </div>
        <SingleFacilityChartLarge 
            chartData={reviewReplyData}
        />
    </>;
};
