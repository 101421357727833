import { useState, useEffect } from 'react';
import { IGetPortalPropertyWebAndCardLogoAsset } from '../../../../../../interfaces/asset';
import { getPropertyLogos } from '../../../../../property_media/property_logos/utils';

export const usePropertyLogos = (nhID: number) => {
    const [propertyLogos, setPropertyLogos] = useState<IGetPortalPropertyWebAndCardLogoAsset[]>([]);

    useEffect(() => {
        const loadLogos = async () => {
            const logos = await getPropertyLogos([nhID], null);
            setPropertyLogos(logos);
        };

        loadLogos();
    }, [nhID]);

    return { propertyLogos, setPropertyLogos };
}; 