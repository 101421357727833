import React from 'react';
import { FormInput } from '../../../../../../components/common/form_input';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { onlyNumeric, yesNoOptions } from '../../../helpers';
import { useFormContext } from 'react-hook-form';
import { ProfileCompletenessTag } from '../../components/profile_completeness_tag';
import { SectionHeading } from '../../components/section_heading';
import { RoomTypeLink } from '../../components/room_type_link';
import { FormCheckbox } from '../../../../../../components/common/form_checkbox';

const RespiteCareCostTab = () => {
    const { watch, setValue } = useFormContext();

    const isRespiteCareSelected = watch('RespiteCare') === 'Yes';
    const isRoomTypeSharedSelected = watch('RoomTypeShared') === 'Yes';
    const isRoomTypePrivateRoomSelected = watch('RoomTypePrivateRoom') === 'Yes';
    const isRoomTypeSemiPrivateSelected = watch('RoomTypeSemiPrivate') === 'Yes';
    const isRoomTypePrivateSuiteSelected = watch('RoomTypePrivateSuite') === 'Yes';

    const isRespiteMinStayRequired = watch('RespiteMinStayRequirement') === 'Yes';


    const isRespiteSharedCostPerDayNAChecked = watch('RespiteSharedCostPerDayNA');
    const isRespitePrivateCostPerDayNAChecked = watch('RespitePrivateCostPerDayNA');
    const isRespiteSemiPrivateCostPerDayNAChecked = watch('RespiteSemiPrivateCostPerDayNA');

    const canUseSharedRoomRespite = isRespiteCareSelected && isRoomTypeSharedSelected;
    const canUsePrivateRoomRespite = isRespiteCareSelected && isRoomTypePrivateRoomSelected;
    const canUseSemiPrivateRoomRespite = isRespiteCareSelected && isRoomTypeSemiPrivateSelected;
    const canUsePrivateSuiteRespite  = isRespiteCareSelected && isRoomTypePrivateSuiteSelected;

    const allFieldsDisabled = !(
        canUsePrivateRoomRespite ||
        canUseSemiPrivateRoomRespite ||
        canUseSharedRoomRespite || 
        canUsePrivateSuiteRespite
    );

    return (
        <>
            <SectionHeading 
                title="Respite Care Cost" 
                subtitle={<>To edit the below inputs &lsquo;<a href={'/core-details'} className="link">Respite Care</a>&rsquo; and the relevant { <RoomTypeLink/> } must be set to &lsquo;Yes&rsquo;.</>}
            />
            <div className="grid gap-3 lg:gap-5 2xl:gap-6 px-3 sm:px-4 2xl:px-5">
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    disabled={!canUseSharedRoomRespite || isRespiteSharedCostPerDayNAChecked}
                    info={
                        <>
                            <FormCheckbox 
                                name="RespiteSharedCostPerDayNA"
                                id="RespiteSharedCostPerDayNA"
                                label="Not Applicable"
                                onChange={() => setValue('RespiteSharedCostPerDay', '')}
                                disabled={!canUseSharedRoomRespite}
                            />                     
                            <ProfileCompletenessTag
                                isVisible={canUseSharedRoomRespite && !isRespiteSharedCostPerDayNAChecked}
                            />
                        </>                     
                    }
                    inlineLabel={false}
                    label="Shared room"
                    maxWidth='5rem'
                    name="RespiteSharedCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    disabled={!canUsePrivateRoomRespite || isRespitePrivateCostPerDayNAChecked}
                    info={
                        <>
                            <FormCheckbox 
                                name="RespitePrivateCostPerDayNA"
                                id="RespitePrivateCostPerDayNA"
                                label="Not Applicable"
                                onChange={()=> setValue('RespitePrivateCostPerDay', '')}
                                disabled={!canUsePrivateRoomRespite}
                            />
                            <ProfileCompletenessTag
                                isVisible={canUsePrivateRoomRespite && !isRespitePrivateCostPerDayNAChecked}
                            />
                        </>                     
                    }
                    inlineLabel={false}
                    label="Private room"
                    maxWidth='5rem'
                    name="RespitePrivateCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    disabled={!canUseSemiPrivateRoomRespite || isRespiteSemiPrivateCostPerDayNAChecked}
                    info={
                        <>
                            <FormCheckbox 
                                name="RespiteSemiPrivateCostPerDayNA"
                                id="RespiteSemiPrivateCostPerDayNA"
                                label="Not Applicable"
                                onChange={()=> setValue('RespiteSemiPrivateCostPerDay', '')}
                                disabled={!canUseSemiPrivateRoomRespite}
                            />
                            <ProfileCompletenessTag
                                isVisible={canUseSemiPrivateRoomRespite && !isRespiteSemiPrivateCostPerDayNAChecked}
                            />
                        </>                     
                    }
                    inlineLabel={false}
                    label="Semi-private room"
                    maxWidth='5rem'
                    name="RespiteSemiPrivateCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    layout="horizontal"
                    disabled={!canUsePrivateSuiteRespite}
                    info={
                        <ProfileCompletenessTag
                            isVisible={false}
                        />
                    }
                    label="Private suite"
                    maxWidth='5rem'
                    name="RespitePrivateSuiteCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormRadioGroup
                    disabled={!isRespiteCareSelected}
                    groupLabel="Minimum stay required?"
                    followUpInput={
                        <FormInput
                            inlineLabel={false}
                            allowedPattern={onlyNumeric}
                            disabled={
                                !isRespiteMinStayRequired ||
                                !isRespiteCareSelected
                            }
                            maxWidth='5rem'
                            name="RespiteMinStay"
                            suffix="days"
                            type="number"
                        />
                    }
                    info={<ProfileCompletenessTag isVisible={false} />}
                    layout="horizontal"
                    name="RespiteMinStayRequirement"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    disabled={allFieldsDisabled}
                    groupLabel="Rates are all-inclusive?"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    layout="horizontal"
                    name="RespiteRatesAllInclusive"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    disabled={allFieldsDisabled}
                    groupLabel="Rate packages/bundles available?"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    layout="horizontal"
                    name="RespiteRatePackagesAvailable"
                    options={yesNoOptions}
                    showResetButton={true}
                />
            </div>
        </>
    );
};

export { RespiteCareCostTab };
