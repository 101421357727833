import React from 'react';
import { FormInput } from '../../../../../../components/common/form_input';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { ProfileCompletenessTag } from '../../components/profile_completeness_tag';
import { onlyNumeric, yesNoOptions } from '../../../helpers';
import { SectionHeading } from '../../components/section_heading';

const BedAllocationTab = () => {
    return (
        <>
            <SectionHeading title="Bed Allocation" />
            <div className="grid gap-3 lg:gap-5 2xl:gap-6 px-3 sm:px-4 2xl:px-5">
                <FormRadioGroup
                    layout="horizontal"
                    name="BedsAllDualLicenced"
                    options={yesNoOptions}
                    groupLabel="All beds dual-licensed for Medicare and Medicaid?"
                    showResetButton={true}
                    info={<ProfileCompletenessTag isVisible={true} />}
                />
                <FormInput
                    inlineLabel={false}
                    layout="horizontal"
                    name="BedsMedicaidCount"
                    label="Medicaid bed count"
                    type="number"
                    maxWidth='5rem'
                    allowedPattern={onlyNumeric}
                    info={<ProfileCompletenessTag isVisible={true} />}
                />
                <FormInput
                    inlineLabel={false}
                    layout="horizontal"
                    name="BedsMedicareCount"
                    label="Medicare bed count"
                    type="number"
                    maxWidth='5rem'
                    allowedPattern={onlyNumeric}
                    info={<ProfileCompletenessTag isVisible={true} />}
                />
            </div>
        </>
    );
};

export { BedAllocationTab };
