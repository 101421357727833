
import React, { useCallback, useEffect, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useLocation } from 'react-router-dom';

import { debounce, get } from 'lodash';

import { getReviewScore } from '../../api/review';
import ActiveFilters from '../../components/common/active_filters';
import DataTable, { DataTableSortOrder, INITIAL_ROWS_PER_PAGE } from '../../components/common/data_table';
import { LoadingDots } from '../../components/common/loading_dots';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import ReviewScoreTableExpandableRows from '../../components/reviews/review_score_table_expandable_rows';
import TableLoading from '../../components/tables/table_loading';
import { FilterType } from '../../interfaces/filters';
import { IPropertyReviewScore, IReviewScoreFilterState } from '../../interfaces/review';
import FilterMatrix from '../../modules/filter_matrix';
import { scrollToTop } from '../../utils/common';
import CsvExport, { DisplayType } from '../../components/csv_export/csv_export';
import { formatFileNameForCsvExport } from '../../utils/file';
import ReviewScoreDisplayFormatted from '../../components/common/review_score_display_formatted';
import ReviewScoreFullFormatted from '../../components/common/review_score_full_formatted';

import * as csvEportAPI from '../../api/csv_export';
import { getClientFormattedDateShort } from '../../utils/date';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck } from '@fortawesome/pro-solid-svg-icons';


interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const ReviewScore = ({ nhIDs, orgNHID }: IProps) => {
    const location = useLocation();
    const filters: IReviewScoreFilterState = get(location, 'state.filters', {});

    const [ firstLoad, setFirstLoad] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>();
    const [ reviewScores, setReviewScores] = useState<IPropertyReviewScore[]>();
    const [ totalRows, setTotalRows] = useState<number>(0);
    const [ totalFilteredRows, setTotalFilteredRows] = useState<number>(0);
    const [ pageOffset, setPageOffset ] = useState<number>(0);
    const [ pageLimit, setPageLimit ] = useState<number>(INITIAL_ROWS_PER_PAGE);
    const [ tableSortColumn, setTableSortColumn] = useState<string>(); 
    const [ tableSortOrder, setTableSortOrder] = useState<DataTableSortOrder>();
    const [ resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);

    const [ filterMatrix ] = useState(() => new FilterMatrix([
        {
            Key: 'ReviewScoreMin',
            FilterFormLabel: 'Review Score Min',
            Type: FilterType.Number,
            FilterPropName: 'ReviewScoreMin'
        },
        {
            Key: 'ReviewScoreMax',
            FilterFormLabel: 'Review Score Max',
            Type: FilterType.Number,
            FilterPropName: 'ReviewScoreMax'
        },
        {
            Key: 'NoReviewScore',
            FilterFormLabel: 'No Review Score',
            Type: FilterType.Boolean,
            FilterPropName: 'NoReviewScore'
        },
        {
            Key: 'BelowReviewScorePositiveReviewCountThreshold',
            FilterFormLabel: 'Less than required number of Positive Reviews',
            Type: FilterType.Boolean,
            FilterPropName: 'BelowReviewScorePositiveReviewCountThreshold'
        },
        { 
            Key: 'NHID', 
            FilterFormLabel: 'NHID' , 
            Type: FilterType.Number, 
            FilterPropName: 'NHID', 
            Hidden: true
        },

    ]));

    const isSinglePropertySession = nhIDs?.length === 1;
    const isSinglePropertySelected = !!filterMatrix.getFormFieldValue('NHID') || isSinglePropertySession;
    const avgRowClass = (orgNHID || nhIDs?.length > 1) && totalFilteredRows > 0 ? 'rdt_FirstRowHighlight' : ''; 

    useEffect(() => {
        // until we migrate to useQuery, this mess clears the filters and then re-sets nhid
        // so that filters aren't applied on the single facility page
        if (isSinglePropertySelected) {
            const nhID = filterMatrix.getFormFieldValue('NHID');
            filterMatrix.reset();
            filterMatrix.setValue('NHID', nhID);
            fetchDataDebounced();
        }
    }, [isSinglePropertySelected]);
    
    


    const showActiveFilters = () => !isSinglePropertySelected && firstLoad;

    const getRequestParams = () => {
        const params = filterMatrix.getRequestParams();

        if (params.NHID) {
            params.NHIDs = [params.NHID];
            delete params.NHID;
        }

        return params;
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);

            const params = {
                ...getRequestParams(),
                Offset: pageOffset,
                Limit: pageLimit,
                SortField: tableSortColumn,
                SortOrder: tableSortOrder
            };

            if (params.NHID) {
                params.NHIDs = [params.NHID];
                delete params.NHID;
            }

            const { reviewScores, totalRows, totalFilteredRows } = await getReviewScore(params);
            
            if (reviewScores) {
                const hideAvgRowForResults = isSinglePropertySession || totalFilteredRows === 0;

                if (hideAvgRowForResults) {
                    reviewScores.shift(); 
                }

                const expandFirstDataRow = isSinglePropertySession || totalFilteredRows === 1;

                const withUpdatedRows = reviewScores.map((rs: IPropertyReviewScore, index: number) => {
                    const disabled = rs.isAvgRow || !rs.ReviewScoreDisplay;
                    const expanded = expandFirstDataRow 
                        ? isSinglePropertySession || index === 1
                        : false;

                    return { ...rs, disabled, expanded};
                });

                setReviewScores(withUpdatedRows);
                setTotalRows(totalRows);
                setTotalFilteredRows(totalFilteredRows);

                if (!firstLoad) {
                    setFirstLoad(true);
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDataDebounced = useCallback(debounce(() => fetchData(), 100), []);

    useEffect(() => {        
        if (!orgNHID && !nhIDs) {
           return; 
        }

        const  { 
            NoReviewScore,
            BelowReviewScorePositiveReviewCountThreshold,
            NHID
        } = filters;

        if (NHID) {
            applyFilter('NHID', NHID, false);
        }

        if (NoReviewScore) {
            applyFilter('NoReviewScore', true, false);
        }

        if (BelowReviewScorePositiveReviewCountThreshold) {
            applyFilter('BelowReviewScorePositiveReviewCountThreshold', true, false);
        }
    }, []);


    useEffect(() => {     
        setResetPaginationToggle(!resetPaginationToggle);
        fetchDataDebounced();
    }, [  orgNHID, nhIDs, pageOffset, tableSortColumn, tableSortOrder ]);
    
    const applyFilter = (key: string, value: any, refreshData = true) => {
        filterMatrix.setValue(key, value);

        if (refreshData) {
            fetchDataDebounced();
        }
    };

    const formatFacility = (data: IPropertyReviewScore) => {
        if (data.isAvgRow) {
            return <strong>Averages from all {data.OrgPropertyCount} {data.OrgName} Communities</strong>;
        }

        const { Name, Address } = data;
        
        return (
            <>
                <div className="flex flex-wrap gap-2 break-words py-1">
                    <strong className="block leading-tight lg:text-lg">
                        { Name }
                    </strong>
                    <span className="inline-block text-xs lg:text-sm leading-tight text-brand_grey-medium">
                        { Address }
                    </span>
                </div>
            </>
        );
    };

    const columns = [
        {
            name: 'Facility Name',
            selector: (row: IPropertyReviewScore) => row.Name,
            cell: (row: IPropertyReviewScore) => formatFacility(row),
            sortable: true,
            sortField: 'Name',
            id: 'name',
            width: '*'
        },
        {
            name: 'Review Score',
            selector: (row: IPropertyReviewScore) => row.ReviewScoreDisplay,
            cell: (row: IPropertyReviewScore) => formatReviewScore(row),
            sortable: true,
            sortField: 'ReviewScoreDisplay',
            id: 'score-display',
            width: '*'
        },
        {
            name: '# Reviews in the last 24 Months',
            selector: (row: IPropertyReviewScore) => <>{row.ReviewScoreTotalReviewCount}</>,
            sortable: true,
            sortField: 'ReviewScoreTotalReviewCount',
            id: 'review-count',
            width: '*'
        },
        {
            name: <div>Average Overall Rating of all reviews in the last 24 months <strong>(up to 5 points available)</strong></div>,
            selector: (row: IPropertyReviewScore) => row.ReviewScoreQualityScore,
            cell: (row: IPropertyReviewScore) => <><strong>{row.ReviewScoreQualityScore}</strong></>,
            sortable: true,
            sortField: 'ReviewScoreQualityScore',
            id: 'average-rating',
            width: '*'
        },
        {
            name: '# Positive Reviews (Overall Rating = 4 or 5) in  the last 24 months',
            selector: (row: IPropertyReviewScore) => <>{row.ReviewScorePositiveReviewCount}</>,
            sortable: true,
            sortField: 'ReviewScorePositiveReviewCount',
            id: 'positive-reviews',
            width: '*'
        },
        {
            name: '# Positive Reviews required in the last 24 months for max points',
            selector: (row: IPropertyReviewScore) => <>{row.isAvgRow ? '' : `${row.ReviewScorePositiveReviewCountThreshold}+`}</>,
            sortable: false,
            sortField: 'ReviewScorePositiveReviewCountThreshold',
            id: 'positive-reviews-threshold',
            width: '*'
        },
        {
            name: <div>Points from Positive Reviews in the last 24 months <strong>(up to 5 points available)</strong></div>,
            selector: (row: IPropertyReviewScore) => row.ReviewScoreQuantityScore,
            cell: (row: IPropertyReviewScore) => <><strong>{row.ReviewScoreQuantityScore}</strong></>,
            sortable: true,
            sortField: 'ReviewScoreQuantityScore',
            id: 'points',
            width: '*'
        },
        {
            name: 'Date of Most Recent Review',
            selector: (row: IPropertyReviewScore) => row.MostRecentReviewDate ? getClientFormattedDateShort(row.MostRecentReviewDate) : null,
            sortable: true,
            sortField: 'MostRecentReviewDate',
            id: 'date-recent-review',
            width: '*'
        },
    ];

    const formatReviewScore = ({ ReviewScoreDisplay, ReviewScoreFull }: IPropertyReviewScore) => {
        return (
            <div className="text-center mx-auto [&>div]:text-lg [&>div]:mb-1 [&>span]:font-semibold">
                <ReviewScoreDisplayFormatted value={ReviewScoreDisplay} />
                <ReviewScoreFullFormatted value={ReviewScoreFull} />
            </div>
        );
    };

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        applyFilter('NHID', selNHID);
    });

    const applyMinMax = (filter: string, val: string) => {
        const numVal = val ? Number(val) : null;

        if (filter === 'ReviewScoreMin' && numVal) {
            const maxValue = Number(filterMatrix.getFormFieldValue('ReviewScoreMax')) || 10;

            if (numVal > maxValue) {
                applyFilter('ReviewScoreMin', maxValue); 
                return;
            }
    
            if (numVal < 0) {
                applyFilter(filter, 0); 
                return;
            }
        }
        if (filter === 'ReviewScoreMax' && numVal) {
            const minValue = Number(filterMatrix.getFormFieldValue('ReviewScoreMin')) || 0;
            if (minValue > numVal) {
                applyFilter('ReviewScoreMax', minValue);
                return;
            }

            if (numVal > 10) {
                applyFilter(filter, 10); 
                return;
            }
        }

        applyFilter(filter, numVal); 
    };

    const onPageChange = async (pageNumber: number) => {
        const newPageOffset = (pageNumber - 1) * pageLimit;

        if (newPageOffset !== pageOffset) {
            setPageOffset(newPageOffset);
            scrollToTop();
        }
    };

    const onLimitChange = async (newPerPage: number, pageNumber: number) => {
        if (newPerPage !== pageLimit) {
            setPageOffset((pageNumber - 1) * pageLimit);
            setPageLimit(newPerPage);
            scrollToTop();
        }
    };

    const onSortChange = (column: TableColumn<any>, sortOrder: SortOrder) => {
        if (column?.sortField && sortOrder) {
            setTableSortColumn(column.sortField);
            setTableSortOrder(sortOrder === 'asc' ? DataTableSortOrder.Ascending : DataTableSortOrder.Descending);            
        }
    };

    const startExport = async (): Promise<string> => {
        return csvEportAPI.startExport('/review/reviewscore/export', getRequestParams());
    };

    const resetFilters = () => {
        filterMatrix.reset();
        fetchDataDebounced();
    };

    const selectedNHID = filterMatrix.getRequestParams().NHID;

    return (
        <>
            <PageCommunitySelectWrapper
                label={'All Review Scores for'}
                handleSelect={handleSelect}
                selNHID={selectedNHID}
            />   
            <section>
                <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                    {
                        !isSinglePropertySelected ? 
                        <>
                            <div className="relative ring-1 ring-brand_grey rounded-xl p-3 sm:p-4 bg-brand_faint-blue mb-4 sm:mb-6 md:mb-8 lg:mb-10 flex flex-wrap gap-3 items-center">
                                <div className="flex flex-wrap gap-3 items-center xl:gap-7">
                                    <div className="form-component form-checkbox">
                                        <label>
                                            <input
                                                className="peer"
                                                type="checkbox"
                                                checked={filterMatrix.getFormFieldValue('NoReviewScore')}
                                                id="NoReviewScore"
                                                onChange={(e) => applyFilter('NoReviewScore', e.target.checked || null)}
                                            />
                                            <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                                            No Review Score
                                        </label>         
                                    </div>
                                    <div className="form-component form-checkbox">
                                        <label>
                                            <input
                                                className="peer"
                                                type="checkbox"
                                                checked={filterMatrix.getFormFieldValue('BelowReviewScorePositiveReviewCountThreshold')}
                                                id="BelowReviewScorePositiveReviewCountThreshold"
                                                onChange={(e) => applyFilter('BelowReviewScorePositiveReviewCountThreshold', e.target.checked || null)}
                                            />
                                            <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                                            Less than required number of Positive Reviews
                                        </label>         
                                    </div>
                                    <div className="flex flex-col gap-3 sm:flex-row xl:gap-7">
                                        <div className="form-component form-inline form-textbox min-w-48 max-w-48">
                                            <label
                                                htmlFor="ReviewScoreMin"
                                            >
                                                Min Review Score
                                            </label>
                                            <input
                                                type="number"
                                                id="ReviewScoreMin"
                                                onChange={(e) => applyMinMax('ReviewScoreMin', e.target.value)}
                                                value={filterMatrix.getFormFieldValue('ReviewScoreMin')}
                                                min={0} 
                                                max={10}
                                                step="0.1"
                                            />
                                        </div>
                                        <div className="form-component form-inline form-textbox min-w-48 max-w-48">
                                            <label
                                                htmlFor="ReviewScoreMax"
                                            >
                                                Max Review Score
                                            </label>
                                            <input
                                                type="number"
                                                id="ReviewScoreMax"
                                                onChange={(e) => applyMinMax('ReviewScoreMax', e.target.value)}
                                                value={filterMatrix.getFormFieldValue('ReviewScoreMax')}
                                                min={filterMatrix.getFormFieldValue('ReviewScoreMin')} 
                                                max={10}
                                                step="0.1"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className="ms-auto btn btn-outline btn-outline-tertiary btn-small flex items-center text-md"
                                    onClick={resetFilters}
                                >
                                    <FontAwesomeIcon icon={faXmark} className="me-1" />Reset
                                </button>
                            </div>
                        </> : null
                    }
                    <div className="flex flex-col items-center text-center gap-3 md:flex-row md:text-start">
                        { showActiveFilters() ? 
                            <ActiveFilters 
                                filterMatrices={[filterMatrix]} 
                                isLoading={isLoading}
                                pageOffset={pageOffset}
                                pageLimit={pageLimit}
                                singularNoun={'Community'}
                                pluralNoun={'Communities'}
                                totalFilteredSize={totalFilteredRows}
                                totalSize={totalRows}
                                showTotals={true}
                                applyFilter={applyFilter}
                            /> : null
                        }
                        <div className="md:ms-auto">
                            <CsvExport 
                                startExportFunction={startExport}
                                modalTitleSuffix={'Review Score'}
                                label="Export as CSV"
                                displayType={DisplayType.Button}
                                fileName={`${formatFileNameForCsvExport('review_score')}`}
                            /> 
                        </div>
                    </div>
                    <div className="mt-4 sm:mt-6 md:mt-8 lg:mt-10">
                        <div data-table="Review Score Breakdown" className={`relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none ${avgRowClass}`}>
                            { isLoading ? <LoadingDots show={true} /> : null }
                            <DataTable
                                columns={columns}
                                data={reviewScores}
                                pagination={true}
                                paginationServer={true}
                                paginationTotalRows={totalFilteredRows}
                                onChangePage={onPageChange}
                                onChangeRowsPerPage={onLimitChange}
                                sortServer={true}
                                onSort={onSortChange}
                                fixedHeader={false}
                                expandableRows
                                expandableRowExpanded={(row: any) => row.expanded}
                                expandableRowDisabled={(row: any) => row.disabled}
                                expandableRowsComponent={ReviewScoreTableExpandableRows}
                                expandableIcon={{
                                    collapsed: <div>View Full Break-<br/>down</div>,
                                    expanded: <div>Hide Full Break-<br/>down</div>
                                }}
                                noDataComponent={
                                    <TableLoading 
                                        loading={isLoading} 
                                        /*loadingMessage="Loading Properties..." */
                                        noDataMessage="No Properties found" 
                                    />
                                }
                            />
                        </div>
                    </div>
                </div>
            </section> 
   
            
                            

                            
                                              
                                       


                            


        </>
    );
};

export default ReviewScore;