import React, { useRef } from 'react';
import { useSyncScroll } from '../../hooks/use_sync_scroll';

interface ISyncScrollBarProps {
    width?: number;
    syncedSelector: string;
}

export const SyncScrollBar = ({ width = 2146, syncedSelector }: ISyncScrollBarProps) => {
    const scrollBarRef = useRef<HTMLDivElement>(null);

    useSyncScroll({ 
        primaryRef: scrollBarRef,
        syncedSelector: syncedSelector
    });

    return (
        <div ref={scrollBarRef} className="hidden xl:block scrollbar overflow-x-scroll mb-3">
            <div className="h-1" style={{ width: `${width}px` }}></div>
        </div>
    );
}; 