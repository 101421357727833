import React, { useEffect, useState } from 'react';

import { mapProfileDatatoDisplay, getOrgProfileScoreFromProfileData } from '../../utils/profile_completeness';
import { useSelector } from 'react-redux';

import { 
    IPortalScoreItem,
    IScoreDisplayItem,
    ProfileScoreType
} from '../../interfaces/property';

interface IProps {
    selNHID: number;
    nhIDs: number[];
    orgNHID: number;
    profileScoreType: ProfileScoreType;
}

const ProfileCompletenessDial = ({ selNHID, nhIDs, orgNHID, profileScoreType }: IProps) => {

    const [ scoreItem, setScoreItem ] = useState<IScoreDisplayItem>(null);

    const profileCompletenessState: IPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    useEffect(() => {        
        const scoreArray = mapProfileDatatoDisplay(profileCompletenessState, selNHID);
        const orgScore = getOrgProfileScoreFromProfileData(profileCompletenessState);

        const scoreItem = scoreArray.find((item: IScoreDisplayItem) => 
            item.KeyName === profileScoreType);

        setScoreItem(scoreItem);
    }, [ orgNHID, nhIDs, selNHID, profileCompletenessState ]);


    const facilityCount = scoreItem?.FacilityCount || 0;
    const achievedScoreCount = scoreItem?.AchievedScoreCount || 0;
    const fieldDescription = scoreItem?.Description || '';
    const availableScore = scoreItem?.AvailableScore || 0;
    const scorePC = scoreItem?.ScorePC || 0;
    const label = scoreItem?.Label || '';

    const pcFull = 100 * (facilityCount > 0 ? achievedScoreCount / facilityCount : 0);
    const relativePC = availableScore * (facilityCount > 0 ? achievedScoreCount / facilityCount : 0);
    const realtivePCRounded = Math.round(relativePC * 10) / 10;

    const pcDisplay = Math.round(pcFull);

    const ringText = selNHID ? <>{pcDisplay}%</>
        : <>{realtivePCRounded}/{availableScore}</>;

    return (
        <>
            <div className="flex flex-col gap-3 sm:flex-row items-center text-center sm:text-start">
                <svg viewBox="0 0 36 36" className="block min-w-24 w-24 h-24">
                    <path className="fill-white stroke-brand_grey-light stroke-[4]" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                    <path className="fill-none stroke-brand_secondary stroke-[4] animate-[progress_1s_ease-out_forwards]" strokeDasharray ={`${pcFull},100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                    <text x="18" y="11" className="text-[4px] fill-brand_secondary font-bold tracking-tighter text-anchor-middle">Profile</text>
                    <text x="18" y="21.5" className="text-[10px] fill-brand_secondary font-bold tracking-tighter text-anchor-middle">{ringText}</text>
                    <text x="18" y="27" className="text-[4px] fill-brand_secondary font-bold tracking-tighter text-anchor-middle">Complete</text>
                </svg>
                <div>
                    <strong className="text-lg lg:text-xl xl:text-2xl block leading-tight mb-1">
                        {achievedScoreCount}/{facilityCount} Facilities are Profile Complete for {label}
                    </strong>
                    <span className="block text-sm leading-tight md:text-md">
                        {fieldDescription}
                    </span>
                </div>
            </div>
        </>
    );
};

export default ProfileCompletenessDial;
