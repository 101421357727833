import React, { FC, useState } from 'react';
import { Button } from '../../../../../components/common/button';
import { AddTeamMemberModal } from './add_team_member_modal';

interface Props {
    propertyId: number;
    isInSinglePropertyContext?: boolean;
}

const AddTeamMemberModalWithButton: FC<Props> = ({
    propertyId,
    isInSinglePropertyContext = false
}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => setIsModalOpen(!isModalOpen);

    return (
        <>
            <Button onClick={toggleModal}>
                Add Team Member
            </Button>
            <AddTeamMemberModal
                propertyId={propertyId}
                isInSinglePropertyContext={isInSinglePropertyContext}
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
            />
        </>
    );
};

export { AddTeamMemberModalWithButton };
