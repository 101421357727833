import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';

import PropertyVirtualTourForm from '../../../../property_media/property_virtual_tour/components/property_virtual_tour_form';
import PropertyVirtualTourSaveButton from '../../../../property_media/property_virtual_tour/components/property_virtual_tour_save_button';

import { CareOfferedCostFundingModal } from '../../../property_core_details/care_offered_modal/care_offered_cost_funding_modal';
import { UpdatePropertyDetailModal } from '../../../property_contact_details/modal/update_property_contact_details_modal';
import LogoUploadModal from '../../../../../components/assets/logo_upload_modal';
import BrochureUploadModal from '../../../../../components/assets/brochure_upload_modal';
import PhotoUploadModal from '../../../../../components/assets/photo_upload_modal';
import { ModalQueryWrapper } from '../../../property_services_and_amenities/services_and_amenities_modal/modal_query_wrapper';
import { EditTourAvailabilityModal } from '../../../property_tour_availability/components/edit_tour_availability_modal';
import VideoUploadModal from '../../../../../components/assets/video_upload_modal';
import PropertyVirtualTourModal from '../../../../property_media/property_virtual_tour/components/property_virtual_tour_modal';

import { useCareOffered } from './hooks/use_care_offered';
import { useContactDetails } from './hooks/use_contact_details';
import { usePropertyLogos } from './hooks/use_property_logos';
import { usePropertyBrochures } from './hooks/use_property_brochures';
import { usePropertyPhotos } from './hooks/use_property_photos';
import { usePropertyServicesAndAmenities } from './hooks/use_property_services';
import { useTourAvailability } from './hooks/use_tour_availability';
import { usePropertyVideos } from './hooks/use_property_videos';
import { usePropertyVirtualTour } from './hooks/use_property_virtual_tour';
import { getPropertyLogos } from '../../../../property_media/property_logos/utils';
import { getPropertyBrochures } from '../../../../property_media/property_brochures/utils';

import { 
    IContentOverviewModalProps, 
    IPropertyContactDetails, 
    IPropertyCoreDetailRow, 
    ProfileScoreExtraType, 
    ProfileScoreType 
} from '../../../../../interfaces/property';
import { IGetPortalPropertyWebAndCardLogoAsset } from '../../../../../interfaces/asset';
import { IPortalPropertyPhotoAsset } from '../../../../../interfaces/asset';
import { ITag } from '../../../../../interfaces/tag';

interface HookResults {
    CareOffered: { filteredData: IPropertyCoreDetailRow[] };
    ContactDetails: { activeContactDetails: IPropertyContactDetails };
    Logo: { 
        propertyLogos: IGetPortalPropertyWebAndCardLogoAsset[]; 
        setPropertyLogos: Dispatch<SetStateAction<IGetPortalPropertyWebAndCardLogoAsset[]>> 
    };
    PropertyBrochures: { 
        propertyBrochures: any[]; 
        setPropertyBrochures: Dispatch<SetStateAction<any[]>> 
    };
    PropertyPhotos: { 
        propertyPhotos: IPortalPropertyPhotoAsset[]; 
        setPropertyPhotos: Dispatch<SetStateAction<IPortalPropertyPhotoAsset[]>>;
        photoTags: ITag[] 
    };
    ServicesAmenities: { row: any; isLoading: boolean };
    TourAvailability: { tourDetails: any; isLoading: boolean };
    PropertyVideos: { 
        propertyVideos: any[]; 
        setPropertyVideos: Dispatch<SetStateAction<any[]>> 
    };
    VirtualTour: { selectedFacility: any; formData: any; isLoading: boolean };
}

interface ModalConfig<T extends keyof HookResults> {
    useHook: (nhID: number, keyName?: string, props?: IContentOverviewModalProps) => HookResults[T];
    component: React.ComponentType<any>;
    getProps: (hookResult: HookResults[T], baseProps: IContentOverviewModalProps) => any;
    shouldRender?: (hookResult: HookResults[T]) => boolean;
}

const getModalConfig = (keyName?: ProfileScoreType | ProfileScoreExtraType): 
    ModalConfig<'CareOffered'> | 
    ModalConfig<'ContactDetails'> | 
    ModalConfig<'Logo'> | 
    ModalConfig<'PropertyBrochures'> | 
    ModalConfig<'PropertyPhotos'> | 
    ModalConfig<'ServicesAmenities'> | 
    ModalConfig<'TourAvailability'> | 
    ModalConfig<'PropertyVideos'> | 
    ModalConfig<'VirtualTour'> => {
    switch (keyName) {
        case ProfileScoreType.FacilityBaseData:
        case ProfileScoreType.CostFunding:
            return {
                useHook: (nhID: number, keyName: ProfileScoreType) => useCareOffered(nhID, keyName),
                component: CareOfferedCostFundingModal,
                getProps: (hookResult, baseProps) => ({
                    isOpen: baseProps.isOpen,
                    setIsOpen: baseProps.onClose,
                    row: hookResult.filteredData?.[0],
                    hasCoreDetails: hookResult.filteredData?.[0]?.HasCoreDetails,
                    profileScoreType: baseProps.keyName as ProfileScoreType
                }),
                shouldRender: (hookResult) => !!hookResult.filteredData?.length
            } as ModalConfig<'CareOffered'>;

        case ProfileScoreExtraType.EmailAddress:
        case ProfileScoreExtraType.PhoneNumber:
        case ProfileScoreExtraType.Website:
            return {
                useHook: (nhID: number) => useContactDetails(nhID),
                component: UpdatePropertyDetailModal,
                getProps: (hookResult: HookResults['ContactDetails'], baseProps: IContentOverviewModalProps) => ({
                    propertyDetail: hookResult.activeContactDetails,
                    handleModalClose: baseProps.onClose,
                    isUpdateModalOpen: baseProps.isOpen
                }),
                shouldRender: (hookResult: HookResults['ContactDetails']) => !!hookResult.activeContactDetails
            } as ModalConfig<'ContactDetails'>;

        case ProfileScoreExtraType.Logo:
            return {
                useHook: (nhID: number) => usePropertyLogos(nhID),
                component: LogoUploadModal,
                getProps: (hookResult: HookResults['Logo'], baseProps: IContentOverviewModalProps) => ({
                    handleClose: baseProps.onClose,
                    handleReloadPropertyLogos: getPropertyLogos,
                    orgNHID: null as null,
                    nhIDs: [baseProps.nhID],
                    selNHID: baseProps.nhID,
                    propertyLogos: hookResult.propertyLogos,
                    setPropertyLogos: hookResult.setPropertyLogos,
                    busyLoadingAsset: false,
                    setBusyLoadingAsset: () => {}
                }),
                shouldRender: (hookResult: HookResults['Logo']) => !!hookResult.propertyLogos?.length
            } as ModalConfig<'Logo'>;

        case ProfileScoreType.PdfBrochure:
            return {
                useHook: (nhID: number) => usePropertyBrochures(nhID),
                component: BrochureUploadModal,
                getProps: (hookResult: HookResults['PropertyBrochures'], baseProps: IContentOverviewModalProps) => ({
                    handleClose: baseProps.onClose,
                    handleReloadPropertyBrochures: getPropertyBrochures,
                    orgNHID: null as null,
                    nhIDs: [baseProps.nhID],
                    selNHID: baseProps.nhID,
                    propertyBrochures: hookResult.propertyBrochures,
                    setPropertyBrochures: hookResult.setPropertyBrochures,
                    busyLoadingAsset: false,
                    setBusyLoadingAsset: () => {}
                }),
                shouldRender: (hookResult: HookResults['PropertyBrochures']) => !!hookResult.propertyBrochures?.length
            } as ModalConfig<'PropertyBrochures'>;

        case ProfileScoreType.Photo:
            return {
                useHook: (nhID: number) => {
                    const profileCompletenessState = useSelector(
                        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
                    );
                    return usePropertyPhotos(nhID, profileCompletenessState);
                },
                component: PhotoUploadModal,
                getProps: (hookResult: HookResults['PropertyPhotos'], baseProps: IContentOverviewModalProps) => ({
                    handleClose: baseProps.onClose,
                    handleReloadPropertyPhotos: () => Promise.resolve([]),
                    selNHID: baseProps.nhID,
                    propertyPhotos: hookResult.propertyPhotos,
                    setPropertyPhotos: hookResult.setPropertyPhotos,
                    busyLoadingAsset: false,
                    setBusyLoadingAsset: () => {},
                    photoTags: hookResult.photoTags,
                    setPhotoUploadMessages: () => {}
                }),
                shouldRender: (hookResult: HookResults['PropertyPhotos']) => !!hookResult.propertyPhotos?.length
            } as ModalConfig<'PropertyPhotos'>;

        case ProfileScoreType.ServicesAndAmenities:
            return {
                useHook: (nhID: number, _, props: IContentOverviewModalProps) => usePropertyServicesAndAmenities(nhID, props?.isOpen || false),
                component: ModalQueryWrapper,
                getProps: (hookResult: HookResults['ServicesAmenities'], baseProps: IContentOverviewModalProps) => ({
                    row: hookResult.row,
                    onClose: baseProps.onClose,
                    isModalOpen: baseProps.isOpen
                }),
                shouldRender: (hookResult: HookResults['ServicesAmenities']) => !!hookResult.row
            } as ModalConfig<'ServicesAmenities'>;

        case ProfileScoreType.TourAvailability:
            return {
                useHook: (nhID: number) => useTourAvailability(nhID),
                component: EditTourAvailabilityModal,
                getProps: (hookResult: HookResults['TourAvailability'], baseProps: IContentOverviewModalProps) => ({
                    isModalOpen: baseProps.isOpen,
                    toggleModal: baseProps.onClose,
                    ...hookResult.tourDetails
                }),
                shouldRender: (hookResult: HookResults['TourAvailability']) => !!hookResult.tourDetails
            } as ModalConfig<'TourAvailability'>;

        case ProfileScoreType.Video:
            return {
                useHook: (nhID: number) => {
                    const profileCompletenessState = useSelector(
                        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
                    );
                    return usePropertyVideos(nhID, profileCompletenessState);
                },
                component: VideoUploadModal,
                getProps: (hookResult: HookResults['PropertyVideos'], baseProps: IContentOverviewModalProps) => ({
                    handleClose: baseProps.onClose,
                    handleReloadPropertyVideos: () => Promise.resolve([]),
                    profileCompletenessFilterValue: null,
                    orgNHID: null,
                    nhIDs: [baseProps.nhID],
                    selNHID: baseProps.nhID,
                    propertyVideos: hookResult.propertyVideos,
                    setPropertyVideos: hookResult.setPropertyVideos,
                    busyLoadingAsset: false,
                    setBusyLoadingAsset: () => {}
                }),
                shouldRender: (hookResult: HookResults['PropertyVideos']) => !!hookResult.propertyVideos?.length
            } as ModalConfig<'PropertyVideos'>;

        case ProfileScoreExtraType.VirtualTour:
            return {
                useHook: (nhID: number) => usePropertyVirtualTour(nhID),
                component: PropertyVirtualTourModal,
                getProps: (hookResult: HookResults['VirtualTour'], baseProps: IContentOverviewModalProps) => {
                    const formId = 'virtual-tour-form';
                    const cacheKey = 'virtual-tours';
                    
                    const footerActions = <PropertyVirtualTourSaveButton formId={formId} />;
                    
                    const children = (
                        <PropertyVirtualTourForm
                            cacheKey={cacheKey}
                            nhID={baseProps.nhID}
                            handleModalClose={baseProps.onClose}
                            formData={hookResult.formData}
                            id={formId}
                        />
                    );
                    
                    return {
                        isOpen: baseProps.isOpen,
                        handleModalClose: baseProps.onClose,
                        selectedFacility: hookResult.selectedFacility,
                        footerActions,
                        children
                    };
                },
                shouldRender: (hookResult: HookResults['VirtualTour']) => !!hookResult.selectedFacility
            } as ModalConfig<'VirtualTour'>;

        default:
            throw new Error(`Unknown modal type: ${keyName}`);
    }
};

export const Modal: React.FC<{props: IContentOverviewModalProps}> = ({props}) => {
    const config = getModalConfig(props.keyName) as ModalConfig<keyof HookResults> & {
        getProps: (hookResult: any, baseProps: IContentOverviewModalProps) => any;
    };

    if (!config) {
       return null; 
    }

    const hookResult = config.useHook(props.nhID, props.keyName, props);

    if (!hookResult || (config.shouldRender && !config.shouldRender(hookResult))) {
        return null;
    }

    const modalProps = config.getProps(hookResult, props);
    
    return <config.component {...modalProps} />;
};