import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getPropertyCoreDetails } from '../../../api/property';
import { ISessionState, ISessionProperty } from '../../../interfaces/session';
import { 
    ProfileScoreType, 
    IPropertyScores, 
    IPropertyScoresItem, 
    IPropertyCoreDetailNHID, 
    IPropertyCoreDetailRow
} from '../../../interfaces/property';
import { RootState } from '../../../store/reducers';
import { DataTableSortOrder } from '../../../components/common/data_table';
import { boolSort, textSort } from '../../../utils/common';
import { getRoomTypesOffered } from '../property_core_details/utils/table_data_utils';

export const usePropertyCoreDetailsTableData = (
    sortField: string,
    sortOrder: DataTableSortOrder,
    selNHID?: number,
    profileCompletenessFilterValue: boolean | null = null,
    profileScoreType?: ProfileScoreType
): { filteredData: IPropertyCoreDetailRow[], rawData: IPropertyCoreDetailRow[] } => {

    const sessionState: ISessionState = useSelector(
        ({SessionState}: RootState) => SessionState
    );

    const profileCompletenessState = useSelector(
        ({ ProfileCompletenessState }: RootState) => ProfileCompletenessState
    );

    const { data: coreDetailData = [], isLoading } = useQuery({
        queryKey: ['property', 'core', 'details'],
        queryFn: getPropertyCoreDetails
    });

    if (isLoading) {
        return { filteredData: [], rawData: [] };
    }

    const getSessionData = (NHID: number) => {
        const property = (sessionState?.Properties || []).find((item: ISessionProperty) => (
            item.NHID === NHID
        ));

        const Addr = property ? [
            property.Address1,
            property.City,
            property.PostCode,
            property.RegionCode
        ].join(', ') : '';

        return {
            Addr,
            WebsiteURL: property?.WebsiteURL,
            StateSlug: property?.StateSlug,
            CitySlug: property?.CitySlug,
            Slug: property?.Slug,
            Name: property?.Name || ''
        };
    };

    const getProfileComplete = (nhID: number, scoreType: ProfileScoreType) => {
        const propertyScore = (profileCompletenessState?.PropertyScores || []).find((item: IPropertyScores) => (
            item.NHID === nhID
        ));

        const coreScore = (propertyScore?.Scores || []).find((item: IPropertyScoresItem) => (
            item.KeyName === scoreType
        ));

        return coreScore ? coreScore.Score === coreScore.AvailableScore : false;
    };
    
    const sortFn = () => {
        switch(sortField) {
            case 'Name':
                return textSort;
            case 'ProfileComplete':
                return boolSort;
            case 'LastUpdated':
                return textSort;
            default:
                console.log(`No sort function declared for column ${sortField}`);
                return textSort;
        }
    };

    const rawData = coreDetailData.map((item: IPropertyCoreDetailNHID): IPropertyCoreDetailRow => {
        const { Name, Addr, WebsiteURL, StateSlug, CitySlug, Slug } = getSessionData(item.NHID);
        const careOfferedProfileComplete = getProfileComplete(item.NHID, ProfileScoreType.FacilityBaseData);
        const costFundingProfileComplete = getProfileComplete(item.NHID, ProfileScoreType.CostFunding);
        
        return {
            NHID: item.NHID,
            HasCoreDetails: !!item.CoreDetails,
            CoreDetailsLastUpdated: item.CoreDetails?.CoreDetailsLastUpdated,
            CostFundingLastUpdated: item.CoreDetails?.CostFundingLastUpdated,
            Name,
            Address: Addr,
            CareOfferedProfileComplete: careOfferedProfileComplete,
            CostFundingProfileComplete: costFundingProfileComplete,
            LongTermCare: item.CoreDetails?.LongTermCare,
            ShortTermCare: item.CoreDetails?.ShortTermCare,
            RespiteCare: item?.CoreDetails?.RespiteCare,
            AdmitPediatricResidents: item.CoreDetails?.AdmitPediatricResidents,
            RoomTypesOffered: getRoomTypesOffered(item),
            SharedLongTermCost: item.CoreDetails?.LongTermSharedCostPerDay?.toString(),
            SharedShortTermCost: item?.CoreDetails?.ShortTermSharedCostPerDay?.toString(),
            SharedRespiteCost: item?.CoreDetails?.RespiteSharedCostPerDay?.toString(),
            PrivateLongTermCost: item?.CoreDetails?.LongTermPrivateCostPerDay?.toString(),
            PrivateShortTermCost: item?.CoreDetails?.ShortTermPrivateCostPerDay?.toString(),
            PrivateRespiteCost: item?.CoreDetails?.RespitePrivateCostPerDay?.toString(),
            SemiPrivateLongTermCost: item?.CoreDetails?.LongTermSemiPrivateCostPerDay?.toString(),
            SemiPrivateShortTermCost: item?.CoreDetails?.ShortTermSemiPrivateCostPerDay?.toString(),
            SemiPrivateRespiteCost: item?.CoreDetails?.RespiteSemiPrivateCostPerDay?.toString(),
            PrivateSuiteLongTermCost: item?.CoreDetails?.LongTermPrivateSuiteCostPerDay?.toString(),
            PrivateSuiteShortTermCost: item?.CoreDetails?.ShortTermPrivateSuiteCostPerDay?.toString(),
            PrivateSuiteRespiteCost: item?.CoreDetails?.RespitePrivateSuiteCostPerDay?.toString(),
            MedicaidBedCount: item.CoreDetails?.BedsMedicaidCount?.toString(),
            MedicareBedCount: item.CoreDetails?.BedsMedicareCount?.toString(),
            AcceptMedicareAdvantagePlans: item.CoreDetails?.AcceptMedicareAdvantage,
            AcceptMedicaidPendingPatients: item.CoreDetails?.AcceptMedicaidPending,
            InsuranceDetails: item?.InsuranceTypes,
            FacilityHasMemoryCareUnit: item.CoreDetails?.FacilityHasMemoryCareUnit,
            FacilityHasBehaviouralHealthUnit: item.CoreDetails?.FacilityHasBehaviouralHealthUnit,
            OtherFacilityTypes: item.CoreDetails?.OtherFacilityTypes,
            MinAgeRequirement: item.CoreDetails?.MinAgeRequirement,
            MinAge: item.CoreDetails?.MinAge,
            WebsiteURL,
            StateSlug,
            CitySlug,
            Slug,
            ...item.CoreDetails
        };
    }).sort(sortFn()(sortField, sortOrder));

    const filteredData = rawData.filter((item: IPropertyCoreDetailRow) => {
        
        const nhIDValid = !selNHID || item.NHID === selNHID;

        const itemProfileCompletenessValue = profileScoreType === ProfileScoreType.FacilityBaseData
            ? item.CareOfferedProfileComplete 
            : item.CostFundingProfileComplete;
        const profileCompletenessFilterValid = profileCompletenessFilterValue === null || 
            profileCompletenessFilterValue === itemProfileCompletenessValue;
        return nhIDValid && profileCompletenessFilterValid;
    });

    return {filteredData, rawData};

}; 