import React from 'react';
import { NavLink } from 'react-router-dom';
import { 
    IReviewScoreFilterState
} from '../../../interfaces/review';
import ReviewScoreDisplayFormatted from '../../common/review_score_display_formatted';
import { IReviewScoreWidgetSmallProps } from './types';
import { buildMultiBulletsNoReviewScore, buildSingleBulletsNoReviewScore, renderScoreCardTxt } from './Review_score_widget_common';

const ReviewScoreWidgetSmall = ({  
    propertyCountData,
    reviewScoreDisplay,
    reviewScoreFull,
    orgName,
    multiMode,
    singleProperty,
}: IReviewScoreWidgetSmallProps) => {
    const singlePropertyName = singleProperty ? singleProperty.Name : '';
    const nhID = singleProperty ? singleProperty.NHID : null;

    const renderHeader = () => {
        if (multiMode) {
            return renderMultiHeader();
        }
        
        return renderSingleHeader();
    };

    const renderMultiHeader = () => {
        return <>
            <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                Your Provider Review Score
            </h4>
            <ReviewScoreDisplayFormatted 
                value={reviewScoreDisplay}
                showAsNotApplicableOnNull={true}
            />
        </>;
    };

    const renderSingleHeader = () => {
        return <>
            <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                Your Review Score
            </h4>
            <ReviewScoreDisplayFormatted 
                value={reviewScoreDisplay}
                showAsNotApplicableOnNull={true}
            />
        </>;
    };

    const renderStats = () => {
        if (!reviewScoreFull) {
            return null;
        }

        if (multiMode) {
            return renderMultiStats();
        }
        return renderSingleStats();
    };

    const renderSingleStats = () => {
        const { 
            TotalCount, 
            TotalLessThan15PosReviewsIn24Mnths, 
        } = propertyCountData || {};

        const arr: [string, string, number, number, string, IReviewScoreFilterState][] = [
            [
                'Do you have less than the required number of positive reviews?', 
                '(click \'View breakdown\' to see the required number of positive reviews for maximum points)',
                TotalLessThan15PosReviewsIn24Mnths, TotalCount, 'score-card-2',
                { NHID: nhID }
            ]
        ];

        return arr.map((item: [string, string, number, number, string, IReviewScoreFilterState]) => (
            renderScoreCard(...item)));
    };

    const renderMultiStats = () => {
        const { 
            TotalCount, 
            TotalLessThan15PosReviewsIn24Mnths, 
            TotalNoReviewScore
        } = propertyCountData || {};
       
        const arr: [string, string, number, number, string, IReviewScoreFilterState][] = [
            [
                'Facilities with No Review Score', 
                '(as no valid reviews in the last 24 months)',
                TotalNoReviewScore, TotalCount, 'score-card-1',
                { NoReviewScore: true },
            ],
            [
                'Facilities with less than the required number of positive reviews', 
                '(click \'View Facilities\' to see the required number of positive reviews for maximum points)',
                TotalLessThan15PosReviewsIn24Mnths, TotalCount, 'score-card-3',
                { BelowReviewScorePositiveReviewCountThreshold: true }
            ]
        ];

        return arr.map((item: [string, string, number, number, string, IReviewScoreFilterState]) => (
            renderScoreCard(...item)));
    };

    const renderScoreCard = (hdr: string, detail: string, num: number, denom: number, key: string, 
        filters: IReviewScoreFilterState) => {

        return <div className="flex items-center gap-3" key={key}>
            <div className="flex items-center text-6xl">
                { renderScoreCardTxt(reviewScoreDisplay, num, denom) }
            </div>
            <p className="text-sm">
                { hdr }
                { ' ' }
                <NavLink className="link" to={'/review-score'} state={{ filters }}>
                    View
                </NavLink>
            </p> 
        </div>;
    };

    const buildMultiBulletsNoReviewScoreSmallView = () => buildMultiBulletsNoReviewScore(orgName, nhID).splice(2, 2);
    const buildSingleBulletsNoReviewScoreSmallView = () => buildSingleBulletsNoReviewScore(singlePropertyName, nhID).splice(1, 2);

    const buildBulletsSmallView = () => {
        if (multiMode) {
            return reviewScoreFull ? null : buildMultiBulletsNoReviewScoreSmallView();
        }
        
        return reviewScoreFull ? null : buildSingleBulletsNoReviewScoreSmallView();
    };

    return <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col">
        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
            {renderHeader()}
        </div> 
        <div className="p-3 sm:p-4 2xl:p-5 h-full">
            {
                !reviewScoreFull ? 
                <ul className="marker:text-brand_grey-medium list-disc ps-5 space-y-2">
                    {
                        buildBulletsSmallView().map((bullet: any, index: number) => (
                            <li key={`bullet-${index}`}>
                                {bullet}
                            </li>
                        ))
                    }
                </ul> : null
            }
            <div className="flex flex-col gap-5">
                { renderStats() }
            </div>
        </div>
        <div className="p-3 sm:p-4 2xl:p-5 !pt-0 mt-auto flex flex-wrap justify-center gap-3">
            <NavLink 
                className="btn !w-full text-center max-w-52"
                to={'/review-score'}
            >
                { multiMode ? 'View all Scores' : 'View breakdown' }
            </NavLink>
        </div>
    </div>;
};

export default ReviewScoreWidgetSmall;
