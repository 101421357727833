import { useState, useEffect } from 'react';
import { IPropertyContactDetails } from '../../../../../../interfaces/property';
import { getPropertyContactDetails } from '../../../../../../api/property';

export const useContactDetails = (nhID: number) => {
    const [activeContactDetails, setActiveContactDetails] = useState<IPropertyContactDetails>(null);

    useEffect(() => {
        const loadContactDetails = async () => {
            const [contactDetails, ] = await getPropertyContactDetails({
                NHIDs: [nhID],
                Offset: 0,
                Limit: 100,
                SortField: 'Name',
                SortOrder: 'DESC',
                OrgNHID: null
            });

            setActiveContactDetails(contactDetails[0]);
        };

        loadContactDetails();
    }, [nhID]);

    return { activeContactDetails };
}; 