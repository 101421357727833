export type LeadMetric =
    'All' |
    'PageViews' |
    'PhoneNumberViews' |
    'WebsiteReferrals' |
    'EmailInquiries' |
    'BrochureInquiries' | 
    'TourRequests';

export interface ILeadTotals {
    PageViews: number;
    PhoneNumberViews?: number;
    WebsiteReferrals?: number;
    EmailInquiries?: number;
    BrochureInquiries?: number;
    TourRequests?: number;
}

export enum LeadTemplateType {
    BrochureInquiry = 5,
    WebsiteInquiry = 1,
    CareInquiry = 12,
    GeneralInquiry = 14,
    JobInquiry = 16,
    OnsiteTourRequest = 18,
    RemoteTourRequest = 19
}
