import React from 'react';
import { NavLink } from 'react-router-dom';
import { IReviewsRequiringActionWidgetXSProps } from './types';
import { LoadingDots } from '../../common/loading_dots';

export const ReviewsRequiringActionWidgetXS = ({ 
    selNHID, 
    totalPendingPublication,
    totalPublishNoReplyLast90,
    loadingData,
}: IReviewsRequiringActionWidgetXSProps) => { 
    const dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);

    const date30DaysAgo = new Date(dateNow);
    date30DaysAgo.setDate(date30DaysAgo.getDate() - 30);

    const date90DaysAgo = new Date(dateNow);
    date90DaysAgo.setDate(date90DaysAgo.getDate() - 90);

    const renderNumber = (number: number, pulsePositive = false) => {
        if (pulsePositive && number) {
            return (
                <em className="block pulse min-w-6">
                    { number }
                </em>
            );
        }

        return <div className="block min-w-6">
            { number }
        </div>;
    };

    const renderText = (text: string, url: string, count: number, filters?: any) => {
        if (!count) {
            return (
                <span className="text-md">
                    { text }
                </span>  
            );
        }

        return (
            <NavLink
                className="link" 
                to={url}
                state={{filters}}
            >
                { text }
            </NavLink>  
        );
    };

    return <div className="ring-1 ring-brand_grey rounded-md lg:rounded-lg flex flex-col h-full 2xl:min-h-56 2xl:max-h-56">
        <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center rounded-t-md lg:rounded-t-lg">
            <h4 className="font-semibold leading-none text-lg lg:text-xl">
                Reviews requiring action
            </h4>
        </div>
        <div className="p-3 sm:p-4 2xl:p-5 h-full">
            <div className="flex flex-col justify-center items-center gap-2 2xl:h-full">
                { loadingData ? 
                    <LoadingDots show={true} showInPlace={true} />
                :
                    <>
                        <div className="flex flex-row gap-6 items-center">
                            <strong className="text-6xl text-center w-6">
                                { renderNumber(totalPendingPublication, true) }
                            </strong>
                            { 
                                renderText(
                                    'Reviews pending publication',
                                    '/pendingreviews', 
                                    totalPendingPublication, 
                                    { NHID: selNHID }
                                ) 
                            }
                        </div>
                        <div className="flex flex-row gap-6 items-center border-t border-brand_grey-light pt-2">
                            <strong className="text-6xl text-center w-6">
                                { renderNumber(totalPublishNoReplyLast90, true) }
                            </strong>
                            { 
                                renderText(
                                    'Recent reviews with no reply',
                                    '/reviews', 
                                    totalPublishNoReplyLast90, 
                                    { 
                                        DatePublishedFrom: date90DaysAgo,
                                        HasReply: false,
                                        NHID: selNHID,
                                    }
                                ) 
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    </div>;
};
