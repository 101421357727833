import React, { useCallback, useEffect, useState } from 'react';
import DataTable from '../../components/common/data_table';
import NoReviewData from '../../components/reviews/no_review_data';
import {
    fetchReviewOverviewData,
    buildOverviewColumns,
} from './reviews_list_common';

import type { IReviewsOverview } from '../../interfaces/review';
import { LoadingDots } from '../../components/common/loading_dots';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import { debounce } from 'lodash';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';
import * as csvExportAPI from '../../api/csv_export';
import { FilterType } from '../../interfaces/filters';
import FilterMatrix from '../../modules/filter_matrix';
import { FiltersContainer } from '../../components/common/filters';
import { useLocation } from 'react-router-dom';
import { isDefined } from '../../utils/common';

interface IReviewsListProps {
    nhIDs: number[];
    orgNHID: number;
}

const createFilters = () => {
    return new FilterMatrix([
        {
            Key: 'NumberOfReviewsWithin12MonthsRange', 
            FilterFormLabel: 'Number of reviews in last 12 months', 
            Type: FilterType.Range, 
            FilterPropName: 'NumberOfReviewsWithin12MonthsRange'
        },
        {
            Key: 'TotalNumberOfReviewsRange', 
            FilterFormLabel: 'Total number of reviews', 
            Type: FilterType.Range, 
            FilterPropName: 'TotalNumberOfReviewsRange'
        },
        {
            Key: 'ReviewScoreRange', 
            FilterFormLabel: 'Review Score', 
            Type: FilterType.Range, 
            FilterPropName: 'ReviewScoreRange',
        },
        { 
            Key: 'NHID', 
            FilterFormLabel: 'NHID', 
            Type: FilterType.Number, 
            FilterPropName: 'NHID', 
            Hidden: true},
    ]);
};

const ReviewsListOverview = ({ nhIDs, orgNHID }: IReviewsListProps) => {
    const [ busy, setBusy ] = useState<boolean>(false);
    const [ reviewsOverview, setReviewsOverview ] = useState<IReviewsOverview[]>([]);
    const [ totalRowsCount, setTotalRowsCount ] = useState<number>(0);
    const [ totalFilteredRowsCount, setTotalFilteredRowsCount ] = useState<number>(0);
    const [ filterMatrix ] = useState(createFilters());

    const location = useLocation();
    const { filters: locationfilters } = location.state ?? {};

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        applyFilter('NHID', selNHID);
    });

    const applyFilter = (key: string, value: any, refreshData = true) => {
        filterMatrix.setValue(key, value);

        if (refreshData) {
            getReviewDataDebounced();
        }
    };

    const getReviewDataDebounced = useCallback(debounce(() => getReviewData(), 100), []);

    const isSinglePropertySession = nhIDs?.length === 1;
    const isSinglePropertySelected = !!filterMatrix.getFormFieldValue('NHID') || isSinglePropertySession;

    useEffect(() => {
        const { NumberOfReviewsWithin12MonthsRange } = locationfilters ?? {};

        if (isDefined(NumberOfReviewsWithin12MonthsRange)) {
            applyFilter('NumberOfReviewsWithin12MonthsRange', NumberOfReviewsWithin12MonthsRange, false);
        } 

        getReviewDataDebounced();
    }, [JSON.stringify(nhIDs), orgNHID]);

    useEffect(() => {
        // reset filters and set NHID when individual property is selected
        if (isSinglePropertySelected) {
            const nhID = filterMatrix.getFormFieldValue('NHID');
            filterMatrix.reset();
            filterMatrix.setValue('NHID', nhID);
            getReviewDataDebounced();
        }
    }, [isSinglePropertySelected]);

    const getReviewData = async () => {
        setBusy(true);

        const tableData = await fetchReviewOverviewData(filterMatrix.getRequestParams());
        
        setReviewsOverview(tableData.rows);
        setTotalRowsCount(tableData.totalRowCount);
        setTotalFilteredRowsCount(tableData.totalFilteredRowCount);
        setBusy(false); 
    };

    const startExport = async (): Promise<string> => {
        return csvExportAPI.startExport('/review/overview/export', filterMatrix.getRequestParams());
    };

    const columns = buildOverviewColumns();

    const resetFilters = () => {
        filterMatrix.reset();
        getReviewDataDebounced();
    };

    return <>
            <PageCommunitySelectWrapper
                label={'Published Reviews for'}
                handleSelect={handleSelect}
                selNHID={filterMatrix.getRequestParams().NHID}
            />
            <section>
                <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                    { !isSinglePropertySelected ? 
                        <FiltersContainer 
                            filterMatrix={filterMatrix}
                            applyFilter={applyFilter}
                            resetFilters={resetFilters}
                            activeFilterProps={{
                                isBusy: busy,
                                showTotals: true,
                                totalCount: totalRowsCount,
                                totalFilteredCount: totalFilteredRowsCount,
                                pageOffset: 0,
                                pageLimit: 0,
                                refreshData: getReviewData,
                                singularNoun: 'Result',
                                pluralNoun: 'Results',
                            }}
                            exportProps={{
                                startExport,
                                fileName: 'reviews_overview',
                                modalTitleSuffix: 'Reviews Overview'
                            }}
                        /> : null
                    }     
                    <div className="mt-4 sm:mt-6 md:mt-8 lg:mt-10">
                        <div data-table="Published Reviews" className="relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none">
                            {/* busy ? <LoadingMessage  message={buildLoadingMessage(filterMatrix)} /> : null */}
                            { busy ? <LoadingDots show={true} /> : null }
                            <DataTable
                                columns={columns}
                                data={reviewsOverview}
                                fixedHeader={false}
                                noDataComponent={<NoReviewData loading={busy} />}
                            /> 
                        </div>
                    </div>
                </div>
            </section>
        </>;
};

export default ReviewsListOverview;
