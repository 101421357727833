import React, { useState } from 'react';
import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import { PropertyProfileContentOverviewTable } from './components/property_profile_content_overview_table';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyProfileContentOverview = ({ nhIDs, orgNHID }: IProps) => {
    const [selNHID, setSelNHID] = useState<number>();

    const handleSelect = (nhid: number) => {
        setSelNHID(nhid);
    };

    const isSinglePropertySelected = !!selNHID || nhIDs?.length === 1;

    return (
        <>
            <div className='mb-4'>
                <PageCommunitySelectWrapper
                    label={'Content Summary for'}
                    handleSelect={handleSelect}
                    selNHID={selNHID}
                />
            </div>
            <PropertyProfileContentOverviewTable selNHID={selNHID} isSinglePropertySelected={isSinglePropertySelected} />
        </>
    );
};

export { PropertyProfileContentOverview };


