import { useState, useEffect } from 'react';
import { IPortalPropertyPhotoAsset } from '../../../../../../interfaces/asset';
import { ITag } from '../../../../../../interfaces/tag';
import { getPhotoTags, getPropertyPhotos } from '../../../../../property_media/property-photos/utils';
import { IPortalScoreItem } from '../../../../../../interfaces/property';

export const usePropertyPhotos = (nhID: number, profileCompletenessState: IPortalScoreItem) => {
    const [propertyPhotos, setPropertyPhotos] = useState<IPortalPropertyPhotoAsset[]>([]);
    const [photoTags, setPhotoTags] = useState<ITag[]>([]);

    useEffect(() => {
        const loadPhotos = async () => {
            const photos = await getPropertyPhotos([nhID], null, profileCompletenessState, null, null);
            setPropertyPhotos(photos);

            const tags = await getPhotoTags();
            setPhotoTags(tags);
        };

        loadPhotos();
    }, [nhID, profileCompletenessState]);

    return { propertyPhotos, setPropertyPhotos, photoTags };
}; 