import React, { useContext, useState, useEffect } from 'react';
import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import DataTable, { DataTableSortOrder } from '../../../components/common/data_table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import ProfileCompletenessHeader from '../../../components/profile_completeness/profile_completeness_header';
import { ProfileScoreType, IPropertyCoreDetailRow } from '../../../interfaces/property';
import PropertyCoreDetailsForm from './property_core_details_form';
import PropertyCostFundingForm from './property_cost_funding_form';
import AppContext from '../../../context/app_context';
import { useCommunitySelectHistory } from '../../../hooks/use_community_select_history';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { shortFormatDate } from '../../../utils/date';
import PageTitle from '../../../components/common/page_title';
import { useLocation } from 'react-router-dom';
import { usePropertyCoreDetailsTableData } from '../hooks/use_property_core_details_table_data';
import { 
    formatCostOrUnknown, 
    getInsuranceDetails, 
    getMinAgeRequirement, 
    valueOrPlaceholder, 
    yesNoOrUnknown 
} from './utils/table_data_utils';
import { CareOfferedCostFundingModalButton } from './care_offered_modal/care_offered_cost_funding_modal_button';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
    pageTitle: string;
    profileScoreType: ProfileScoreType;
}

const PropertyCoreDetails = ({ nhIDs, orgNHID, pageTitle, profileScoreType }: IProps) => {
    const { state } = useLocation();
    const { targetId, targetNHID } = state || {};

    const [ selNHID, setSelNHID ] = useState<number>();
    const [sortField, setSortField] = useState<string>('Name');
    const [sortOrder, setSortOrder] = useState<DataTableSortOrder>(DataTableSortOrder.Ascending);
    const [ profileCompletenessFilterValue, setProfileCompletenessFilterValue ] = useState<boolean | null>(null);

    const singlePropertySession = nhIDs?.length === 1;
    const singleNHID = singlePropertySession ? nhIDs[0] : selNHID;

    const appContext: any = useContext(AppContext);

    useEffect(() => {
        if (targetNHID) {
            handleSelect(targetNHID);
        }

        const el = document.getElementById(targetId);
        if (el) {
          el.scrollIntoView();
        }
      }, [targetId, targetNHID]);
    
    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });
   
    const { filteredData, rawData } = usePropertyCoreDetailsTableData(
        sortField,
        sortOrder,
        selNHID,
        profileCompletenessFilterValue,
        profileScoreType
    );

    const formatCareOfferedProfileComplete = (row: IPropertyCoreDetailRow) => {

        return (
            <div className="mx-auto">
                <FontAwesomeIcon 
                    icon={row.CareOfferedProfileComplete ? faCheck : faXmark} 
                    className={`h-6 w-6 ${row.CareOfferedProfileComplete ? 'text-brand_status-success' : 'text-brand_status-error'}`}
                /> 
            </div>
        );
    };

    const formatCostFundingProfileComplete = (row: IPropertyCoreDetailRow) => {

        return (
            <div className="mx-auto">
                <FontAwesomeIcon 
                    icon={row.CostFundingProfileComplete ? faCheck : faXmark} 
                    className={`h-6 w-6 ${row.CostFundingProfileComplete ? 'text-brand_status-success' : 'text-brand_status-error'}`}
                /> 
            </div>
        );
    };

    const formatRoomTypesOffered = (row: IPropertyCoreDetailRow) => {
        return (
            <ul className="space-y-2" key={row.NHID}>
                {
                    row.RoomTypesOffered.map((item: string) => (
                        <li key={item}>
                            {item}
                        </li>
                    ))
                }
            </ul>
        );
    };

    const formatCostPerDay = (row: IPropertyCoreDetailRow) => {

        if (
            !row.RoomTypesOffered.includes('Shared') &&
            !row.RoomTypesOffered.includes('Private') &&
            !row.RoomTypesOffered.includes('Semi Private') 
        ) {
            return 'None Selected';
        }

        return (
            <ul className="space-y-2">
                {
                    row.RoomTypesOffered.includes('Shared') ?
                    <li>
                        <strong className="block">Shared</strong>
                        <span className="block"><em>Long Term:</em> {formatCostOrUnknown(row.SharedLongTermCost)}</span>
                        <span className="block"><em>Short Term:</em> {formatCostOrUnknown(row.SharedShortTermCost)}</span>
                        <span className="block"><em>Respite:</em> {formatCostOrUnknown(row.SharedRespiteCost)}</span>
                    </li> : null
                }
                {
                    row.RoomTypesOffered.includes('Private') ?
                    <li>
                        <strong className="block">Private</strong>
                        <span className="block"><em>Long Term:</em> {formatCostOrUnknown(row.PrivateLongTermCost)}</span>
                        <span className="block"><em>Short Term:</em> {formatCostOrUnknown(row.PrivateShortTermCost)}</span>
                        <span className="block"><em>Respite:</em> {formatCostOrUnknown(row.PrivateRespiteCost)}</span>
                    </li>: null
                }
                {
                    row.RoomTypesOffered.includes('Semi Private') ?
                    <li>
                        <strong className="block">Semi-private</strong>
                        <span className="block"><em>Long Term:</em> {formatCostOrUnknown(row.SemiPrivateLongTermCost)}</span>
                        <span className="block"><em>Short Term:</em> {formatCostOrUnknown(row.SemiPrivateShortTermCost)}</span>
                        <span className="block"><em>Respite:</em> {formatCostOrUnknown(row.SemiPrivateRespiteCost)}</span>
                    </li>: null
                }
            </ul>
        );
    };

    const formatPaymentType = (row: IPropertyCoreDetailRow) => {
        return (
            <ul className="space-y-2">
                <li>
                    <strong className="block">Medicaid Bed Count</strong>
                    <span className="block">{valueOrPlaceholder(row.MedicaidBedCount)}</span>
                </li>
                <li>
                    <strong className="block">Medicare Bed Count</strong>
                    <span className="block">{valueOrPlaceholder(row.MedicareBedCount)}</span>
                </li>
                <li>
                    <strong className="block">Accept Medicare Advantage plans</strong>
                    <span className="block">{yesNoOrUnknown(row.AcceptMedicareAdvantagePlans)}</span>
                </li>
                <li>
                    <strong className="block">Accept Medicaid pending patients</strong>
                    <span className="block">{yesNoOrUnknown(row.AcceptMedicaidPendingPatients)}</span>
                </li>
            </ul>
        );
    };
    
    const formatInsuranceTypes = (row: IPropertyCoreDetailRow) => {
        const [activeInsuranceCount, totalInsuranceCount] = getInsuranceDetails(row.InsuranceDetails);

        return (
            <div className="mx-auto text-center">
                <div className="text-lg">
                    <strong>{activeInsuranceCount}</strong> / <strong>{totalInsuranceCount}</strong>
                </div>
                <span className="text-xs block">
                    completed
                </span>
            </div>
        );
    };

    const renderCareOffered  = (row: IPropertyCoreDetailRow) => 
    <ul className="space-y-2" key={row.NHID}>
        <li><strong>Long Term Care:</strong> <span>{yesNoOrUnknown(row?.LongTermCare)}</span></li>
        <li><strong>Short Term Care:</strong> <span>{yesNoOrUnknown(row?.ShortTermCare)}</span></li>
        <li><strong>Respite Care:</strong> <span>{yesNoOrUnknown(row?.RespiteCare)}</span></li>
        <li><strong>Facility has a Memory Care Unit:</strong> <span>{yesNoOrUnknown(row?.FacilityHasMemoryCareUnit)}</span></li>
        <li><strong>Facility has a Behavioural Health Unit:</strong> <span>{yesNoOrUnknown(row?.FacilityHasBehaviouralHealthUnit)}</span></li>
        <li><strong>Speciality Niche Services:</strong> <span>{valueOrPlaceholder(row?.OtherFacilityTypes, 'None Listed')}</span></li>
        <li><strong>Minimum Age:</strong> <span>{getMinAgeRequirement(row?.MinAgeRequirement, row?.MinAge)}</span></li>
        <li><strong>Pediatric Residents:</strong> <span>{yesNoOrUnknown(row?.AdmitPediatricResidents)}</span></li>
    </ul>;

    const formatName = (row: IPropertyCoreDetailRow) => {

        const { WebsiteURL, StateSlug, CitySlug, Slug } = row;

        return (
            <div className="flex flex-wrap gap-2 break-words py-1">
                <a 
                    href="#" 
                    onClick={(e) => handleLinkClick(row.NHID, e)}
                >
                    <strong className="block leading-tight lg:text-lg">
                        {row.Name}
                    </strong>
                </a>
                <span className="inline-block text-xs lg:text-sm me-2 leading-tight text-brand_grey-medium">{ row.Address }</span>
                <a
                    className="link text-xs bg-brand_faint-blue px-2 py-1 inline-block leading-none rounded-lg relative -top-px"
                    href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                    target="_blank"
                >
                    view on website
                </a>
            </div>  
        );
    };

    const careOfferedColumns = [
        {
            id: 'NHID',
            name: 'Facility Name',
            sortField: 'Name',
            sortable: true,
            cell: (row: IPropertyCoreDetailRow) => formatName(row),
            width: '*'
        },
        {
            name: <>Profile Complete <br/>for Care Offered</>,
            sortable: true,
            sortField: 'ProfileComplete',
            cell: (row: IPropertyCoreDetailRow) => formatCareOfferedProfileComplete(row),
            width: '*'
        },
        {
            name: 'Care Offered',
            cell: renderCareOffered,
            width: '*'
        },
        {
            name: 'Room Types Offered',
            cell: (row: IPropertyCoreDetailRow) => formatRoomTypesOffered(row),
            width: '*'
        },
        {
            name: 'Last Updated',
            sortable: true,
            sortField: 'LastUpdated',
            cell: (row: IPropertyCoreDetailRow) => <>{row.CoreDetailsLastUpdated ? shortFormatDate(row.CoreDetailsLastUpdated): 'No updates made'}</>,
            id: 'LastUpdated',
            width: '*'
        }, 
        {
            name: '',
            sortable: true,
            cell: (row: IPropertyCoreDetailRow) => 
            <CareOfferedCostFundingModalButton 
                row={row} 
                hasCoreDetails={row.HasCoreDetails} 
                profileScoreType={ProfileScoreType.FacilityBaseData}
            />,
            id: 'Actions',
            width: '*'
        },
    ];

    const costFundingColumns = [
        {
            id: 'NHID',
            name: 'Facility Name',
            sortField: 'Name',
            sortable: true,
            cell: (row: IPropertyCoreDetailRow) => formatName(row),
            width: '*'
        },
        {
            name: <>Profile Complete for Cost / Funding</>,
            sortable: true,
            sortField: 'ProfileComplete',
            cell: (row: IPropertyCoreDetailRow) => formatCostFundingProfileComplete(row),
            width: '*'
        },
        {
            name: 'Cost (starting at per day)',
            cell: (row: IPropertyCoreDetailRow) => formatCostPerDay(row),
            width: '*'
        },
        {
            name: 'Bed Allocation and Payment Types',
            cell: (row: IPropertyCoreDetailRow) => formatPaymentType(row),
            width: '*'
        },
        {
            name: 'Insurance Providers Accepted',
            cell: (row: IPropertyCoreDetailRow) => formatInsuranceTypes(row),
            width: '*'
        },
        {
            name: 'Last Updated',
            sortable: true,
            sortField: 'LastUpdated',
            id: 'LastUpdated',
            cell: (row: IPropertyCoreDetailRow) => <div className='fs-6 mt-4'>{row.CostFundingLastUpdated ? shortFormatDate(row.CostFundingLastUpdated): 'No updates made'}</div>,
            width: '*'
        },
        {
            name: '',
            sortable: true,
            cell: (row: IPropertyCoreDetailRow) => <CareOfferedCostFundingModalButton 
                    row={row} 
                    hasCoreDetails={row.HasCoreDetails} 
                    profileScoreType={ProfileScoreType.CostFunding}
                />,
            id: 'Actions',
            width: '*'
        }
    ];

    const columns = profileScoreType === ProfileScoreType.FacilityBaseData ? 
        careOfferedColumns : costFundingColumns;

    const handleLinkClick = (
        nhID: number, 
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        e.preventDefault();
        handleSelect(nhID);
        window.scrollTo(0, 0);
    };

    const handleTableSortChange = (
        column: TableColumn<IPropertyCoreDetailRow>,
        sortOrder: SortOrder
    ) => {
        setSortField(column.sortField);
        setSortOrder(
            sortOrder === 'asc'
                ? DataTableSortOrder.Ascending
                : DataTableSortOrder.Descending
        );
    };

    const selectedFacility = (() => {
        const currentSelectedRow = rawData.find((row: IPropertyCoreDetailRow) => selNHID === row.NHID);
        return singlePropertySession ? rawData[0] : currentSelectedRow;
    })();


    const lastUpdated = profileScoreType === ProfileScoreType.FacilityBaseData ? 
        selectedFacility?.CoreDetailsLastUpdated : selectedFacility?.CostFundingLastUpdated;

    return (
        <>
             <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                <PageTitle title={pageTitle}/>
                { 
                    selectedFacility && <div>
                        {   lastUpdated 
                                ? `Last Updated: ${shortFormatDate(lastUpdated)}`
                                : 'No updates made'
                            }
                    </div>
                }
            </div>
            
            { 
                profileScoreType === ProfileScoreType.FacilityBaseData ? (
                    <>
                        <PageCommunitySelectWrapper
                            label={'Care Offered'}
                            selNHID={selNHID}
                            handleSelect={handleSelect}
                        />
                        <ProfileCompletenessHeader
                            selNHID={selNHID}
                            nhIDs={nhIDs}
                            orgNHID={orgNHID}
                            profileScoreType={ProfileScoreType.FacilityBaseData}
                            handleProfileCompletenessFilter={setProfileCompletenessFilterValue}
                            profileCompletenessFilterValue={profileCompletenessFilterValue}
                            title="for Care Offered"
                        /> 
                    </>
                ) : (
                    <>
                        <PageCommunitySelectWrapper
                            label={'Cost / Funding'}
                            selNHID={selNHID}
                            handleSelect={handleSelect}
                        />
                        <ProfileCompletenessHeader
                            selNHID={selNHID}
                            nhIDs={nhIDs}
                            orgNHID={orgNHID}
                            profileScoreType={ProfileScoreType.CostFunding}
                            handleProfileCompletenessFilter={setProfileCompletenessFilterValue}
                            profileCompletenessFilterValue={profileCompletenessFilterValue}
                            title="for Cost / Funding"
                        /> 
                    </>
                )
            }

            <section>
                <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10"> 
                    { !singlePropertySession && !selNHID ?  
                        <div data-table={`${profileScoreType === ProfileScoreType.FacilityBaseData ? 'Care Offered' : 'Cost / Funding'}`} className="relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none">
                            <DataTable
                                columns={columns}
                                onSort={handleTableSortChange}
                                data={filteredData}

                            />
                        </div> : 
                        profileScoreType === ProfileScoreType.FacilityBaseData ? 
                        <PropertyCoreDetailsForm
                            nhID={singleNHID} 
                        /> :
                        <PropertyCostFundingForm
                            nhID={singleNHID} 
                        /> 
                    }
                </div>
            </section>
        </>
    );
};


export default PropertyCoreDetails;
export type { IProps as IPropertyCoreDetails };