import _ from 'lodash';
import React, { FormEvent, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';
import { 
    createUser, 
    getUser, 
    getUserByEmailID, 
    sendActivationEmail, 
    sendPasswordResetEmail, 
    deletePortalUser,
    updateUser, 
    updatePortalUserEntity,
    getReviewCardPrimaryUserCount,
    getEntityContacts
} from '../../api/user';
import { LoadingDots } from '../../components/common/loading_dots';
import { ISelectOption } from '../../interfaces/form';
import {
    ISessionState,
    SessionType as SessionTypeKeyName,
    SessionTypeID as SessionType
} from '../../interfaces/session';
import { IPortalUser, IPortalUserByEmail, IPortalUserPayload, IGetReviewCardPrimaryUsers } from '../../interfaces/user';
import { showToast, Toast, ToastType } from '../../utils/toast';
import { validateEmailAddress } from '../../utils/validation';
import { updateSessionData } from '../../utils/session'; 
import EmailExistsForm from './user_email_exists_form';
import UserTableExpandableRows from '../../components/users/user_table_expandable_rows';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '../../components/common/button';
import { updateProfileCompleteness } from '../../utils/profile_completeness';

interface IFormData {
    FirstName: string;
    LastName: string;
    JobTitle: string;
    EmailAddress: string;
    CanReplyToReview: boolean;
    CanManageUsers: boolean;
    CanReceiveNotifications: boolean;
    SessionTypeID: SessionType;
    Properties: ISelectOption[];
    UserID?: number;
    PortalUserID?: number;
    HasActivated: boolean;
    IsReviewCardPrimaryContact: boolean;
    IsPrimaryContact: boolean;
    IsPrimarySubscriptionContact: boolean;
}

interface ICreateUserProps {
    nhIDs: number[];
    orgNHID: number;
}

interface ICanExistingUserBeLinked {
    IsAllowedToLink: boolean;
    Message: string;
}

const defaultFormData: IFormData = {
    FirstName: '',
    LastName: '',
    JobTitle: '',
    EmailAddress: '',
    CanReplyToReview: false,
    CanManageUsers: false,
    CanReceiveNotifications: false,
    SessionTypeID: null,
    Properties: null,
    HasActivated: false,
    IsReviewCardPrimaryContact: false,
    IsPrimaryContact: false,
    IsPrimarySubscriptionContact: false
};

const EditUser = ({ nhIDs, orgNHID }: ICreateUserProps) => { 
    const sessionState: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );
    const [warningMessages, setWarningMessages] = useState<ReactNode[]>([]);
 
    const location = useLocation();
    const navigate = useNavigate();

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ confirmDelete, setConfirmDelete ] = useState<boolean>(false);
    const [ formData, setFormData ] = useState<IFormData>(defaultFormData);
    const [ isUserEmailExists, setIsUserEmailExists ] = useState<boolean>(true);
    const [ isUserEmailDeleted, setIsUserEmailDeleted ] = useState<boolean>(true);
    const [ isUserEmailHasActivated, setIsUserEmailHasActivated ] = useState<boolean>(true);
    const [ doShowUserEmailExistsHtML, setDoShowUserEmailExistsHtML ] = useState<boolean>(false);
    const [ isExistingUser, setIsExistingUser ] = useState<boolean>(false);
    const [ existingUser, setExistingUser ] = useState<IPortalUser>(null);
    const [deselectPrimaryUserWarning, setDeselectPrimaryUserWarning] = useState<boolean>(false);
    const [primaryUserWarning, setPrimaryUserWarning] = useState<boolean>(false);
    const [ propertiesAlreadyHavePrimaryUser, setPropertiesAlreadyHavePrimaryUser ] = useState<any[]>(null);
    

    useEffect(() => {
        const state = location?.state as any;
        const userID = state?.UserID;

        if (userID) {
            getUserData(userID);
        }
        
        updateFormData(
            'SessionTypeID', 
            isOrgSession ? SessionType.Org : SessionType.Property
        );
    }, [ nhIDs, orgNHID ]);
    
    const canManageUsers = _.get(sessionState, 'Session.Perms.CanManageUsers', false);
    const isOrgSession = sessionState?.Session?.SessionType === SessionTypeKeyName.SessionTypeOrg;
        
    const getUserData = async (userID: number) => {
        setIsLoading(true);

        try {
            const user: IPortalUser = await getUser(userID);
            mapUserToFormData(user);
        } catch (err) {
            showToast('Error retrieving the user', ToastType.Error);
        } finally {
            setIsLoading(false);
        }
    };

    const canExistingUserBeLinked = (() : ICanExistingUserBeLinked => {
        const sessionProperties: any[] = _.get(sessionState, 'Properties');
        const entityName = isOrgSession ? sessionState.Properties[0].OrgName : sessionState.Properties.length > 1 ? sessionState.Properties[0].OrgName : sessionState.Properties[0].Name;
        const returnValue : ICanExistingUserBeLinked = {
            IsAllowedToLink: false,
            Message: `Please either contact your account manager or email reviews@aplaceformom.com to ask if ${formData.EmailAddress} can be relinked to ${entityName}`
        };
        if(existingUser) {
            //Org User
            if(existingUser.SessionTypeID === SessionType.Org ) {
                return {
                    IsAllowedToLink: false,
                    Message: `${existingUser.EmailAddress} ( ${existingUser.FirstName} ${existingUser.LastName} ) already has access.`
                };
            } else {
                const sessionNHIDs: number[] = sessionProperties.map((m: any) => m.NHID);
                const existingUserNHIDs: number[] = [];
                if(sessionNHIDs.length === existingUser.Entities.length) {
                    existingUser.Entities.forEach((m: any) => { 
                        if( m.EntityType === 'property' && m.EntityID > 0  && !sessionNHIDs.includes(m.EntityID)) {
                            existingUserNHIDs.push(m.EntityID);
                        }
                    });
                } else {
                    existingUser.Entities.forEach((m: any) => { 
                        if( m.EntityType === 'property' && m.EntityID > 0) {
                            existingUserNHIDs.push(m.EntityID);
                        }
                    });
                }
                //Property User
                if(existingUserNHIDs.length === 0) {
                    return {
                        IsAllowedToLink: false,
                        Message: `${existingUser.EmailAddress} ( ${existingUser.FirstName} ${existingUser.LastName} ) already has access.`
                    };
                } else {
                    return {
                        IsAllowedToLink: true,
                        Message: `Please confirm if you would like to link ${existingUser.EmailAddress} to ${entityName} instead of the above.`
                    };
                }
            }
        }

        return returnValue;
    })();

    const handleLinkingExistingUser = async () => {
       if(existingUser) {
            setIsLoading(true);
            try {
                await updatePortalUserEntity(existingUser.PortalUserID);
                return navigate('/users');
            } catch (e) {
                showToast(`Error linking user (Email: ${existingUser.EmailAddress})`, ToastType.Error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleEmailExists = async (emailToCheckValue: string) => {
       updateFormData('EmailAddress', emailToCheckValue);
        
        if (emailToCheckValue.trim().length === 0) {
            return;
        }

        setIsLoading(true);

        try {
            const userData: IPortalUserByEmail = await getUserByEmailID(`${encodeURIComponent(emailToCheckValue)}`);

            if (!userData) {
                throw new Error();
            }

            if(userData.IsUserEmailExists) {
                setDoShowUserEmailExistsHtML(true);
                setIsUserEmailExists(true);
                setIsUserEmailDeleted(userData.IsUserEmailDeleted);
                setExistingUser(userData.User);
                setIsUserEmailHasActivated(userData.IsUserEmailHasActivated);
               
            } else {
                setDoShowUserEmailExistsHtML(false);
                setIsUserEmailExists(false);
                setIsUserEmailDeleted(false);
                setExistingUser(null);
                setIsUserEmailHasActivated(false);
            }
        } catch (e) {
            showToast(`Error retrieving user by email (Email: ${emailToCheckValue})`, ToastType.Error);
        } finally {
            setIsLoading(false);
        }
    };

    const resetFormData = () => {
        setFormData(defaultFormData); 
        setIsExistingUser(false);
        setIsUserEmailExists(true);
        setIsUserEmailDeleted(false);
        setDoShowUserEmailExistsHtML(false);
        setExistingUser(null);
        setIsUserEmailHasActivated(false);
        return navigate('/users/add');  
    };

    const renderExistingUserEmailHTML = () => {
        
        const {IsAllowedToLink, Message} =  canExistingUserBeLinked;

        if (isUserEmailExists && isUserEmailDeleted) {
            return (
                <div className="row mt-4 col-sm-12">
                    <div className='col-sm-12'>
                        There is already a user account for { formData.EmailAddress } but it has been deleted
                    </div>
                    <div className="row mt-4">
                        <div className="col-6">
                            <Link to="/users">
                                <button className="btn btn-secondary">Return to User List</button>
                            </Link>
                        </div>
                    </div>
                </div>
            );
        }

        if (isUserEmailExists && !isUserEmailHasActivated) {
            return (
                <div className="row mt-4 col-sm-12">
                    <div className='col-sm-12'>
                        There is already a user account for { formData.EmailAddress } but the user has not completed sign up
                    </div>
                    <div className="row mt-4">
                        <div className="col-6">
                            <Link to="/users">
                                <button className="btn btn-secondary">Return to User List</button>
                            </Link>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="row mt-4 col-sm-12">
                 <div className='col-sm-12'>
                    There is already a user account for { formData.EmailAddress }
                    {
                        IsAllowedToLink ?  
                            <>       
                                 <div className="mt-2">
                                    They are linked to the following:
                                    { <UserTableExpandableRows  data={existingUser} />}
                                </div> 
                                <div className="col-12">
                                    {Message}
                                </div>
                                <div className="row mt-4">
                                    { !isOrgSession ?
                                         <div className="col-6">
                                            <button className="btn btn-primary" onClick={handleLinkingExistingUser}>Confirm</button>
                                        </div>
                                    :
                                        <div className="col-6">
                                            <Link to="/users/edit" state={{ UserID:existingUser.UserID }}>
                                                <button className="btn btn-primary">Edit this User</button>
                                            </Link>
                                        </div>
                                    }
                                    <div className="col-6">
                                        <button className="btn btn-secondary" onClick={resetFormData}>No, please do not make any changes</button>
                                    </div>
                                </div>
                            </>
                        : 
                            <>
                                <div className="col-12 mt-4">
                                    {Message}
                                </div>
                                <div className="row mt-4">
                                    <div className="col-6">
                                        <Link to="/users">
                                            <button className="btn btn-secondary">Return to User List</button>
                                        </Link>
                                    </div>
                                </div>
                            </>
                            
                    }
                </div>
            </div>
        );
    };

    const mapUserToFormData = (user: IPortalUser) => {
        const {
            UserID,
            PortalUserID,
            FirstName,
            LastName,
            JobTitle,
            EmailAddress,
            CanReplyToReview,
            CanManageUsers,
            CanReceiveNotifications,
            SessionTypeID,
            KeyName,
            HasActivated,
            IsReviewCardPrimaryContact,
            IsPrimarySubscriptionContact = false,
            IsPrimaryContact = false
        } = user;

        let properties: ISelectOption[] = [];
        
        if (isOrgSession && KeyName === SessionTypeKeyName.SessionTypeProperty) {
            properties = buildPropertyOptions(user.Entities);
        }

        setFormData({
            ...formData,
            UserID,
            PortalUserID,
            FirstName,
            LastName,
            JobTitle,
            EmailAddress,
            CanReplyToReview,
            CanManageUsers,
            CanReceiveNotifications,
            SessionTypeID,
            Properties: properties,
            HasActivated,
            IsReviewCardPrimaryContact,
            IsPrimaryContact,
            IsPrimarySubscriptionContact 
        });     
        if(UserID) {
            setIsExistingUser(true);
        }
    };

    const buildPropertyOptions = (properties: any[]): ISelectOption[] => {
        return properties.map((m: any) => { 
            const name = m.Name ?? m.EntityName;
            const NHID = m.NHID ?? m.EntityID;
            const city = m.CityName ?? m.EntityCity;
            const cityStr = city?.trim().length ? `, ${city}`: '';

            return {
                label: `${name}${cityStr} (ID: ${NHID})`,
                value: NHID
            };    
        });
    };

    const propertyOptions = ((): ISelectOption[] => {
        if (isOrgSession) {
            return buildPropertyOptions(sessionState.Properties);
        }

        return [];
    })();

    const updateFormData = (key: any, value: any) => {
        setFormData({
            ...formData,
            [key]: value
        });
    };

    const getEntityIDs = (): number[] => {
        if (isOrgSession) {
            if (formData.SessionTypeID === SessionType.Property) {
                return formData.Properties.map((m: ISelectOption) => m.value);
            }   

            return [orgNHID];
        }

        return nhIDs;
    };

    const saveUser = async (e: FormEvent) => {
        e.preventDefault();

        if (isLoading || !validateFormData()) {
            return;
        }

        setIsLoading(true);

        const {
            FirstName,
            LastName,
            JobTitle,
            EmailAddress,
            SessionTypeID,
            CanReplyToReview,
            CanManageUsers,
            CanReceiveNotifications,
            PortalUserID,
            IsReviewCardPrimaryContact,
            IsPrimaryContact,
            IsPrimarySubscriptionContact
        } = formData;

        const payload: IPortalUserPayload = {
            FirstName,
            LastName,
            JobTitle,
            EmailAddress,
            SessionTypeID,
            CanReplyToReview,
            CanManageUsers,
            CanReceiveNotifications,
            EntityIDs: getEntityIDs(),
            PortalUserID: PortalUserID,
            IsReviewCardPrimaryContact,
            IsPrimaryContact,
            IsPrimarySubscriptionContact
            
        };

        try {
            if(
                !isExistingUser &&
                payload.IsReviewCardPrimaryContact && 
                !payload.EntityIDs.length){
                return setDeselectPrimaryUserWarning(true);
            }

             if (payload.EntityIDs.length && !warningMessages.length) {
                let warnings: ReactNode[] = [];
                const contactsOfEntities = await getEntityContacts(payload.EntityIDs);
                if (IsPrimaryContact) {
                    const portalUserIsPrimaryContact = contactsOfEntities.some((entity) => entity.PortalUserID === PortalUserID && entity.PrimaryContact);
                    const otherPrimaryContacts = contactsOfEntities.filter((entity) => entity.PortalUserID !== PortalUserID && entity.PrimaryContact);
                    if (!portalUserIsPrimaryContact && otherPrimaryContacts.length) {

                        const primaryContactWarnings = otherPrimaryContacts.map((contact:any, index: number) => {
                            return <div key={`pc-${index}`}>
                            <span className="font-weight-bold red">
                                {contact.FirstName} {contact.LastName} 
                                </span>   
                                &nbsp; ({contact.EmailAddress}) is already set as the Primary Contact for {contact.EntityName}.
                                <br />
                            </div>;
                        });
                        warnings = [...warnings, ...primaryContactWarnings];

    
                    }
                }

                if (IsPrimarySubscriptionContact) {
                    const portalUserIsPrimarySubscriptionContact = contactsOfEntities.some((entity) => entity.PortalUserID === PortalUserID && entity.PrimarySubscriptionContact);
                    const otherPrimarySubscriptionContacts = contactsOfEntities.filter((entity) => entity.PortalUserID !== PortalUserID && entity.PrimarySubscriptionContact);
                    if (!portalUserIsPrimarySubscriptionContact && otherPrimarySubscriptionContacts.length) {
                        const primarySubscriptionContactWarnings = otherPrimarySubscriptionContacts.map((contact, index: number) => {
                            return <div key={`psc-${index}`}>
                            <span className="font-weight-bold red">
                                {contact.FirstName} {contact.LastName} 
                                </span>   
                                &nbsp; ({contact.EmailAddress}) is already set as the Primary Subscription Contact for {contact.EntityName}.
                                <br />
                            </div>;
                        });

                        warnings = [...warnings, ...primarySubscriptionContactWarnings];
                    }
                }
                // early return on the first submission attempt with warnings
                if (warnings.length) {
                    setWarningMessages(warnings);
                    return ;
                }
            }

            // if ReviewCardPrimaryContact is selected and there are some entities associated but no warning has been shown yet
            if(payload.IsReviewCardPrimaryContact && payload.EntityIDs.length && !primaryUserWarning){
                const getPrimaryUserCountPayload: IGetReviewCardPrimaryUsers ={
                    EntityIDs: payload.EntityIDs.toString(),
                    PortalIDNotPresent: payload.PortalUserID?? undefined
                }; 
                const primaryContactAlreadySet = await getReviewCardPrimaryUserCount(getPrimaryUserCountPayload);
                if (primaryContactAlreadySet.confirm) { 
                
                    setPropertiesAlreadyHavePrimaryUser(primaryContactAlreadySet.entitiesToHaveContactReplaced),
                    setPrimaryUserWarning(true);
                    return;
                }
            }
            const response = isExistingUser
                ? await updateUser(payload)
                : await createUser(payload);

            if (response.errors?.length) { 
                throw new Error(response.errors[0]);
            }

            const responseUser: IPortalUser = response.data;

            if (!responseUser) {
                throw new Error();
            }

            updateProfileCompleteness();

            if (!isExistingUser) {
                await sendActivationEmail(responseUser.UserID);

                const addAnotherUser = document.getElementById('addAnotherUser') as HTMLInputElement;
                
                if (addAnotherUser?.checked) {
                    showToast('User added successfully!', ToastType.Success);

                    addAnotherUser.checked = false;
                    
                    setFormData({
                        ...defaultFormData,
                        SessionTypeID: isOrgSession ? SessionType.Org : SessionType.Property
                    });
                    
                    return;
                }
            } else {

                const editedUserID = responseUser.UserID;
                const sessionUserID = _.get(sessionState, 'Session.UserID');

                if (editedUserID === sessionUserID) {
                    updateSessionData();
                }
            }
            
            return navigate('/users');   
        } catch (error) {
            const err = error as any;
            const errMsg = err.customError ?? 'An error occurred saving the user.';

            showToast(errMsg, ToastType.Error);
        } finally {
            setIsLoading(false);
        }
    };

    const validateFormData = (): boolean => {
        const {
            EmailAddress,
            SessionTypeID,
            Properties,
        } = formData;

        const validationErrorText: string[] = [];

        if (!validateEmailAddress(EmailAddress)) {
            validationErrorText.push('A valid Email Address must be entered.');
        }

        if (!SessionTypeID) {
            validationErrorText.push('You must select what sort of record(s) you would like to link to.');
        }

        if (isOrgSession && SessionTypeID === SessionType.Property && !Properties?.length) {
            validationErrorText.push('Please select at least one Community.');
        }

        if (validationErrorText.length > 0) {
            showToast(validationErrorText.join(' '), ToastType.Error);

            return false;
        }

        return true;
    };

    const handleSendPasswordResetEmail = async (e: FormEvent) => {
        e.preventDefault();

        if (!isExistingUser) {
            return;
        }

        setIsLoading(true);

        try {
            await sendPasswordResetEmail(formData.EmailAddress);

            showToast(`A Password Reset email has been sent to ${formData.EmailAddress}`, ToastType.Success);
        } catch (err) {
            showToast(`Error sending Password Reset email to ${formData.EmailAddress}`, ToastType.Error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteUser = async (e: FormEvent, confirmed: boolean, cancelled = false) => {
        e.preventDefault();

        const { PortalUserID } = formData;

        if (!isExistingUser || !PortalUserID) {
            return;
        }

        if (!confirmed) {
            setConfirmDelete(true);
            return;
        } else if (cancelled) {
            setConfirmDelete(false);
            return;
        }

        setIsLoading(true);

        try {
            await deletePortalUser(PortalUserID);

            showToast('User Deleted', ToastType.Success);
        } catch (err) {
            showToast('Error Deleting User', ToastType.Error);
        } finally {
            setIsLoading(false);
        }

       return navigate('/users'); 
    };

    const handleSendActivationEmail = async (e: FormEvent) => {
        e.preventDefault();

        if (!isExistingUser || formData.HasActivated) {
            return;
        }

        setIsLoading(true);

        try {
            await sendActivationEmail(formData.UserID);
            
            showToast(`An Activation email has been sent to ${formData.EmailAddress}`, ToastType.Success);
        } catch (err) {
            showToast(`Error sending Activation email to ${formData.EmailAddress}`, ToastType.Error);
        }
    };

    const handleSubmitButtonText =()=>{
        let buttonText = 'Submit';
        switch(isExistingUser) {
            case true:
                buttonText = 'Update';
            break;
          }
          switch(primaryUserWarning) {
            case true:
                buttonText = `Proceed with ${buttonText}`;
            break;
          }
        return buttonText;
    };

    const handleDeselectPrimaryUserwarning = () =>{
 
        const renderedOutput = <strong>Please deselect 'Review Card Order Primary Contact' if no property is associated with this User.</strong>;
  
         return <div className="mt-4 md:mt-6 lg:mt-8">
            <div className="msg msg-warning">
                {renderedOutput} 
            </div>
         </div>;
      };
    
    const handlePrimaryUserwarning = () =>{

       const renderedOutput = propertiesAlreadyHavePrimaryUser.map(portalUserObject =>
            <p>
                <strong>{portalUserObject.UserFirstName} {portalUserObject.UserLastName}</strong> ({portalUserObject.EmailAddress}) is already set as the Review Card Order Primary Contact for {portalUserObject.PropertyName}.
            </p>
        );

        return <div className="mt-4 md:mt-6 lg:mt-8">
            <div className="msg msg-warning">
                <div>
                    { renderedOutput }
                    <p>
                        If you want to replace them as the 'Review Card Order Primary Contact' please proceed, if not please uncheck 'Review Card Order Primary Contact' above.
                    </p>                    
                </div>
            </div>
        </div>;
     };

    return (
        <section>
            <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                { isLoading ? <LoadingDots /> : null }

                {
                    (isUserEmailExists && !isExistingUser) ? 
                        <>
                            <Toast></Toast>
                            <EmailExistsForm handleEmailExistsCheck={handleEmailExists}  />
                            { doShowUserEmailExistsHtML ? renderExistingUserEmailHTML() : null }
                        </>
                    : null
                } 
                {
                    isExistingUser || (!isUserEmailExists && !isExistingUser) ?
                        <>
                            <Toast></Toast>
                            <form onSubmit={saveUser}>
                                <fieldset>
                                    <legend className="font-semibold text-xl mb-3 block">
                                        Details
                                    </legend>
                                    <div className="grid grid-cols-1 gap-3 w-full max-w-1/2">
                                        {/* <FormInput
                                            name="email"
                                            label="Email"
                                            type="email"
                                            required={true}
                                            disabled={true}
                                            value={formData.EmailAddress || ''}
                                        /> */}
                                        <div className="form-component form-inline form-textbox">
                                            <label
                                                htmlFor="email"
                                            >
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                max="120"
                                                value={formData.EmailAddress || ''}
                                                onChange={(e) => updateFormData('EmailAddress', e.target.value)}
                                                disabled={true}
                                                required
                                            />
                                        </div>
                                        <div className="form-component form-inline form-textbox">
                                            <label 
                                                htmlFor="firstname"
                                            >
                                                First Name
                                            </label>
                                            <input
                                                type="text"
                                                id="firstname"
                                                value={formData.FirstName || ''}
                                                max="120"
                                                onChange={(e) => updateFormData('FirstName', e.target.value)}
                                                disabled={!canManageUsers}
                                                required
                                            />
                                        </div>
                                        <div className="form-component form-inline form-textbox">
                                            <label
                                                htmlFor="lastname"
                                            >
                                                Last Name
                                            </label>
                                            <input
                                                type="text"
                                                id="lastname"
                                                value={formData.LastName || ''}
                                                max="120"
                                                onChange={(e) => updateFormData('LastName', e.target.value)}
                                                disabled={!canManageUsers}
                                                required
                                            />
                                        </div>
                                        <div className="form-component form-inline form-textbox">
                                            <label
                                                htmlFor="jobtitle"
                                            >
                                                Job Title
                                            </label>
                                            <input
                                                type="text"
                                                id="jobtitle"
                                                value={formData.JobTitle || ''}
                                                max="255"
                                                onChange={(e) => updateFormData('JobTitle', e.target.value)}
                                                disabled={!canManageUsers}
                                                required
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className="mt-4 md:mt-6 lg:mt-8">
                                    <legend className="font-semibold text-xl mb-3 block">
                                        Permissions
                                    </legend>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:w-3/4 xl:w-1/2 gap-3">
                                        <div className="form-component form-checkbox">
                                            <label>
                                                <input
                                                    className="peer"
                                                    type="checkbox"
                                                    checked={formData.CanManageUsers}
                                                    id="CanManageUsers"
                                                    onChange={(e) => updateFormData('CanManageUsers', e.target.checked)}
                                                    disabled={!canManageUsers}
                                                />
                                                <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                                                Can Manage Users
                                            </label>         
                                        </div>
                                        <div className="form-component form-checkbox">
                                            <label>
                                                <input
                                                    className="peer"
                                                    type="checkbox"
                                                    checked={formData.CanReplyToReview}
                                                    id="CanReplyToReview"
                                                    onChange={(e) => updateFormData('CanReplyToReview', e.target.checked)}
                                                    disabled={!canManageUsers}
                                                />
                                                <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                                                Can Reply To Reviews
                                            </label>         
                                        </div>
                                        <div className="form-component form-checkbox">
                                            <label>
                                                <input
                                                    className="peer"
                                                    type="checkbox"
                                                    checked={formData.CanReceiveNotifications}
                                                    id="CanReceiveNotifications"
                                                    onChange={(e) => updateFormData('CanReceiveNotifications', e.target.checked)}
                                                    disabled={!canManageUsers}
                                                />
                                                <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                                                Review Notifications
                                            </label>         
                                        </div>
                                        <div className="form-component form-checkbox">
                                            <label>
                                                <input
                                                    className="peer"
                                                    type="checkbox"
                                                    checked={formData.IsReviewCardPrimaryContact}
                                                    id="IsReviewCardPrimaryContact"
                                                    onChange={(e) => updateFormData('IsReviewCardPrimaryContact', e.target.checked)}
                                                    disabled={!canManageUsers}
                                                />
                                                <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                                                Review Card Order Primary Contact
                                            </label>         
                                        </div>
                                        <div className="form-component form-checkbox">
                                            <label>
                                                <input
                                                    className="peer"
                                                    type="checkbox"
                                                    checked={formData.IsPrimaryContact}
                                                    id="IsPrimaryContact"
                                                    onChange={(e) => updateFormData('IsPrimaryContact', e.target.checked)}
                                                    disabled={!canManageUsers}
                                                />
                                                <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                                                Primary Contact
                                            </label>         
                                        </div>
                                        <div className="form-component form-checkbox">
                                            <label>
                                                <input
                                                    className="peer"
                                                    type="checkbox"
                                                    checked={formData.IsPrimarySubscriptionContact}
                                                    id="IsPrimarySubscriptionContact"
                                                    onChange={(e) => updateFormData('IsPrimarySubscriptionContact', e.target.checked)}
                                                    disabled={!canManageUsers}
                                                />
                                                <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                                                Primary Subscription Contact
                                            </label>         
                                        </div>
                                    </div>
                                </fieldset>
                                
                                { isOrgSession ?
                                    <fieldset className="mt-4 md:mt-6 lg:mt-8">
                                        <legend className="font-semibold text-xl mb-3 block">
                                            Link this user to
                                        </legend>
                                        <div className="grid grid-cols-1 gap-3">
                                            <div className="form-component form-radio">
                                                <label>
                                                    <input
                                                        className="peer"
                                                        type="radio"
                                                        name="EntityLink"
                                                        checked={formData.SessionTypeID === SessionType.Org}
                                                        onChange={() => updateFormData('SessionTypeID', SessionType.Org)}
                                                        disabled={!canManageUsers}
                                                    /> 
                                                    <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                                                    <span>
                                                        <strong>{ sessionState.Org?.Name }</strong> (can access all its Communities)
                                                    </span>
                                                </label> 
                                            </div>
                                            <div className="form-component form-radio">
                                                <label>
                                                    <input
                                                        className="peer"
                                                        type="radio"
                                                        name="EntityLink"
                                                        checked={formData.SessionTypeID === SessionType.Property}
                                                        onChange={() => updateFormData('SessionTypeID', SessionType.Property)}
                                                        disabled={!canManageUsers}
                                                    /> 
                                                    <FontAwesomeIcon icon={faCircle} className="peer-checked:block"/>
                                                    <span>
                                                        A selection of <strong>{ sessionState.Org?.Name }</strong> Communities
                                                    </span>
                                                </label> 
                                            </div>       
                                            { formData.SessionTypeID === SessionType.Property ?
                                                <div className="form-component form-inline form-select mt-1">
                                                    <label
                                                        htmlFor="Properties"
                                                    >
                                                        Select Communities
                                                    </label>
                                                    <Select
                                                        id="Properties"
                                                        onChange={(e) => updateFormData('Properties', e)}
                                                        options={propertyOptions}
                                                        isMulti
                                                        closeMenuOnSelect={true}
                                                        name="Properties"
                                                        classNamePrefix="select"
                                                        value={formData.Properties}
                                                        isDisabled={!canManageUsers}
                                                        unstyled
                                                    />
                                                </div> : null
                                            }
                                        </div>
                                    </fieldset> : null
                                }
        
                                { primaryUserWarning ? 
                                    handlePrimaryUserwarning() : null
                                }
                                { deselectPrimaryUserWarning ?
                                    handleDeselectPrimaryUserwarning() : null
                                }
                                { warningMessages.length ? 
                                    <>
                                        { warningMessages }
                                        If you want to add this user as a contact too, please proceed. Otherwise deselect the relevant options. 
                                    </> : null
                                }

                                <fieldset className="mt-4 md:mt-6 lg:mt-8">
                                    <div className="flex items-center gap-3">
                                        {
                                            canManageUsers ?
                                                <>
                                                    {
                                                        !confirmDelete ?
                                                            <Button 
                                                                type="submit"
                                                            >
                                                                { handleSubmitButtonText() }
                                                            </Button> : null  
                                                    }
                                                    {
                                                        !isExistingUser && !confirmDelete ?
                                                            <div className="form-component form-checkbox ms-6">
                                                                <label>
                                                                    <input
                                                                        className="peer"
                                                                        type="checkbox"
                                                                        id="addAnotherUser"
                                                                    />
                                                                    <FontAwesomeIcon icon={faCheck} className="peer-checked:block"/>
                                                                    Add another user after clicking submit
                                                                </label>         
                                                            </div> : null
                                                    }
                                                    {
                                                        isExistingUser && !confirmDelete && formData.HasActivated?
                                                            <Button
                                                                styleType="outline"
                                                                onClick={handleSendPasswordResetEmail}
                                                            >
                                                                Send Password Reset Email
                                                            </Button> : null
                                                    }
                                                    {
                                                        isExistingUser ? confirmDelete ?
                                                            <>
                                                                <Button
                                                                    variant="warning"
                                                                    onClick={ 
                                                                        (e: any) => { 
                                                                            handleDeleteUser(e, true);
                                                                    }}
                                                                >
                                                                    Confirm Delete User
                                                                </Button> 
                                                                <Button
                                                                    styleType="outline"
                                                                    onClick={ 
                                                                        (e: any) => { 
                                                                            handleDeleteUser(e, true, true);
                                                                    }}
                                                                >
                                                                    Cancel Delete User
                                                                </Button> 
                                                            </> : 
                                                            <Button
                                                                variant="warning"
                                                                onClick={ 
                                                                    (e: any) => { 
                                                                        handleDeleteUser(e, false);
                                                                }}
                                                            >
                                                                Delete User
                                                            </Button> : null
                                                    }
                                                    {
                                                        isExistingUser && validateEmailAddress(formData.EmailAddress) && !formData.HasActivated ?
                                                            <Button
                                                                styleType="outline"
                                                                onClick={handleSendActivationEmail}
                                                            >
                                                                Send Activation Email
                                                            </Button> : null
                                                    }
                                                </> : 
                                                <span className="msg msg-warning">
                                                    You do not currently have permission to manage users
                                                </span>
                                            }
                                    </div>
                                </fieldset>
                            </form>
                        </>
                    : null
                } 
            </div>   
        </section>
    );
};

export default EditUser;