import { IPortalPropertyAsset } from '../../../interfaces/asset';
import * as assetApi from '../../../api/assets';

export const getPropertiesBrochures = async (nhIDs: number[]): Promise<IPortalPropertyAsset[]> => {
    return await assetApi.getPropertiesBrochures(nhIDs);
};

export const getPropertyBrochures = async ( nhID: number[], orgNHID?: number): Promise<IPortalPropertyAsset[]> => {
    if(nhID[0] === orgNHID) {
        return await getOrgPropertiesBrochures(orgNHID);
    }
    return await assetApi.getPropertiesBrochures(nhID);
};  

export const getOrgPropertiesBrochures = async(orgNHID: number): Promise<IPortalPropertyAsset[]> => {
    if (!orgNHID) {
        console.error('getOrgPropertiesBrochures: Invalid orgNHID');
        return null;
    }

    return await assetApi.getOrgPropertiesBrochures(orgNHID);
};