import React from 'react';
import { ILeadTotals, LeadMetric } from '../../interfaces/leads';

type Props = {
    metric: LeadMetric;
    handleChange(tab: LeadMetric): void;
    totals: ILeadTotals;
    selNHID: number;
};

interface ITab {
    name: string;
    value: LeadMetric;
}

const tabs: ITab[] = [
    {
        name: 'All Channels',
        value: 'All'
    },
    {
        name: 'Page Views',
        value: 'PageViews'
    },
    {
        name: 'Website Referrals',
        value: 'WebsiteReferrals'
    },
    {
        name: 'Phone Number Views',
        value: 'PhoneNumberViews'
    },
    {
        name: 'Email Inquiries',
        value: 'EmailInquiries'
    },
    {
        name: 'Brochure Inquiries',
        value: 'BrochureInquiries'
    },
    {
        name: 'Tour Requests',
        value: 'TourRequests'
    }
];

export default function LeadInsightsTabs(props: Props) {
    return (
        <ul>
            {
                tabs.map((tab: ITab, index: number) => {
                    const active = tab.value === props.metric;
                    const key = tab.value as keyof ILeadTotals;

                    return (
                        <li
                            key={index}
                            className={`rounded-md p-2 cursor-pointer xl:p-3 ${active ? 'bg-brand_secondary text-white' : 'ring-1 ring-brand_grey-light hover:bg-brand_faint-blue'}`}
                            onClick={() => props.handleChange(tab.value)}
                        >
                            { tab.value === 'All' ? null : <><strong className="font-semibold leading-none mb-1.5 text-2xl">{ props.totals[key]?.toLocaleString() }</strong></> || <strong className="font-semibold leading-none mb-1.5 text-2xl">0</strong> }
                            <span className={`text-2xs font-medium block leading-none ${tab.value === 'All' ? 'text-sm font-semibold' : ''}`}>
                                {tab.name}
                            </span>
                        </li>
                    );
                })
            }
        </ul>
    );
}