import React, { useEffect, useState } from 'react';
import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import MultiOrgPropertyBrochures from '../../../components/assets/multi_org_property_brochures';
import IndividualPropertyBrochures from '../../../components/assets/individual_property_brochures';
import { useLocation } from 'react-router-dom';
import { ISessionState, SessionType } from '../../../interfaces/session';
import { IPortalPropertyAsset } from '../../../interfaces/asset';
import { useSelector } from 'react-redux';

import { useCommunitySelectHistory } from '../../../hooks/use_community_select_history';
import ProfileCompletenessHeader from '../../../components/profile_completeness/profile_completeness_header';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { DataTableSortOrder } from '../../../components/common/data_table';
import { boolSort, textSort } from '../../../utils/common';

import { 
    ProfileScoreType,
    IPortalScoreItem,
    IPropertyScoresItem,
    IPropertyScores
} from '../../../interfaces/property';

import { 
    getPropertiesBrochures, 
    getPropertyBrochures, 
    getOrgPropertiesBrochures 
} from './utils';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyBrochures = ({ nhIDs, orgNHID }: IProps) => {
    const location = useLocation();
    const state = location?.state as any;
    const defaultNHID = state?.NHID || null;
    const [busyLoadingAsset, setBusyLoadingAsset] = useState<boolean>(true);
    const [selNHID, setSelNHID] = useState<number>(defaultNHID);
    const [propertyBrochures, setPropertyBrochures] = useState<any[]>([]);
    const [ profileCompletenessFilterValue, setProfileCompletenessFilterValue ] = useState<boolean | null>(null);
    const [sortField, setSortField] = useState<string>('Name');
    const [sortOrder, setSortOrder] = useState<DataTableSortOrder>(DataTableSortOrder.Ascending);


    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });
    
    const { Session }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const profileCompletenessState: IPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    const { SessionType: sessionType } = Session;

    const getProfileComplete = (NHID: number) => {
    
        const propertyScore = (profileCompletenessState?.PropertyScores || []).find((item: IPropertyScores) => (
            item.NHID === NHID
        ));

        const coreScore = (propertyScore?.Scores || []).find((item: IPropertyScoresItem) => (
            item.KeyName === ProfileScoreType.PdfBrochure
        ));

        return coreScore ? coreScore.Score === coreScore.AvailableScore : false;
    };

    const loadPropertyBrochures = async () => {

        let sortFn: (key: string, order: 'ASC' | 'DESC') => any;

        switch(sortField) {
            case'Name':
                sortFn = textSort;
                break;
            case 'ProfileComplete':
                sortFn = boolSort;
                break;
            default:
              console.log(`No sort function declared for column ${sortField}`);
        }

        if (!selNHID) {
            setPropertyBrochures(null);
            setBusyLoadingAsset(true);
            try {
                const propertyBrochures = sessionType === SessionType.SessionTypeOrg
                    ? await getOrgPropertiesBrochures(orgNHID)
                    : await getPropertiesBrochures(nhIDs);

                const tableData = (propertyBrochures || []).map((item: IPortalPropertyAsset) => {

                    const profileComplete = getProfileComplete(item.NHID);

                    return {
                        ...item,
                        ProfileComplete: profileComplete,
                    };
                }).sort(sortFn(sortField, sortOrder));

                const filteredData = (tableData || []).filter((item: any) => {
            
                    const nhIDValid = !selNHID || item.NHID === selNHID;
            
                    const profileCompletenessFilterValid = profileCompletenessFilterValue === null || 
                        profileCompletenessFilterValue === item.ProfileComplete;
            
                    return nhIDValid && profileCompletenessFilterValid;
                });

                setPropertyBrochures(filteredData);

            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingAsset(false);
            }
        } else {
            setBusyLoadingAsset(false);
        }
    };

    useEffect(() => {
        const singleNHID = selNHID || (nhIDs?.length === 1 ? nhIDs[0] : null);
        if (singleNHID) {
            setSelNHID(singleNHID);
        }
    }, [nhIDs, orgNHID]);

    useEffect(() => {
        if (!selNHID) {
            loadPropertyBrochures();
        }

        setBusyLoadingAsset(true);
        (async () => {
            try {
                if (selNHID && selNHID !== orgNHID) {
                    const propertyBrochures = await getPropertyBrochures([selNHID]);
                    if (propertyBrochures) {
                        setPropertyBrochures(propertyBrochures);
                    }
                } 
            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingAsset(false);
            }
        })();
    }, [selNHID, profileCompletenessFilterValue, sortField, sortOrder]);

    const handleTableSortChange = (
        column: TableColumn<any>,
        sortOrder: SortOrder
    ) => {
        setSortField(column.sortField);
        setSortOrder(
            sortOrder === 'asc'
                ? DataTableSortOrder.Ascending
                : DataTableSortOrder.Descending
        );
    };

    const profileCompletenessHeaderSelNHID = orgNHID !== selNHID ? selNHID : null;
     return (
        <> 
            <PageCommunitySelectWrapper
                label={'PDF Brochure for'}
                handleSelect={handleSelect}
                selNHID={selNHID}
            />
            <ProfileCompletenessHeader
                selNHID={profileCompletenessHeaderSelNHID}
                nhIDs={nhIDs}
                orgNHID={orgNHID}
                profileScoreType={ProfileScoreType.PdfBrochure}
                handleProfileCompletenessFilter={setProfileCompletenessFilterValue}
                profileCompletenessFilterValue={profileCompletenessFilterValue}
                title="for PDF Brochures"
            />
            <section>
                <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                    { nhIDs?.length !== 1 && !selNHID ?
                        <MultiOrgPropertyBrochures
                            handleSelNHID={setSelNHID}
                            orgNHID={orgNHID}
                            nhIDs={nhIDs}
                            selNHID={selNHID}
                            propertyBrochures={propertyBrochures}
                            setPropertyBrochures={setPropertyBrochures}
                            busyLoadingAsset={busyLoadingAsset}
                            setBusyLoadingAsset={setBusyLoadingAsset}
                            handleTableSortChange={handleTableSortChange}
                            handleReloadPropertyBrochures={getPropertyBrochures}
                        /> :
                        <IndividualPropertyBrochures
                            handleReloadPropertyBrochures={getPropertyBrochures}
                            selNHID={selNHID}
                            propertyBrochures={propertyBrochures}
                            setPropertyBrochures={setPropertyBrochures}
                            busyLoadingAsset={busyLoadingAsset}
                            setBusyLoadingAsset={setBusyLoadingAsset}
                        />
                    }
                </div>
            </section>
        </>
    );
};

export default PropertyBrochures;