import React from 'react';
import { Button } from '../../../../components/common/button';
import { useState } from 'react';
import { ProfileScoreType } from '../../../../interfaces/property';
import { CareOfferedCostFundingModal } from './care_offered_cost_funding_modal';

interface IProps {
    row: any;
    hasCoreDetails: boolean;
    profileScoreType: ProfileScoreType
}

export const CareOfferedCostFundingModalButton = ({ row, hasCoreDetails, profileScoreType }: IProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>  
            <Button
                key={`edit-${row.NHID}`}
                onClick={() => setIsOpen(true)} 
                className="ms-auto"
            >
                Edit
            </Button>
            <CareOfferedCostFundingModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                row={row}
                hasCoreDetails={hasCoreDetails}
                profileScoreType={profileScoreType}
            />
        </>
    );
};

export { CareOfferedCostFundingModal };
