export interface ISelectOption<T = any> {
    label: string;
    value: any;
}

export interface IRadioGroupData {
    label: string;
    value: any;
}

export const YesNoAllRadioData: IRadioGroupData[] = [{
    label: 'All',
    value: null 
}, {
    label: 'Yes',
    value: true 
}, {
    label: 'No',
    value: false 
}];

export const YesNoFilterOptions: ISelectOption<'true' | 'false' | null>[] = [
    {label: 'All', value: null},
    {label: 'Yes', value: 'true'},
    {label: 'No', value: 'false'}
];
