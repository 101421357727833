import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';

export const FilterButton = () => {
    return (
        <label htmlFor="filters" className="btn btn-outline btn-outline-tertiary">
            <FontAwesomeIcon icon={faFilter} className="text-3xl me-2" />Filters
        </label>
    );
};