import React from 'react';
import { IContentOverviewLinkProps, ProfileScoreExtraType, ProfileScoreType } from '../../../../interfaces/property';
import { Link } from 'react-router-dom';

interface LinkData{
    url: string;
    text: 'View' | 'Update';
    state?: unknown;
}

const PropertyProfileContentOverviewPageLink = ({ nhID, keyName }: IContentOverviewLinkProps) => {
    
    const linkState = { filters: { NHID: nhID } };

    const scoreTypeUrlMap: Partial<Record<ProfileScoreType | ProfileScoreExtraType, LinkData>> = {
        [ProfileScoreType.Description]: {
            url: '/descriptions',
            state: linkState,
            text: 'View'
        },
        [ProfileScoreExtraType.PortalUsers]: {
            url: '/users',
            state: linkState,
            text: 'Update'
        },
        [ProfileScoreType.MeetTheTeam]: {
            url: '/team',
            state: linkState,
            text: 'Update'
        }
    } as const;
   
    if (!scoreTypeUrlMap[keyName]) {
        return null;
    }

    const { url, state, text } = scoreTypeUrlMap[keyName];
    
    return (
        <Link
            to={url}
            state={state || null}
            className="link text-xs bg-brand_faint-blue px-2 py-1 inline-block leading-none rounded-lg relative -top-px"
        >
            {text}
        </Link>
    );
};

export { PropertyProfileContentOverviewPageLink };
