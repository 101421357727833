import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import PageTitle from '../../components/common/page_title';
import { ISessionProperty, ISessionState } from '../../interfaces/session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { IMenuStateTotals } from '../../interfaces/menu';
import { LoadingDots } from '../../components/common/loading_dots';


type Crumb = {
    url?: string;
    text: string;
    getLinkCount?: (menuTotals: IMenuStateTotals) => number
};
interface IProps {
    Component: any;
    Crumbs: Crumb[];
    HeaderShow: boolean
    HeaderTitle?: string;
    HeaderText?: string;
    PageTitleText?: string;
    SupportArticleButtonShow?: boolean;
}

const NonMenuPageTemplate = ({
    Component,
    Crumbs,
    HeaderShow,
    HeaderTitle,
    HeaderText,
    PageTitleText,
    SupportArticleButtonShow
}: IProps) => {  
    const { Session, Org, Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const menuTotals: IMenuStateTotals = useSelector(
        ({ MenuTotalsState }: any) => MenuTotalsState
    );

    const renderBreadcrumbs = () => {    
        return (
            <nav className="hidden lg:block overflow-auto border-b border-brand_grey scrollbar scrollbar-sm scrollbar-full scrollbar-breadcrumbs">
                <ul className="flex text-md 2xl:text-base first:[&>li]:ms-auto last:[&>li]:me-auto [&>li]:whitespace-nowrap [&>li>*]:inline-block [&>li]:leading-none [&>li]:px-3 [&>li>*]:py-5 xl:[&>li>*]:py-6 [&>li>a]:relative before:[&>li.active>a]:w-full before:[&>li>a]:border-b-[3px] before:[&>li>a]:border-b-brand_primary hover:before:[&>li>a]:w-full before:[&>li>a]:ease-in-out before:[&>li>a]:duration-200 before:[&>li>a]:absolute before:[&>li>a]:w-0 before:[&>li>a]:bottom-0 before:[&>li>a]:left-0">
                
                    { Crumbs.map((crumb) => {
                        const linkCount = crumb.getLinkCount ? <span className="bg-brand_faint-blue text-xs rounded-md py-0.5 px-1.5 ms-0.5">{crumb.getLinkCount(menuTotals)}</span> : null;
                        if (!crumb.url) {
                            return <li key={crumb.url}>
                                <strong className="font-semibold text-brand_grey-dark">
                                    {crumb.text}<FontAwesomeIcon icon={faChevronRight} className="ms-1.5 h-3 w-3 text-brand_grey-medium relative -top-px lg:h-3.5 lg:w-3.5" />
                                </strong>
                            </li>;
                        }

                        if (location.pathname === crumb.url) {
                            return (
                                <li className="link active" key={crumb.url}>
                                    <NavLink to={ crumb.url }>
                                        { crumb.text }{ linkCount}
                                    </NavLink>
                                </li> 
                            );
                        }

                    return <li className="link text-brand_grey-dark" key={crumb.url} >
                        <NavLink to={crumb.url }>
                            { crumb.text }{ linkCount}
                        </NavLink>
                    </li>;
                    })}
                </ul>
            </nav>
        );
    };
    
    const renderSupportLink = () => {
        return (
            <a 
                onClick={() => window.frames[0].document.getElementById('doc360-button').click()}
                className="fw-bold support-link"
            >
                Learn more in the Support Center
            </a>
        );
    };

    const renderMeta = () => {
        const title = `${PageTitleText} | nursinghomes.com Portal `;

        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ title }</title>
                <meta name="description" content={ title } />
            </Helmet>
        );
    };

    const renderComponent = (): any => {

        if (!Session) {
            return null;
        }

        let nhIDs: number[] | null;
        let orgNHID: number | null;

        if (Org) {
            orgNHID = Org.NHID;
            nhIDs = null;
        } else {
            if (Properties) {
                orgNHID = null;
                nhIDs = Properties.map((item: ISessionProperty) => item.NHID);
            }
        }

        const props = { 
            nhIDs,
            orgNHID
        };

        return (
            <Component {...props}/>
        );
    };

    const renderComponentWithHeader = () => {
        return (
            <>
                { renderBreadcrumbs() }
                <header>
                    <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                            <PageTitle title={HeaderTitle}/>
                            <p>
                                { HeaderText || null }
                                { SupportArticleButtonShow ? renderSupportLink() : null }
                            </p>

                    </div>
                </header>
                <main>
                    { renderComponent() }
                </main>
            </>
        );
    };

    return (
        <>
            <div id='page_template_top'>
                { !Session ? <LoadingDots show={true}/> : null }
                { renderMeta() }
                { HeaderShow ? renderComponentWithHeader() : renderComponent() } 
            </div>
        </>
    );
};

export default NonMenuPageTemplate;
 