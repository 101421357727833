import * as yup from 'yup';
import { validationHelpers } from '../../../helpers';

const {
    yupNullableStringToNumberCost,
    yupNullableStringToBool
} = validationHelpers;

const schema = yup.object().shape({
    LongTermPrivateCostPerDay: yupNullableStringToNumberCost,
    LongTermPrivateCostPerDayNA: yup.boolean().nullable(),
    LongTermPrivateSuiteCostPerDay: yupNullableStringToNumberCost,
    LongTermRatePackagesAvailable: yupNullableStringToBool,
    LongTermRatesAllInclusive: yupNullableStringToBool,
    LongTermSemiPrivateCostPerDay: yupNullableStringToNumberCost,
    LongTermSemiPrivateCostPerDayNA: yup.boolean().nullable(),
    LongTermSharedCostPerDay: yupNullableStringToNumberCost,
    LongTermSharedCostPerDayNA: yup.boolean().nullable(),
});

export default schema;
