import _, { get } from 'lodash';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPlus } from '@fortawesome/pro-solid-svg-icons';
import * as csvEportAPI from '../../api/csv_export';
import { getUsers } from '../../api/user';
import ActiveFilters from '../../components/common/active_filters';
import DataTable, { INITIAL_ROWS_PER_PAGE } from '../../components/common/data_table';
import { LoadingDots } from '../../components/common/loading_dots';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import CsvExport, { DisplayType } from '../../components/csv_export/csv_export';
import NoUserData from '../../components/users/no_user_data';
import UserTableExpandableRows from '../../components/users/user_table_expandable_rows';
import { FilterType } from '../../interfaces/filters';
import { ISelectOption } from '../../interfaces/form';
import { ISessionState } from '../../interfaces/session';
import { IPortalUser } from '../../interfaces/user';
import FilterMatrix from '../../modules/filter_matrix';
import { scrollToTop } from '../../utils/common';
import { shortFormatDateTime } from '../../utils/date';
import { formatFileNameForCsvExport } from '../../utils/file';
import { SessionType } from '../../interfaces/session';
import AppContext from '../../context/app_context';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';
import { FilterButton } from '../../components/common/filter_button';

const createFilters = () => {
    return new FilterMatrix([
        {
            Key: 'FirstName', 
            FilterFormLabel: 'Filter by First Name', 
            Type: FilterType.String, 
            FilterPropName: 'FirstName'
        },
        {
            Key: 'LastName', 
            FilterFormLabel: 'Filter by Last Name', 
            Type: FilterType.String, 
            FilterPropName: 'LastName'
        },
        {
            Key: 'Permissions', 
            FilterFormLabel: 'Filter by Permissions', 
            Type: FilterType.LabelValue, 
            FilterPropName: 'Permissions'
        },
        {
            Key: 'EmailAddress', 
            FilterFormLabel: 'Filter by Email Address', 
            Type: FilterType.String, 
            FilterPropName: 'EmailAddress'
        },
        {
            Key: 'LastLoginFrom', 
            FilterFormLabel: 'Filter by last login from', 
            Type: FilterType.DateFromUTC, 
            FilterPropName: 'LastLoginFrom'
        },
        {
            Key: 'LastLoginTo', 
            FilterFormLabel: 'Filter by last login to', 
            Type: FilterType.DateToUTC, 
            FilterPropName: 'LastLoginTo'
        }
    ]);
};

const permissionOptions: ISelectOption[] = [
    {
        label: 'Reply to Reviews',
        value: 'CanReplyToReview'
    }, {
        label: 'Manage Users',
        value: 'CanManageUsers'
    }, {
        label: 'Review Notifications',
        value: 'CanReceiveNotifications'
    },
    {
        label: 'Review Card Order Primary Contact',
        value: 'IsReviewCardPrimaryContact'
    },
    {
        label: 'Primary Contact',
        value: 'IsPrimaryContact'
    },
    {
        label: 'Primary Subscription Contact',
        value: 'IsPrimarySubscriptionContact'
    }
];

const UserList = () => { 
    const sessionState: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );
    
    const [, updateState] = useState<any>();
    const [ selNHID, setSelNHID ] = useState<number>(null);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ users, setUsers ] = useState<IPortalUser[]>([]);
    const [ totalUserCount, setTotalUserCount ] = useState<number>(0);
    const [ pageOffset, setPageOffset ] = useState<number>(0);
    const [ pageLimit, setPageLimit ] = useState<number>(INITIAL_ROWS_PER_PAGE);
    const [ resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);
    const [filterMatrix ] = useState(createFilters());

    const canManageUsers = _.get(sessionState, 'Session.Perms.CanManageUsers', false);
    const getDataDebounced = useCallback(_.debounce(() => getUserData(), 500), []);
    
    const location = useLocation();
    const defaultNHID = get(location, 'state.filters.NHID', undefined);

    if (!selNHID && defaultNHID) {
        setSelNHID(defaultNHID);
    }

    useEffect(() => {
        setResetPaginationToggle(!resetPaginationToggle);
        setPageOffset(0);
    }, [pageLimit, selNHID]);

    useEffect(() => {
        getUserData();
    },  [pageLimit, selNHID, pageOffset]);


    const appContext: any = useContext(AppContext);

    const buildColumns = () => {
        return [
            {
                name: 'User ID',
                selector: (row: IPortalUser) => row.UserID,
                sortable: true,
                id: 'UserID',
                width: appContext.isMobile ? '100px' : '120px'
            },
            {
                name: 'Name (Job Title)',
                cell: (row: IPortalUser) => <div>
                    <span className="inline-block me-0.5">{row.FirstName} {row.LastName}</span> {row.JobTitle?.trim() ? <><em className="text-brand_grey-medium">({row.JobTitle})</em></>: null}
                </div>,
                selector: (row: IPortalUser) => row.FirstName.toLowerCase(),
                sortable: true,
                id: 'Name',
                width: appContext.isMobile ? '180px' : appContext.isTablet ? '200px' : '*'
            },
            {
                name: 'User Email',
                cell: (row: IPortalUser) => row.EmailAddress,
                selector: (row: IPortalUser) => row.EmailAddress.toLowerCase(),
                sortable: true,
                id: 'EmailAddress',
                width: appContext.isMobile ? '180px' : appContext.isTablet ? '200px' : '*'
            },
            {
                name: 'Linked to Org/Facility',
                cell: (row: IPortalUser) => formatOrgPropertyColumn(row),
                id: 'LinkToOrg/Facility',
                width: appContext.isMobile ? '250px' : appContext.isTablet ? '300px' : '*'
            },
            {
                name: 'Permissions',
                cell: (row: IPortalUser) => formatPermissionsColumn(row),
                id: 'Permissions',
                width: '220px'
            },
            {
                name: 'Last Login Date',
                selector: (row: IPortalUser) => new Date(row.LastLoginDate).getTime(),
                cell: (row: IPortalUser) => formatLastLoginDateColumn(row),
                sortable: true,
                id: 'LastLoginDate',
                width: appContext.isMobile ? '180px' : '200px'
            }
        ];
    };

    const formatPermissionsColumn = (row: IPortalUser) => {
        const {
            CanReplyToReview,
            CanManageUsers,
            CanReceiveNotifications,
            IsReviewCardPrimaryContact,
            IsPrimaryContact,
            IsPrimarySubscriptionContact
        } = row;
  

        return (
            <>  
                <ul className="flex flex-col gap-1 text-2xs whitespace-nowrap [&>li]:px-2 [&>li]:py-1 [&>li]:text-white [&>li]:rounded-md [&>li]:w-fit">
                    { CanReplyToReview ? <li className="permission-reviews">Reply to Reviews</li>: null}
                    { CanReceiveNotifications ? <li className="permission-notifications">Review Notifications</li>: null}
                    { CanManageUsers ? <li className="permission-users">Manage Users</li>: null}
                    { IsReviewCardPrimaryContact ? <li className="permission-reviewcardprimary">Review Card Contact</li>: null}
                    { IsPrimaryContact ? <li className="permission-primarycontact">Primary Contact</li>: null}
                    { IsPrimarySubscriptionContact ? <li className="permission-primarysubscriptioncontact">Subscription Primary Contact</li>: null}
                </ul>
            </>
        );
    };

    const formatLastLoginDateColumn = (row: IPortalUser) => { 

        if (row.LastLoginDate) {
            return <div>{shortFormatDateTime(row.LastLoginDate)}</div>;
        }

        if (!row.HasActivated) {
            return <div>Not Yet Activated</div>;
        }
        
        return <div>-</div>;
    };

    const formatOrgPropertyColumn = (row: IPortalUser) => {
        const { KeyName, Entities } = row;
        if (!Entities.length) {
            return null;
        }

        if (KeyName === SessionType.SessionTypeOrg) {
            return  (
                <>
                    { Entities[0].EntityName  }(Org)
                </>
            );
        } else if (KeyName === SessionType.SessionTypeProperty && Entities.length === 1) {
            return (
                <>
                    { Entities[0].PropertyName }
                </>
            );
        }else if (KeyName === SessionType.SessionTypeProperty && Entities.length > 1) {
            const facilityList = Entities.map((entity) =>{
                return entity.PropertyName;
            });
            const facilityArray : any[] = [];
            facilityList.forEach((item)=> {
                facilityArray.push(<>{item}<br/></>);
            });
            return facilityArray;
        }
    };


    const columns: any = buildColumns();

    if (canManageUsers) {
        columns.push({
            name: '',
            cell: ({ UserID, Entities }: IPortalUser) => {
                const isLinkedViaOrg = Entities.some((entity) => entity.EntityType === 'org');
                // when not in org session, we don't want to be able to edit users linked only by org
                return !sessionState.Org && isLinkedViaOrg ? null : (
                    <Link to="/users/edit" state={{ UserID }} className="btn ms-auto">
                        Edit
                    </Link>
                );
            },
            id: 'editbutton',
            width: appContext.isMobile ? '120px' : '130px'
        });
    }

    const applyFilter = (key: string, value: any, refreshData = true) => {
        filterMatrix.setValue(key, value);
        forceUpdate();
        if (refreshData) {
            getDataDebounced();
        }
    };

    const resetFilters = () => {
        filterMatrix.reset();
        getUserData();
    };
    
    const forceUpdate = useCallback(() => updateState({}), []);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    const getParams = () => {
        const params: any = {
            ...filterMatrix.getRequestParams(),
            NHID: selNHID,
            Offset: pageOffset,
            Limit: pageLimit
        };
        
        if (params.Permissions) {
            params.Permissions.split(',').forEach((p: string) => params[p] = true); 
            delete params.Permissions;
        }

        return params;
    };

    const getUserData = async () => {
        const params = getParams();

        setIsLoading(true);

        const data = await getUsers(params);

        const rowData = _.get(data, 'data', []);
        const totalRows = _.get(data, 'totalRows', 0);
    
        setUsers(rowData);
        setTotalUserCount(totalRows);
        setIsLoading(false);
    };

    const onPageChange = async (pageNumber: number) => {
        const newPageOffset = (pageNumber - 1) * pageLimit;

        if (newPageOffset !== pageOffset) {
            setPageOffset(newPageOffset);
            scrollToTop();
        }
    };

    const onLimitChange = async (newPerPage: number, pageNumber: number) => {
        if (newPerPage !== pageLimit) {
            setPageOffset((pageNumber - 1) * pageLimit);
            setPageLimit(newPerPage);
            scrollToTop();
        }
    };

    const startExport = async (): Promise<string> => {
        const params = getParams();

        return csvEportAPI.startExport('/user/export', params);
    };

    return (
        <>
             <PageCommunitySelectWrapper
                label={'Show users who have access to:'}
                handleSelect={handleSelect}
                selNHID={selNHID}
            />
            <section>
                <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                    <div>
                        <input type="checkbox" className="hidden peer/filters" id="filters" />
                        <div className="hidden peer-checked/filters:block relative ring-1 ring-brand_grey rounded-xl p-3 sm:p-4 bg-brand_faint-blue mb-4 sm:mb-6 md:mb-8 lg:mb-10">
                            <div className="flex items-center">
                                <strong className="text-lg md:text-xl">
                                    Filter Results
                                </strong>
                                <button
                                    className="ms-4 btn btn-outline btn-outline-tertiary btn-small flex items-center text-md"
                                    onClick={resetFilters}>
                                        <FontAwesomeIcon icon={faXmark} className="me-1" />Clear all filters
                                </button>
                                <label htmlFor="filters" className="ms-auto cursor-pointer rounded-full bg-brand_tertiary hover:bg-brand_tertiary-dark text-white w-8 h-8 flex justify-center items-center">
                                    <FontAwesomeIcon icon={faXmark} />
                                </label>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 mt-6">
                                <div className="form-component form-inline form-textbox">
                                    <label
                                        htmlFor="FirstName"
                                    >
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        id="FirstName"
                                        onChange={(e) => applyFilter('FirstName', e.target.value)}
                                        onKeyUp={(e: any) => e.keyCode === 13 ? applyFilter('FirstName', e.target.value) : ''}
                                        value={filterMatrix.getFormFieldValue('FirstName')}
                                    />
                                </div>
                                <div className="form-component form-inline form-textbox">
                                    <label
                                        htmlFor="LastName"
                                    >
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        id="LastName"
                                        onChange={(e) => applyFilter('LastName', e.target.value)}
                                        onKeyUp={(e: any) => e.keyCode === 13 ? applyFilter('LastName', e.target.value) : ''}
                                        value={filterMatrix.getFormFieldValue('LastName')}
                                    />
                                </div>
                                <div className="form-component form-inline form-textbox">
                                    <label
                                        htmlFor="EmailAddress"
                                    >
                                        Email Address
                                    </label>
                                    <input
                                        type="email"
                                        id="EmailAddress"
                                        onChange={(e) => applyFilter('EmailAddress', e.target.value)}
                                        onKeyUp={(e: any) => e.keyCode === 13 ? applyFilter('EmailAddress', e.target.value) : ''}
                                        value={filterMatrix.getFormFieldValue('EmailAddress')}
                                    />
                                </div>
                                <div className="form-component form-select form-inline">
                                    <label
                                        htmlFor="Permissions"
                                    >
                                        Permissions
                                    </label>
                                    <Select
                                        id="Permissions"
                                        onChange={(e) => applyFilter('Permissions', e)}
                                        options={permissionOptions}
                                        isMulti
                                        closeMenuOnSelect={true}
                                        name="Permissions"
                                        classNamePrefix="select"
                                        value={filterMatrix.getFormFieldValue('Permissions')}
                                        unstyled
                                    />
                                </div>
                                <div className="form-component form-inline form-datepicker">
                                    <label
                                        htmlFor="LastLoginFrom"
                                    >
                                        Last Login From
                                    </label>
                                    <DatePicker
                                        id="LastLoginFrom"
                                        dateFormat="MMMM d, yyyy"
                                        onChange={(date: any) => {
                                            if (date) {
                                                date.setHours(0, 0, 0, 0);
                                            }
                                            applyFilter('LastLoginFrom', date);
                                        }}
                                        maxDate={filterMatrix.getFormFieldValue('LastLoginTo') || new Date()}
                                        selected={filterMatrix.getFormFieldValue('LastLoginFrom')}
                                        placeholderText="Click to select a date"
                                        showPopperArrow={false}
                                    />
                                </div>
                                <div className="form-component form-inline form-datepicker">
                                    <label
                                        htmlFor="LastLoginTo"
                                    >
                                        Last Login To
                                    </label>
                                    <DatePicker
                                        id="LastLoginTo"
                                        dateFormat="MMMM d, yyyy"
                                        onChange={(date: any) => {
                                            if (date) {
                                                date.setHours(23, 59, 59, 999);
                                            }
                                            applyFilter('LastLoginTo', date);
                                        }}
                                        minDate={filterMatrix.getFormFieldValue('LastLoginFrom')}
                                        maxDate={new Date()}
                                        selected={filterMatrix.getFormFieldValue('LastLoginTo')}
                                        placeholderText="Click to select a date"
                                        showPopperArrow={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center text-center gap-3 md:flex-row md:text-start">
                        <ActiveFilters 
                            filterMatrices={[filterMatrix]} 
                            totalFilteredSize={totalUserCount}
                            isLoading={isLoading}
                            pageOffset={pageOffset}
                            pageLimit={pageLimit}
                            singularNoun={'User'}
                            pluralNoun={'Users'}
                            showTotals={false}
                            applyFilter={applyFilter}
                        />
                        <div className="md:ms-auto flex flex-wrap justify-center md:justify-end gap-3 lg:flex-nowrap">
                            <FilterButton />
                            <CsvExport 
                                startExportFunction={startExport}
                                modalTitleSuffix={'Users'}
                                label="Export as CSV"
                                displayType={DisplayType.Button}
                                fileName={`${formatFileNameForCsvExport('user_export')}`}
                            />
                            <Link to="/users/add" className="btn">
                                <FontAwesomeIcon icon={faPlus} className="text-3xl me-2" />Add User
                            </Link>
                        </div>
                    </div>
                    <div className="mt-4 sm:mt-6 md:mt-8 lg:mt-10">
                        <div data-table="Portal Users" className="relative [&>div]:scrollbar [&>div]:pb-2 [&>div]:-mx-3 [&>div]:w-[calc(100%+1.5rem)] [&>div]:px-3 sm:[&>div]:-mx-4 sm:[&>div]:w-[calc(100%+2rem)] sm:[&>div]:px-4 xl:[&>div]:mx-0 xl:[&>div]:w-full xl:[&>div]:px-0 before:h-full before:top-0 before:absolute before:z-10 before:bg-gradient-to-r before:from-white before:w-3 before:-left-3 after:h-full after:top-0 after:absolute after:z-10 after:bg-gradient-to-l after:from-white after:-right-3 after:w-3 sm:before:-left-4 sm:before:w-4 sm:after:-right-4 sm:after:w-4 xl:before:content-none xl:after:content-none">
                            {
                                isLoading ? <LoadingDots show={true} />
                                : <DataTable
                                    columns={columns}
                                    data={users}
                                    expandableRows={true}
                                    expandableRowsComponent={UserTableExpandableRows}
                                    pagination={true}
                                    paginationServer={true}
                                    paginationTotalRows={totalUserCount}
                                    onChangePage={onPageChange}
                                    onChangeRowsPerPage={onLimitChange}
                                    paginationResetDefaultPage={resetPaginationToggle}
                                    fixedHeader={false}
                                    noDataComponent={<NoUserData loading={isLoading} />}
                                    defaultExpanded={false}
                                    defaultExpandedIDProp={'UserID'}
                                /> 
                            }
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default UserList;