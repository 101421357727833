import { IPortalPropertyAsset } from '../../../interfaces/asset';
import { buildProfileCompleteMapForNHIDs } from '../../../utils/profile_completeness';
import { IPortalScoreItem } from '../../../interfaces/property';

import * as assetApi from '../../../api/assets';

export const getPropertiesVideos = async (
    nhIDs: number[],
    profileCompletenessState: IPortalScoreItem,
    profileCompletenessFilterValue: boolean | null
): Promise<IPortalPropertyAsset[]> => {
    const filters = new Map<string, any>();
    const videoData = await assetApi.getPropertiesVideos(nhIDs, filters);
    return filterByProfileCompleteness(videoData, profileCompletenessFilterValue, profileCompletenessState);
};

export const getPropertyVideos = async (
    nhID: number[], 
    profileCompletenessState: IPortalScoreItem, 
    profileCompletenessFilterValue: boolean | null,
    orgNHID?: number
): Promise<IPortalPropertyAsset[]> => {
    if(nhID[0] === orgNHID) {
        return await getOrgPropertiesVideos(orgNHID, null, profileCompletenessState, profileCompletenessFilterValue);
    }
    const videoData = await assetApi.getPropertiesVideos(nhID);
    return filterByProfileCompleteness(videoData, null, profileCompletenessState);
};

export const getOrgPropertiesVideos = async (
    orgNHID: number, 
    showWithNoVideoFilter: boolean,
    profileCompletenessState: IPortalScoreItem,
    profileCompletenessFilterValue: boolean | null
): Promise<IPortalPropertyAsset[]> => {
    if (!orgNHID) {
        console.error('getOrgPropertiesVideos: Invalid orgNHID');
        return null;
    }

    const filters = new Map<string, any>();
    if (showWithNoVideoFilter) {
        filters.set('LowResPhotos', showWithNoVideoFilter);
    }
    const videoData = await assetApi.getOrgPropertiesVideos(orgNHID, filters);

    return filterByProfileCompleteness(videoData, profileCompletenessFilterValue, profileCompletenessState);
};

const filterByProfileCompleteness = (
    videoData: IPortalPropertyAsset[],
    profileCompletenessFilterValue: boolean | null,
    profileCompletenessState: IPortalScoreItem
) => {
    if (profileCompletenessFilterValue === null) {
        return videoData;
    }

    const profileMap = buildProfileCompleteMapForNHIDs(profileCompletenessState);

    return videoData.filter((item: IPortalPropertyAsset) => {
        const profileCompleteItem = profileMap.get(item.NHID);
        return profileCompleteItem?.VideoComplete === profileCompletenessFilterValue;
    });
};

