import { useSelector } from 'react-redux';
import {
    IPortalScoreItem,
    IPropertyScores,
    ProfileScoreType
} from '../interfaces/property';
import { useState } from 'react';

const useProfileCompletenessFilter = (scoreType: ProfileScoreType) => {
    const [profileCompletenessFilterValue, setProfileCompletenessFilterValue] =
        useState<boolean | null>(null);

    const profileCompletenessState: IPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    const completenessMap: Record<string, Record<string, boolean>> = {};
    profileCompletenessState.PropertyScores.forEach(
        (item: IPropertyScores) => {
            completenessMap[item.NHID] = item.Scores.reduce((acc, curr) => {
                acc[curr.KeyName] = curr.Score === curr.AvailableScore;
                return acc;
            }, {} as Record<string, boolean>);
        }
    );

    const filter = (originalData: any) => {
        const dataWithProfileCompleteness = originalData.map((o: any) => ({
            ...o,
            ProfileComplete: completenessMap[o.NHID]?.[scoreType]
        }));

        if (profileCompletenessFilterValue === null) {
            return dataWithProfileCompleteness;
        }
        return dataWithProfileCompleteness
            .filter((item: any) => {
                return item.ProfileComplete === profileCompletenessFilterValue;
            });
    };

    return {
        handleProfileCompletenessFilterChange:
            setProfileCompletenessFilterValue,
        withProfileCompleteness: filter,
        profileCompletenessFilterValue
    };
};

export { useProfileCompletenessFilter };
