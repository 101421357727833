import { useQuery } from '@tanstack/react-query';
import { getTourDetailsList } from '../../../../api/tours';

export const useTourDetailsList = () => {
    const { data = { rows: [], totalRowCount: 0 }, isLoading } = useQuery({
        queryFn: () => getTourDetailsList(),
        queryKey: ['portal', 'tour', 'details']
    });

    return {
        data: {
            ...data,
            rows: data?.rows || []
        },
        isLoading
    };
};