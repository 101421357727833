import React, { useEffect, useState } from 'react';
import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import MultiOrgPropertyVideos from '../../../components/assets/multi_org_property_videos';
import IndividualPropertyVideos from '../../../components/assets/individual_property_videos';
import { useLocation } from 'react-router-dom';
import { ISessionState, SessionType } from '../../../interfaces/session';
import { useSelector } from 'react-redux';
import ProfileCompletenessVideoHeader from '../../../components/profile_completeness/profile_completeness_video_header';
import { IPortalScoreItem } from '../../../interfaces/property';
import { useCommunitySelectHistory } from '../../../hooks/use_community_select_history';
import { getOrgPropertiesVideos, getPropertyVideos } from './utils';
import { getPropertiesVideos } from './utils';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyVideos = ({ nhIDs, orgNHID }: IProps) => {
    const location = useLocation();
    const state = location?.state as any;
    const defaultNHID = state?.NHID || null;
    const [busyLoadingAsset, setBusyLoadingAsset] = useState<boolean>(true);
    const [selNHID, setSelNHID] = useState<number>(defaultNHID);
    const [propertyVideos, setPropertyVideos] = useState<any[]>([]);
    const [profileCompletenessFilterValue, setProfileCompletenessFilterValue] = useState<boolean | null>(null);
    const [showWithNoVideoFilter, setShowWithNoVideoFilter] = useState<boolean>(false);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    const { Session }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const profileCompletenessState: IPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    const { SessionType: sessionType } = Session;

    const loadPropertyVideos = async () => {
        if (!selNHID) {
            setPropertyVideos(null);
            setBusyLoadingAsset(true);
            try {
                const propertyVideos = sessionType === SessionType.SessionTypeOrg
                    ? await getOrgPropertiesVideos(orgNHID, showWithNoVideoFilter, profileCompletenessState, profileCompletenessFilterValue)
                    : await getPropertiesVideos(nhIDs, profileCompletenessState, profileCompletenessFilterValue);

                    setPropertyVideos(propertyVideos);
            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingAsset(false);
            }
        } else {
            setBusyLoadingAsset(false);
        }
    };

    useEffect(() => {
        const singleNHID = selNHID || (nhIDs?.length === 1 ? nhIDs[0] : null);
        if (singleNHID) {
            setSelNHID(singleNHID);
        }
    }, [nhIDs, orgNHID]);

    useEffect(() => {
        if (!selNHID) {
            loadPropertyVideos();
        }

        setBusyLoadingAsset(true);
        (async () => {
            try {
                if (selNHID && selNHID !== orgNHID) {
                    const propertyVideos = await getPropertyVideos([selNHID], profileCompletenessState, profileCompletenessFilterValue, orgNHID);
                    if (propertyVideos) {
                        setPropertyVideos(propertyVideos);
                    }
                } 
            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingAsset(false);
            }
        })();
    }, [selNHID]);

    useEffect(() => {
        loadPropertyVideos();


    }, [profileCompletenessFilterValue, showWithNoVideoFilter]);


     return (
        <> 
            <PageCommunitySelectWrapper
                label={'All Videos for'}
                handleSelect={handleSelect}
                selNHID={selNHID}
            />
            <ProfileCompletenessVideoHeader
                selNHID={selNHID}
                nhIDs={nhIDs}
                orgNHID={orgNHID}
                handleProfileCompletenessFilter={setProfileCompletenessFilterValue}
                handleNoVideoFilter={setShowWithNoVideoFilter}
                showWithNoVideo={showWithNoVideoFilter}
                profileCompletenessFilterValue={profileCompletenessFilterValue}
            />
            <section>
                <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                    {
                        nhIDs?.length !== 1 && !selNHID ?
                            <MultiOrgPropertyVideos
                                orgNHID={orgNHID}
                                nhIDs={nhIDs}
                                profileCompletenessFilterValue={profileCompletenessFilterValue}
                                propertyVideos={propertyVideos}
                                setPropertyVideos={setPropertyVideos}
                                busyLoadingAsset={busyLoadingAsset}
                                setBusyLoadingAsset={setBusyLoadingAsset}
                                handleReloadPropertyVideos={getPropertyVideos}
                            />
                        :
                            null
                    }
                    {
                        nhIDs?.length === 1 || selNHID ?
                            <IndividualPropertyVideos
                                handleReloadPropertyVideos={getPropertyVideos}
                                profileCompletenessFilterValue={profileCompletenessFilterValue}
                                selNHID={selNHID}
                                propertyVideos={propertyVideos}
                                setPropertyVideos={setPropertyVideos}
                                busyLoadingAsset={busyLoadingAsset}
                                setBusyLoadingAsset={setBusyLoadingAsset}
                            />
                        :
                            null
                    }
                </div>
            </section>
        </>
    );
};

export default PropertyVideos;