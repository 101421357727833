import React, {
    ComponentType,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import cx from 'classnames';
import { getNearestScrollingAncestor } from '../utils/dom';
import { LoadingDots as Dots } from '../components/common/loading_dots';

interface ITabProps {
    children: React.ReactNode;
    isActive: boolean;
    onClick: () => void;
}

interface ITab<T = string> {
    tabInfo?: ReactNode;
    tabHeader: ReactNode;
    tabKey: T;
    tabContent: ComponentType;
}

interface ITabOptions {
    isScrollTab?: boolean;
}

const Tab = (props: ITabProps) => {
    const { children, isActive, onClick } = props;

    const listItemClassName = cx('link whitespace-nowrap relative cursor-pointer inline-block py-3 before:border-b-[3px] before:border-b-brand_tertiary hover:before:w-full before:ease-in-out before:duration-200 before:absolute before:w-0 before:bottom-0 before:left-0', {
        'before:w-full': isActive
    });

    return (
        <li className={listItemClassName}>
            <button
                onClick={onClick}
            >
                {children}
            </button>
        </li>
    );
};

const useCreateTabs = (tabConfig: ITab[], options: ITabOptions = {}) => {
    const { isScrollTab = false } = options;
    const firstTabKey = tabConfig?.[0]?.tabKey;
    const scrollParentRef = useRef(null);
    const sectionRefs = useRef([]);
    const [activeTab, setActiveTab] = useState(firstTabKey);

    useEffect(() => {
        setActiveTab(firstTabKey);
    }, [tabConfig]);

    const tabTitles = useMemo(
        () =>
            tabConfig.map((tab: ITab, index: number) => (
                <Tab
                    isActive={activeTab === tab.tabKey}
                    key={index}
                    onClick={() => {
                        if (isScrollTab) {
                            const tabElement = document.getElementById(
                                `tab-${tab.tabKey}`
                            );
                            tabElement.scrollIntoView({
                                behavior: 'smooth',
                                // for the first tab, scroll bottom edge close to end of container to ensure any 
                                // content before the first tab content, e.g. filters, is displayed
                                block: tab.tabKey === firstTabKey ? 'end' : 'start', 
                            });
                        } else {
                            setActiveTab(tab.tabKey);
                        }
                    }}
                >
                    {tab.tabHeader}
                </Tab>
            )),
        [tabConfig, activeTab, firstTabKey]
    );

    // only used when in scroll tab mode, this tracks which section is visible to update the tab header styling
    const setRef = (node: HTMLElement) => {
        let observer: IntersectionObserver;
        if (node) {
            scrollParentRef.current = getNearestScrollingAncestor(node);
            observer = new IntersectionObserver(
                (entries) => {
                    const visibleEntries = entries.filter(
                        (entry) => entry.isIntersecting
                    );

                    // Find the first visible entry (the highest one)
                    if (visibleEntries.length > 0) {
                        setActiveTab(
                            (visibleEntries[0].target as HTMLElement).dataset
                                .tabkey
                        );
                    }
                },
                {
                    root: scrollParentRef.current,
                    threshold: 0,
                    rootMargin: '0px 0px -100% 0px'
                }
            );
            sectionRefs.current.forEach((item) => {
                if (item instanceof Element) {
                    observer.observe(item);
                }    
            });
        } else {
            setActiveTab(firstTabKey);
            if (observer) {
                sectionRefs.current.forEach((item) => observer?.unobserve(item));
            }
        }
    };

    const activeTabInfo = tabConfig.find(
        (tab: ITab) => tab.tabKey === activeTab
    )?.tabInfo;

    const renderTabInfo = (tabInfo: ReactNode) => {
        if (!tabInfo) return null;
        return (
            <div className={cx('fs-6 p-4 bg-light', { 'mt-3': isScrollTab })}>
                {tabInfo}
            </div>
        );
    };

    const tabHeaders = () => (
        <>
            <div className="border-b border_brand_grey shadow">
                <ul className="px-3 sm:px-4 2xl:px-5 flex gap-4 overflow-x-auto no-scrollbar text-sm">
                    {tabTitles}
                </ul>
            </div>
            {!isScrollTab && renderTabInfo(activeTabInfo)}
        </>
    );

    const tabContent = tabConfig
        .filter((tab: ITab) => {
            if (isScrollTab) return true;
            return tab.tabKey === activeTab;
        })
        .map(({ tabContent: Tab, tabKey, tabInfo }, index: number) => (
            <div
                key={tabKey}
                id={`tab-${tabKey}`}
                {...(isScrollTab
                    ? { ref: (el) => (sectionRefs.current[index] = el) }
                    : {})}
                data-tabkey={tabKey}
            >
                {isScrollTab && renderTabInfo(tabInfo)}
                <Tab />
            </div>
        ));

    const tabContentWrapper = useCallback(
        () => (
            <div className="grid gap-3 sm:gap-5 md:gap-6 2xl:gap-7 pb-3 sm:pb-4 2xl:pb-5" {...(isScrollTab ? { ref: setRef } : {})}>
                {tabContent}
            </div>
        ),
        [...(!isScrollTab ? tabContent : []), tabConfig]
    );

    return { TabHeaders: tabHeaders, TabContent: tabContentWrapper };
};

const tabLoadingPlaceholderConfig = (tabCount: number = 3, tabWidth: string = '130px'): ITab[] => {
    return Array(tabCount).fill(null).map((_, index) => {
        return {
            tabHeader: <div
                className="flex-1 skeleton-loader-gradient bg-gradient-to-r from-slate-200 via-slate-100 to-slate-300 animate-pulse"
                style={{ height: '1.2em', width: tabWidth }}
            />,
            tabKey: `${index}`,
            tabContent: () => <div
                className="absolute inset-0 items-center justify-center flex"
            >
                {index === 0 && <Dots show={true}/>}

            </div>
        };
    });

};


export { useCreateTabs, type ITab, tabLoadingPlaceholderConfig };
