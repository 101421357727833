import { useSelector } from 'react-redux';
import { IPortalScoreItem, IPropertyScoresItem } from '../../../../interfaces/property';
import { RootState } from '../../../../store/reducers';
import FilterMatrix from '../../../../modules/filter_matrix';
import { FilterType } from '../../../../interfaces/filters';
import { useState } from 'react';

const useContentOverviewFilterMatrix = (): { 
    filterMatrix: FilterMatrix,
    rangeFilterMatrix: FilterMatrix
} => {
    const { PropertyScores }: IPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: RootState) => ProfileCompletenessState
    );

    const propertyScores = PropertyScores?.[0]?.Scores || [];

    if (!propertyScores.length) {
        return { filterMatrix: new FilterMatrix([]), rangeFilterMatrix: new FilterMatrix([]) };
    }

    const [ filterMatrix ] = useState<FilterMatrix>(new FilterMatrix(propertyScores.map((score: IPropertyScoresItem) => ({
        Key: score.FieldName,
        FilterFormLabel: score.FieldLabel,
        Type: FilterType.LabelValue,
        FilterPropName: score.FieldName
    }))));
    
    const [ rangeFilterMatrix ] = useState<FilterMatrix>(new FilterMatrix([{
        Key: 'ProfileCompletenessExpr',
        FilterFormLabel: 'Profile Completeness %',
        Type: FilterType.Range,
        FilterPropName: 'ProfileCompletenessExpr'
    }]));


    return { filterMatrix, rangeFilterMatrix };
};

export { useContentOverviewFilterMatrix };
