import * as yup from 'yup';
import { validationHelpers } from '../../../helpers';

const {
    yupNullableStringToNumberCost,
    yupNullableStringToBool
} = validationHelpers;

const schema = yup.object().shape({
    ShortTermPrivateCostPerDay: yupNullableStringToNumberCost,
    ShortTermPrivateCostPerDayNA: yup.boolean().nullable(),
    ShortTermPrivateSuiteCostPerDay: yupNullableStringToNumberCost,
    ShortTermRatePackagesAvailable: yupNullableStringToBool,
    ShortTermRatesAllInclusive: yupNullableStringToBool,
    ShortTermSemiPrivateCostPerDay: yupNullableStringToNumberCost,
    ShortTermSemiPrivateCostPerDayNA: yup.boolean().nullable(),
    ShortTermSharedCostPerDay: yupNullableStringToNumberCost,
    ShortTermSharedCostPerDayNA: yup.boolean().nullable(),
});

export default schema;
