import React, { MouseEvent, useState } from 'react';
import { generateUploadSignature, getCloudinaryMetadata } from '../../api/assets';
import { 
    INHSignatureParams,
    INHUploadSignature,
    ICloudinaryData,
    IAssetUploadLog,    
    NHAssetType
} from '../../interfaces/asset';
import { Button } from '../common/button';
import Swal from 'sweetalert2';

interface IUploadWidgetProps {
    nHID?: number;
    fileName?: string;
    assetTypeName?: string;
    assetType: number;
    onAssetUpload: (cloudinaryData: ICloudinaryData) => any;
    showAssetUploadResult: (uploadResult: IAssetUploadLog[]) => void;
    selectedTagID?: number | null;
    setIsUploadLogoTypeSelected?: (isUploadLogoTypeSelected: boolean | null) => void;
}

export default function UploadWidget(props: IUploadWidgetProps) {
    const [isOpeningWidget, setIsOpeningWidget] = useState<boolean>(false);
    
    const assetTypeSettings: any[] = [
        {},
        {buttonVerb: 'Select a Logo', uploadPreset: 'logos', maxFileSize: 40000000, resourceType: 'image' }, //40MB
        {},
        {buttonVerb: 'Select a Review Card Logo', uploadPreset: 'card_logos', maxFileSize: 40000000, resourceType: 'image' }, //40MB
        {},
        {buttonVerb: 'Select a Photo', uploadPreset: 'meet_team_photos', maxFileSize: 5000000, resourceType: 'image' }, //5MB
        {
            buttonVerb: <>Select a Website <br className="sm:hidden"/>&amp; Review Card Logo</>,
            uploadPreset: 'logos',
            maxFiles: 1,
            maxFileSize: 40000000,
            resourceType: 'image'
        }, //40MB
    ];

    const selectedAssetTypeSettings  = assetTypeSettings[props.assetType ? props.assetType - 1: 1];

    const generateSignature = async (callback: any, params_to_sign: any): Promise<void> => {
        try {
            const signatureParam: INHSignatureParams = { 
                UploadPreset: params_to_sign.upload_preset,
                CustomCoordinates: params_to_sign.custom_coordinates,
                FileName: params_to_sign.public_id
            };
            const signatureData:INHUploadSignature = await generateUploadSignature(signatureParam);
            callback({signature: signatureData.Signature, timestamp: signatureData.SignatureTimestamp});
        } catch (err) {
             await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `You are not authorize to upload ${props.assetTypeName}, Error generating upload signature.`
            });
         }
    };

    async function handleUploadAsset(event: MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        if (
            'assetType' in props && !props.assetType
        ) {
            props.setIsUploadLogoTypeSelected(true);
            await Swal.fire({
                icon: 'warning',
                iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
                title: 'Logo Use',
                text: 'Please select where you want to use this logo.',
                confirmButtonText: 'Okay',
                allowOutsideClick: false,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success m-1',
                }
            });
            return false;
        }        
        setIsOpeningWidget(true);
        const windowObj = window as any;
        const assetUploadLog: IAssetUploadLog[] = [];
        let fileName = null;

        if (props.fileName) {
            fileName = props.fileName.replace(/\s/g, '-');
            const randomString = Math.random().toString(36).substring(2, 9);
            const signatureTimestamp = Math.round((new Date).getTime()/1000);
            fileName = fileName + '_' + randomString + '1-' + signatureTimestamp;
        }
        const cloudinaryMetadata = await getCloudinaryMetadata();
        
        if (cloudinaryMetadata) {
            const assetUploadWidget = windowObj.cloudinary.createUploadWidget(
                {
                    uploadSignature: generateSignature,
                    cloudName: cloudinaryMetadata.CloudName,
                    apiKey: cloudinaryMetadata.ApiKey,
                    uploadPreset: selectedAssetTypeSettings.uploadPreset,
                    sources: ['local', 'url', 'dropbox', 'google_photos', 'facebook', 'instagram'],
                    multiple: false,
                    maxFiles: 1,
                    maxFileSize: selectedAssetTypeSettings.maxFileSize,
                    resourceType: selectedAssetTypeSettings.resourceType,
                    showPoweredBy: false,
                    thumbnails: '.thumbnailV2',
                    cropping: true,
                    publicId: fileName
                },
                async (error: any, result: any) => {
                    setIsOpeningWidget(false);
                    if (!error && result.event === 'success') {
                        try {
                            const transformedAssetUrl = [NHAssetType.Logos, NHAssetType.CardLogos].includes(props.assetType) ?
                                result.info.secure_url.replace(/\.[^/.]+$/, '.png'): result.info.secure_url;
                            const createdAssetDetail = await props.onAssetUpload({
                                AssetUrl : result.info.secure_url,
                                TransformedAssetUrl: transformedAssetUrl,
                                IsTransfromationComplete: true,
                                Sequence: 1,
                                CloudName: cloudinaryMetadata.CloudName
                            });
                            
                            assetUploadLog.push({
                                Type: 'success',
                                FileName: result.info.secure_url,
                                Message: '',
                                AssetDetail: createdAssetDetail
                            });
                            props.showAssetUploadResult(assetUploadLog);
                        } catch (e) {
                            assetUploadLog.push({ Type: 'error', FileName: result?.info?.secure_url, Message: 'Failed to upload' });
                        }
                    } else if (error) {
                        assetUploadLog.push({ Type: 'error', FileName: '', Message: error.statusText });
                    } 
                }
            );
            assetUploadWidget.open();
        } else {
            await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `You are not authorize to upload ${props.assetTypeName}, Error generating upload signature.`
            });
        }
    }

    return Object.hasOwn(window, 'cloudinary') ? (
        <div>
            <Button
                id="upload_widget"
                isLoading={isOpeningWidget}
                styleType="outline"
                onClick={handleUploadAsset}
            >
                {selectedAssetTypeSettings.buttonVerb}
            </Button>
            <div className="thumbnailV2" />
        </div>
    ) : (
        <span className="msg msg-error">
            The upload service is unavailable, please try again in a few
            minutes. If the problem persists please check (or ask your Tech
            department to check) that your firewall is not blocking the
            following domains: "res.cloudinary.com" "api.cloudinary.com"
            "widget.cloudinary.com" otherwise please contact your account
            manager.
        </span>
    );
}