import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import FilterMatrix from '../../../../modules/filter_matrix';
import Select from 'react-select';
import { YesNoFilterOptions } from '../../../../interfaces/form';
import { RangeFilters } from '../../../../components/common/filters/filter_range';

interface Props {
    filterMatrix: FilterMatrix;
    rangeFilterMatrix: FilterMatrix;
    applyFilter: (filterMatrix: FilterMatrix, key: string, value: any) => void;
    resetFilters: () => void;  
} 

const ProfileContentOverviewFilters = ({ filterMatrix, rangeFilterMatrix, applyFilter, resetFilters }: Props) => {

    const FilterItem = ({ filter }: { filter: { Key: string, FilterFormLabel: string } }) => {
        if (filter.Key === 'Total Score') {
            return null;
        }

        return (
            <div key={filter.Key} className="form-component form-inline form-select">
                <label htmlFor={filter.Key}>{filter.FilterFormLabel}</label>
                <Select
                    id={filter.Key}
                    onChange={(selectedOption: any) => applyFilter(filterMatrix, filter.Key, [selectedOption])}
                    options={YesNoFilterOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={filterMatrix.getFormFieldValue(filter.Key)}
                    unstyled
                />
            </div>
        );
    };

    return (
        <>
            <input type="checkbox" className="hidden peer/filters" id="filters" />
            <div className="hidden peer-checked/filters:block relative ring-1 ring-brand_grey rounded-xl p-3 sm:p-4 bg-brand_faint-blue mb-4 sm:mb-6 md:mb-8 lg:mb-10">
                <div className="flex items-center">
                    <strong className="text-lg md:text-xl">
                        Filter Results
                    </strong>
                    <button
                        className="ms-4 btn btn-outline btn-outline-tertiary btn-small flex items-center text-md"
                        onClick={resetFilters}>
                            <FontAwesomeIcon icon={faXmark} className="me-1" />Clear all filters
                    </button>
                    <label htmlFor="filters" className="ms-auto cursor-pointer rounded-full bg-brand_tertiary hover:bg-brand_tertiary-dark text-white w-8 h-8 flex justify-center items-center">
                        <FontAwesomeIcon icon={faXmark} />
                    </label>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4 xl:grid-cols-6 gap-3 mt-6">
                    { filterMatrix.filters.map((filter: any) => <FilterItem filter={filter} /> ) }
                    <div className="col-span-1 md:col-span-3">
                        <RangeFilters
                            filterMatrix={rangeFilterMatrix}
                            apply={applyFilter}
                        />                       
                    </div>
                </div>
            </div>
        </>
    );
};

export { ProfileContentOverviewFilters };
