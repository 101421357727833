import React from 'react';
import FilterMatrix from '../../modules/filter_matrix';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
    filterMatrices: FilterMatrix[];
    totalFilteredSize?: number;
    totalSize?: number;
    isLoading: boolean;
    pageOffset: number;
    pageLimit: number;
    singularNoun: string;
    pluralNoun: string;
    showTotals: boolean;
    applyFilter?: (key: string, value: any, refreshData?: boolean) => void;
    applyFilterWithMatrix?: (filterMatrix: FilterMatrix, key: string, value: any) => void;
    showResetLink?: boolean;
    refreshData?(): void;
}

const ActiveFilters = ({ 
    filterMatrices, 
    totalFilteredSize, 
    totalSize,
    isLoading, 
    pageOffset, 
    pageLimit, 
    singularNoun, 
    pluralNoun,
    showTotals,
    applyFilter,
    applyFilterWithMatrix,
    showResetLink,
    refreshData
}: IProps) => {

    const renderFilterHeaderStr = () => {

        if (isLoading) {
            return null;
        }

        let numFiltersApplied = 0;

        for (let i = 0; i < filterMatrices.length; i++) {
            numFiltersApplied += filterMatrices[i].getActiveCount();
        }

        let str = '';

        if (showTotals) {
            if (numFiltersApplied === 0) {
                str = `All ${totalFilteredSize} ${pluralNoun}`;
            } else {
                str = totalFilteredSize > 0 
                ? `${totalFilteredSize} ${totalFilteredSize === 1 ? 'match' : 'matches'} from all ${totalSize} ${pluralNoun}`
                : `No ${pluralNoun.toLowerCase()} found from all ${totalSize} ${pluralNoun}`;
            }

        } else {

            const resultCount = totalFilteredSize !== undefined 
            ? `${totalFilteredSize.toLocaleString()} ${totalFilteredSize === 1 ? singularNoun : pluralNoun}` : '';

            const startOffset = pageOffset + 1;
            const endOffset = Math.min(pageOffset + pageLimit, totalFilteredSize); 

            str = endOffset > 0 
                ? `Showing ${startOffset} to ${endOffset} of ${resultCount}`
                : `No ${pluralNoun.toLowerCase()} found`;
        }

        return (
            <>
            {
                numFiltersApplied ? 
                <div className="mb-1">
                    <strong className="text-md md:text-lg me-1 inline-block">{ str }</strong> <span className="text-brand_grey-medium text-md inline-block">({numFiltersApplied ? numFiltersApplied : 'No'} filter{ numFiltersApplied !== 1 ? 's' : '' } applied)</span>
                </div> 
                : 
                <strong className="text-md md:text-lg lg:text-xl lg:whitespace-nowrap">
                    { str }
                </strong>
            }
          </>
        );
    };

    const resetFilterMatrices = () => {
        filterMatrices.forEach((filterMatrix: any) => {
            filterMatrix.reset();
            refreshData();
        });
    };

    const renderResetLink = () => {
        if (!showResetLink) {
            return <></>;
        }

        let count = 0;

        filterMatrices.forEach((filterMatrix: any) => {
            count += filterMatrix.getActiveCount();
        });

        if (!count) {
            return <></>;
        }

        return (
            <span className="cursor-pointer link inline-block" onClick={() => resetFilterMatrices()}>
                Remove filters to view all reviews
            </span>
        );
    };

    const renderActiveFilters = (): any => {
        let labels: any[] = [];

        for (let i = 0; i < filterMatrices.length; i++) {
            filterMatrices[i].filters.forEach((filterItem: any) => {
                const textLabel = filterMatrices[i].getTextLabel(filterItem);

                if (filterItem && textLabel) {
                    labels.push({
                        label: textLabel,
                        key: filterItem.Key,
                        matrix: filterMatrices[i]
                    });
                }
            });
        }

        labels = labels.map((item: any) => {
            const split = item.label.split(':');
            const label = (split[0] || '').trim();
            const value = (split[1] || '').trim();

            return {
                label,
                value,
                key: item.key,
                matrix: item.matrix
            };
        }).filter((item: any) => item.label);

        if (!labels.length) {
            return null;
        }

        return (
            <ul className="flex justify-center items-center flex-wrap gap-x-2 gap-y-1 text-md md:justify-start md:me-12">
                {
                    labels.map((item: any, index: number) => {
                        return (
                            <li
                                key={`active-filter-${index}`}
                                onClick={() => applyFilterWithMatrix ? applyFilterWithMatrix(item.matrix, item.key, null) : applyFilter(item.key, null, true)}
                                className="bg-brand_tertiary text-white flex items-center gap-y-1 gap-x-2 py-1 px-1.5 rounded-md cursor-pointer text-xs lg:text-sm leading-tight"
                            >
                                <div className="">
                                    <strong className="font-semibold me-1.5">
                                        {item.label}{item.value.length ? ':' : ''}
                                    </strong>
                                    {item.value}                                    
                                </div>
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className="min-w-3.5 min-h-3.5"
                                />
                            </li>
                        );                   
                    })
                }
                { renderResetLink() }
            </ul>
        );
    };

    return (
        <div>
            { renderFilterHeaderStr() }
            { renderActiveFilters() }
        </div> 
    );
};


export default ActiveFilters;