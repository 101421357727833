import { TableColumn } from 'react-data-table-component';

interface ITourType {
    TourTypeID: TourType | null;
    TourTypeName: string;
    TourMenuName: string;
    TourTypeSequence: number;
    KeyName: string;
}

interface ITourAvailability {
    NHID: number;
    TourAvailabilityID?: number;
    IsOrganization: boolean;
    InheritedAvailabilityDetailsFromOrg?: boolean;
    IsVerified: boolean;
    TourTypeID: TourType | null;
    TourTypeName: string;
    ParentOrgName: string | null;
    PhysicalTourDays: number[] | string;
    PhysicalTourStartTime: string;
    PhysicalTourEndTime: string;
    PhysicalDescription: string;
    UpdatedAt: string;
    UpdatedBy: string;
    VirtualTourDays: number[] | string;
    VirtualTourStartTime: string;
    VirtualTourEndTime: string;
    VirtualDescription: string;
}

interface ITourFormData extends ITourAvailability {
    PhysicalTourDays: number[];
    VirtualTourDays: number[];
}
interface ITourDefaultValues extends ITourAvailability {
    PhysicalTourDays: string;
    VirtualTourDays: string;
}

enum TourType {
    Both_onsite_remote = '1',
    Onsite = '2',
    Remote = '3',
    No_tours = '4'
}

type TourScheduling =
    | 'OnsiteTourTimes'
    | 'OnsiteTourDays'
    | 'RemoteTourTimes'
    | 'RemoteTourDays';

interface ITourAvailabilityTableColumn
    extends TableColumn<ITourDefaultValues & { ProfileComplete: boolean }> {
    id: keyof ITourDefaultValues | 'Actions' | TourScheduling | 'ProfileComplete';
}

export {
    type ITourAvailabilityTableColumn,
    type ITourFormData,
    type ITourDefaultValues,
    type ITourType,
    TourType
};
