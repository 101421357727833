import { IGetPortalPropertyWebAndCardLogoAsset } from '../../../interfaces/asset';
import * as assetApi from '../../../api/assets';

export const getOrgPropertiesLogos = async ( orgNHID: number, noLogoPropertyFilter: boolean ): Promise<IGetPortalPropertyWebAndCardLogoAsset[]> => {
    if (!orgNHID) {
        console.error('getOrgPropertiesLogos: Invalid orgNHID');
        return null;
    }

    const filters = new Map<string, any>();
    if (noLogoPropertyFilter !== null) {
        filters.set('NoLogo', noLogoPropertyFilter);
    }
    return await assetApi.getOrgPropertiesLogos(orgNHID, filters);
};

export const getPropertiesLogos = async ( nhIDs: number[], noLogoPropertyFilter: boolean ): Promise<IGetPortalPropertyWebAndCardLogoAsset[]> => {
    const filters = new Map<string, any>();
    if (noLogoPropertyFilter !== null) {
        filters.set('NoLogo', noLogoPropertyFilter);
    }
    return await assetApi.getPropertiesLogos(nhIDs, filters);
};

export const getPropertyLogos = async ( nhID: number[], orgNHID?: number ): Promise<IGetPortalPropertyWebAndCardLogoAsset[]> => {
    if(nhID[0] === orgNHID) {
       return await getOrgPropertiesLogos(orgNHID, false);
    } 
    return await getPropertiesLogos(nhID, false);
};