import { 
    IPropertyCoreDetailNHID,
    IPropertyCoreDetailInsuranceType,
    ActiveInsuranceCount,
    TotalInsuranceCount
} from '../../../../interfaces/property';
import { isDefined } from '../../../../utils/common';

export const getMinAgeRequirement = (hasMinAgeRequirement: boolean | null, minAge?: number): string => {
    if (typeof hasMinAgeRequirement !== 'boolean' || !minAge) {
        return 'Unknown';
    }
    return hasMinAgeRequirement ? `Yes (${minAge})` : 'No';
};

export const yesNoOrUnknown = (value: boolean | null): string => {
    if (value === null) {
        return 'Unknown';
    }
    return value ? 'Yes' : 'No';
};

export const valueOrPlaceholder = (value: string | number | null, placeholder: string = 'Unknown'): string => {
    if (!isDefined(value)) {
        return placeholder;
    }

    return value.toString();
};

export const getRoomTypesOffered = (item: IPropertyCoreDetailNHID): string[] => {
    const roomTypeArray = [];
    if (item.CoreDetails) {
        const types = [
            ['RoomTypePrivateRoom', 'Private'],
            ['RoomTypePrivateSuite', 'Private Suite'],
            ['RoomTypeSemiPrivate', 'Semi Private'],
            ['RoomTypeShared', 'Shared']
        ];

        for (const type of types) {
            if ((item.CoreDetails as any)[type[0]] === true) {
                roomTypeArray.push(type[1]);
            }
        }

        if (item.CoreDetails?.RoomTypeOther) {
            roomTypeArray.push(item.CoreDetails.RoomTypeOther);
        }
    }

    if (!roomTypeArray.length) {
        roomTypeArray.push('None Selected');
    }

    return roomTypeArray;
};

export const formatCostOrUnknown = (cost: number | string): string => {
    if (!cost) {
        return 'Unknown';
    }
    return `$${cost}`;
};

export const getInsuranceDetails = (item: IPropertyCoreDetailInsuranceType[]): [ActiveInsuranceCount, TotalInsuranceCount] => {
    if (!item) {
        return [0,0];
    }

    return [
        item.reduce((acc: number, val: IPropertyCoreDetailInsuranceType) => 
            acc + (val.IsActive ? 1 : 0), 0),
        item.length
    ];
}; 