import { useEffect, RefObject } from 'react';

interface UseSyncScrollProps {
  primaryRef: RefObject<HTMLElement>;
  syncedSelector: string;
}

export const useSyncScroll = ({ primaryRef, syncedSelector }: UseSyncScrollProps) => {
  useEffect(() => {
    const primary = primaryRef.current;
    const secondary = document.querySelector(syncedSelector);

    if (!primary || !secondary) {
      console.warn('Scroll elements not found');
      return;
    }

    const handlePrimaryScroll = () => {
      secondary.scrollLeft = primary.scrollLeft;
    };

    const handleSecondaryScroll = () => {
      primary.scrollLeft = secondary.scrollLeft;
    };

    primary.addEventListener('scroll', handlePrimaryScroll);
    secondary.addEventListener('scroll', handleSecondaryScroll);

    return () => {
      primary.removeEventListener('scroll', handlePrimaryScroll);
      secondary.removeEventListener('scroll', handleSecondaryScroll);
    };
  }, [primaryRef, syncedSelector]);
};