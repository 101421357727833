import React from 'react';
import { LoadingDots } from '../common/loading_dots';
import { getClientFormattedDate } from '../../utils/date';
import DisplayAsset from '../../components/assets/display_asset';
import UploadWidget from '../../components/cloudinary/upload_widget';
import * as assetApi from '../../api/assets';
import Swal from 'sweetalert2';

import { 
    NHAssetType,
    IPortalPropertyAsset,
    IPortalPropertyAssetObj,
    ICloudinaryData,
    IAssetUploadLog,
    IAssetPayLoad
 } from '../../interfaces/asset';
import { updateProfileCompleteness } from '../../utils/profile_completeness';
import { IPortalScoreItem } from '../../interfaces/property';
import { useSelector } from 'react-redux';

interface IProps {
    handleReloadPropertyVideos: (nhID: number[], profileCompletenessState: IPortalScoreItem, profileCompletenessFilterValue: boolean | null, orgNHID?: number) => Promise<IPortalPropertyAsset[]>;
    orgNHID?: number | null;
    nhIDs?: number[];
    selNHID: number | null;
    propertyVideos: IPortalPropertyAsset[];
    setPropertyVideos: (videos: IPortalPropertyAsset[]) => void;
    busyLoadingAsset: boolean;
    setBusyLoadingAsset: (busyState: boolean) => void;
    handleClose?: any;
    profileCompletenessFilterValue: boolean | null;
}

const IndividualPropertyVideos = ({
    handleReloadPropertyVideos,
    orgNHID,
    nhIDs,
    selNHID,
    propertyVideos,
    setPropertyVideos,
    busyLoadingAsset,
    setBusyLoadingAsset,
    handleClose,
    profileCompletenessFilterValue
}: IProps) => {

    const profileCompletenessState: IPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    const refreshNHID = orgNHID ? [orgNHID] : nhIDs ? nhIDs : [selNHID];
   
    const handleAssetUpload = async(cloudinaryData: ICloudinaryData): Promise<any> => {
        const assetPayLoad: IAssetPayLoad = { NHID: selNHID, AssetType: NHAssetType.Videos, ...cloudinaryData };
        const assetID = await assetApi.createAsset(assetPayLoad);

        updateProfileCompleteness();

        return {
            AssetID: assetID,
            TransformedAssetUrl: cloudinaryData.TransformedAssetUrl,
            Sequence: cloudinaryData.Sequence,
            FileName: cloudinaryData.TransformedAssetUrl.match(/([^/]+)(?=\.\w+$)/)[0]
        };
    };
    
    const handleShowAssetUploadResult = async(assetUploadLog : IAssetUploadLog[]) => {
        let hasAssetUploaded = false;
        const currentDate = new Date();
        const assetObj:IPortalPropertyAssetObj[] = [];
        for (const item of assetUploadLog) {
            if(item.Type === 'success') {
                hasAssetUploaded = true;
                break;
            }
        }

        for (const item of assetUploadLog) {
            assetObj.push({
                AssetID: item.AssetDetail.AssetID,
                FileName: item.AssetDetail.FileName,
                Sequence:  item.AssetDetail.Sequence,
                TransformedAssetUrl: item.AssetDetail.TransformedAssetUrl,
                CreatedDate: `${currentDate.getFullYear()}-${currentDate.getMonth()+1}-${currentDate.getDate()}`
            });
        }

        if( !hasAssetUploaded ) {
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while uploading the video, please try again.',
                icon: 'warning',
                iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
                confirmButtonText: 'Okay',
                allowOutsideClick: false,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success m-1',
                }
            });
        } else {
            try {
                setBusyLoadingAsset(true);
                const updatedPropertyVideos = await handleReloadPropertyVideos(refreshNHID, profileCompletenessState, profileCompletenessFilterValue, orgNHID);
                setPropertyVideos(updatedPropertyVideos);    
                handleClose ?. ();       
            } catch (err) {
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to upload the video.',
                    icon: 'warning',
                    iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
                    confirmButtonText: 'Okay',
                    allowOutsideClick: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-success m-1',
                    }
                });
            } finally {
                updateProfileCompleteness();
                setBusyLoadingAsset(false);
            }
        }
    };

    const handleDelete = () => {
        Swal.fire({
            title: 'Delete Video',
            text: 'Are you sure you want to delete this video?',
            icon: 'warning',
            iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-success m-1',
                denyButton: 'btn btn-error m-1',
                cancelButton: 'btn btn-error m-1'
            }
        }).then(async (result: any) => {
            if (result.value) {
                setBusyLoadingAsset(true);
                try {
                    const deletedAssetID:number = await assetApi.deletePropertyVideo(propertyVideos[0]?.NHID);

                    if (!deletedAssetID) {
                        throw new Error('Failed to delete Video');
                    }

                    const updatedPropertyVideos = await handleReloadPropertyVideos(refreshNHID, profileCompletenessState, profileCompletenessFilterValue, orgNHID);
                    setPropertyVideos(updatedPropertyVideos);
                    handleClose ?. ();
                } catch (err) {
                    Swal.fire({
                        title: 'Error',
                        text: 'Failed to delete the video.',
                        icon: 'warning',
                        iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>',
                        confirmButtonText: 'Okay',
                        allowOutsideClick: false,
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'btn btn-success m-1',
                        }
                    });
                } finally {
                    setBusyLoadingAsset(false);
                }
            }
        });
    };

    const renderIndividualPropertyVideos = () => {
        const property = propertyVideos && propertyVideos.length > 0 ? propertyVideos[0] : null;
        return (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-5 2xl:gap-10">
                <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                    <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
                        <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                            Video for {property?.Property ? property.Property.Name : ''}
                        </h4>
                    </div>
                    <div className="p-3 sm:p-4 2xl:p-5 h-full">
                        { property?.Asset.length > 0 ?
                            property.Asset.map((video:IPortalPropertyAssetObj) => {
                                return (
                                    <div key={video.AssetID} className="inline-block [&>video]:object-cover [&>video]:rounded-t-md [&>video]:min-w-72 [&>video]:max-w-72 [&>video]:h-44">
                                        <DisplayAsset
                                            TransformedAssetUrl={video.TransformedAssetUrl}
                                            FileName={video.FileName}
                                            AssetType={NHAssetType.Videos} 
                                        />
                                        <div className="p-2 text-2xs border-x border-b border-brand_grey">
                                            Date uploaded: {video.CreatedDate ? getClientFormattedDate(video.CreatedDate) : ''}
                                        </div>
                                        <ul className="flex gap-2 justify-between p-2 text-2xs border-x border-b border-brand_grey rounded-b-md mt-auto">
                                            <li>
                                                <a
                                                    href=""
                                                    className="link"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleDelete();
                                                    }}
                                                >
                                                    Delete
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                );
                            }) :
                            <span className="msg msg-warning">
                                No video uploaded
                            </span>
                        }
                    </div>
                </div>
                <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg">
                    <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-1 sm:py-2 2xl:py-3 min-h-14 flex items-center justify-between gap-2">
                        <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                            Upload a New Video
                        </h4>
                    </div>
                    <div className="p-3 sm:p-4 2xl:p-5 h-full">
                        <div className="grid gap-3 sm:gap-4 2xl:gap-5">
                            <UploadWidget 
                                nHID = {selNHID}
                                fileName={property?.Property.Slug}
                                assetTypeName = 'Select Video to Upload' 
                                assetType = {NHAssetType.Videos}
                                onAssetUpload = {handleAssetUpload}
                                showAssetUploadResult = {handleShowAssetUploadResult}
                            />
                            <div className="grid gap-2 text-sm">
                                <p>
                                    Please note maximum file size 1GB.
                                </p>
                                <p>
                                    Large video files can take up to 5 minutes to upload on slower connections.
                                </p>
                                <p>
                                    If in doubt please refresh the page after a couple of minutes.
                                </p>
                            </div>
                            <div className="ring-1 ring-brand_secondary p-3 sm:p-4 xl:p-6 rounded-md sm:rounded-lg text-md">
                                <strong className="text-md md:text-lg lg:text-xl">
                                    Uploading Videos - Tips &amp; Terms
                                </strong>
                                <ul className="marker:text-brand_grey-medium mt-3 list-disc ps-5 space-y-2 text-sm">
                                    <li>
                                        Your Video Uploaded will automatically be saved as an .mp4 file, resized and cropped to best fit our video template (360 pixels height). If you are not happy with the automatic resize and crop please upload a pre-formatted video to the exact video template specification.
                                    </li>
                                    <li>
                                        nursinghomes.com reserve the right to remove any videos we deem unsuitable.
                                    </li>
                                    <li>
                                        <strong>By pressing Upload button, I/we agree to the terms & conditions set out on <a className="link" href="https://www.nursinghomes.com/terms" target="_blank">www.nursinghomes.com/terms</a></strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <span className="msg msg-warning mt-3">
                            You are currently allowed to upload one video. If you re-upload a video, it will overwrite the existing video. Please note if you have recently uploaded any videos it might take up to 60 minutes to appear on this page.
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <LoadingDots show={busyLoadingAsset} />
            { renderIndividualPropertyVideos() }
        </>
    );
};

export default IndividualPropertyVideos;