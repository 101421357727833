import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ExpressionType, rangeSelectOptions, SqlRange } from '../../../../interfaces/filter_matrix';
import { ISelectOption } from '../../../../interfaces/form';
import { IRangeFilterTypeProps } from '../types';

export const RangeFilterType = ({
    filterMatrix,
    filterKey,
    apply,
}: IRangeFilterTypeProps) => {
    const [filterValue, setFilterValue] = useState(new SqlRange(undefined));
    const currentFilterItem = filterMatrix.filters.find(filter => filter.Key === filterKey);

    useEffect(() => {
        if (filterValue.operation !== currentFilterItem?.Value?.operation) {
            if (currentFilterItem?.Value?.operation) {
                setFilterValue(new SqlRange(currentFilterItem.Value.operation, currentFilterItem.Value.from, currentFilterItem.Value.to));
                return;
            } 
            setFilterValue(new SqlRange(undefined));
            return;
        }
    }, [filterMatrix.uuid, currentFilterItem.Value]);

    const resetRangeFilter = (filterKey: string) => {
        setFilterValue(new SqlRange(undefined));
        apply(filterKey, new SqlRange(undefined));
    };

    const onRangeTypeChange = (key: string, operation: ISelectOption): SqlRange => {
        const newOpcode = operation.value as ExpressionType || undefined;

        if (
            filterValue.operation && 
            ((newOpcode === ExpressionType.In && filterValue.operation !== ExpressionType.In) ||
            (newOpcode !== ExpressionType.In && filterValue.operation === ExpressionType.In))
        ) {
            setFilterValue(new SqlRange(newOpcode));
            return;
        }

        if (newOpcode === ExpressionType.Between) {
            setFilterValue(new SqlRange(newOpcode, filterValue.from, filterValue.to));
            if (filterValue.from && filterValue.to) {
                apply(key, new SqlRange(newOpcode, filterValue.from, filterValue.to));
            }
        } else if (filterValue.from) {
            setFilterValue(new SqlRange(newOpcode, filterValue.from));
            apply(key, new SqlRange(newOpcode, filterValue.from));
        } else {
            setFilterValue(new SqlRange(newOpcode));
        }
    };

    const onRangeFromChange = (key: string, value: string, maxAllowedLength?: number): SqlRange => {
        // Handle defaulting the opcode choice to 'equals'
        if (typeof filterValue === 'string') {
            if (validateRangeFilterValue(value, ExpressionType.Equal, maxAllowedLength)) {
                setFilterValue(new SqlRange(ExpressionType.Equal, value));
                return;
            } else {
                return;
            }
        }

        if (!validateRangeFilterValue(value, filterValue.operation, maxAllowedLength)) {
            return;
        }

        if (filterValue.operation === ExpressionType.Between) {
            setFilterValue(new SqlRange(filterValue.operation, value, filterValue.to));
            if (value && filterValue.to) {
                apply(key, new SqlRange(filterValue.operation, value, filterValue.to));
            }
            return;
        }

        setFilterValue(new SqlRange(filterValue.operation, value));
        if (value) {
            apply(key, new SqlRange(filterValue.operation, value));
        }
    
    };

    const onRangeToChange = (key: string, newToValue: string, maxAllowedLength?: number): SqlRange => {
        if (!validateRangeFilterValue(newToValue, filterValue.operation, maxAllowedLength)) {
            return;
        }

        setFilterValue(new SqlRange(filterValue.operation, filterValue.from, newToValue));

        if (filterValue.from && newToValue) {
            apply(key, new SqlRange(filterValue.operation, filterValue.from, newToValue));
        }
    };

    const validateRangeFilterValue = (val: string, operation: string, maxAllowedLength: number): boolean => {
        if (!val.length) {
            return true;
        }

        if (operation === ExpressionType.In) {
            const splitArray = (val || '')
            .split(',')
            .filter(item => item && item.length)
            .map(item => item.replace(/\s+/g, ''));

            if (!splitArray.length) {
                return false;
            }

            const valArray = splitArray.map((item: any) => !!(isFinite(item) && item !== ''));
            return !valArray.includes(false);
        } else {
            const input: any = val.replace(/\s+/g, '');
            return !!(isFinite(input) && input !== '' && (maxAllowedLength === undefined || input.length <= maxAllowedLength));
        }
    };
        
    const notEnteredOption = filterMatrix.getNotEnteredOption(filterKey, false);

    const rangeOptions = rangeSelectOptions(notEnteredOption);

    const { operation, from, to } = filterValue;
    const currentValue = rangeOptions.find(
        (o: ISelectOption) => o.value === operation
    );

    return <div className="flex flex-wrap items-end gap-2" key={`${currentFilterItem.Key}`}>
            <div className="form-component form-inline form-select min-w-[19rem] md:min-w-80">
                <label 
                    htmlFor={currentFilterItem.Key}
                >
                    {currentFilterItem.FilterFormLabel}
                </label>
                <Select
                    id={currentFilterItem.Key}
                    options={rangeOptions}
                    name={currentFilterItem.FilterPropName}
                    classNamePrefix="select"
                    value={currentValue || null}
                    onChange={(e) => onRangeTypeChange(currentFilterItem.Key, e)}
                    unstyled
                />
            </div>
            <div className="flex gap-2  items-center">
                <div className="form-component form-textbox min-w-20 max-w-20">
                    <input
                        value={from || ''}
                        onChange={(e) => onRangeFromChange(currentFilterItem.Key, e.target.value)}
                    />                
                </div>
                {operation === ExpressionType.Between ? 
                <div className="form-component form-textbox min-w-20 max-w-20">
                    <input
                        value={to || ''}
                        onChange={(e) => onRangeToChange(currentFilterItem.Key, e.target.value)}
                    />
                </div>
                : null
                }
                <a
                    className="text-xs link"
                    onClick={() => resetRangeFilter(currentFilterItem.Key)}
                >
                    Clear
                </a> 
            </div>                     
        </div>;
};
