import React, { FC } from 'react';
import {  options, Value, Option, valueMap, getInitialOption } from './helpers';
import { ButtonGroup } from '../../../../../components/button_group/button_group';
import { ProfileCompletenessTag } from '../../../property_core_details/tabs/components/profile_completeness_tag';

interface IProps {
    onChange?: (selectedValue: Value) => void;
    value: Value;
}

const ProfileCompletenessFilter: FC<IProps> = ({ onChange, value }) => {

    const handleChange = (item: Option ) => {
        // convert the button group option into boolean or null
        const { value } = item;
        onChange(valueMap[value]);
    };
    
    return (
        <>
            <ProfileCompletenessTag direction='right' isVisible={true} />
            <ButtonGroup 
                options={options as unknown as Option[]} 
                onChange={onChange ? handleChange : null} 
                value={getInitialOption(value)} 
            />
        </>
    );
};

export { ProfileCompletenessFilter };
