import React, { useEffect, useState } from 'react';
import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import MultiOrgPropertyPhotos from '../../../components/assets/multi_org_property_photos';
import IndividualPropertyPhotos from '../../../components/assets/individual_property_photos';
import { useLocation } from 'react-router-dom';
import { ISessionState, SessionType } from '../../../interfaces/session';
import { IPortalPropertyPhotoAsset } from '../../../interfaces/asset';
import { useSelector } from 'react-redux';
import * as assetApi from '../../../api/assets';
import { ProfileScoreType, IPortalScoreItem } from '../../../interfaces/property';
import ProfileCompletenessPhotoHeader from '../../../components/profile_completeness/profile_completeness_photo_header';
import { buildProfileCompleteMapForNHIDs } from '../../../utils/profile_completeness';
import { useCommunitySelectHistory } from '../../../hooks/use_community_select_history';
import { ITag } from '../../../interfaces/tag';
import { IMessageType } from '../../../interfaces/common';
import { 
    NHAssetType,
    TagType
} from '../../../interfaces/asset';
import { getOrgPropertiesPhotos, getPhotoTags, getPropertiesPhotos, getPropertyPhotos } from './utils';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}


const PropertyPhotos = ({ nhIDs, orgNHID }: IProps) => {
    const location = useLocation();
    const state = location?.state as any;
    const defaultNHID = state?.NHID || null;
    const [busyLoadingPhotos, setBusyLoadingPhotos] = useState<boolean>(true);
    const [selectedNHID, setSelectedNHID] = useState<number>(defaultNHID);
    const [propertyPhotos, setPropertyPhotos] = useState<any[]>([]);
    const [profileCompletenessFilterValue, setProfileCompletenessFilterValue ] = useState<boolean | null>(null);
    const [hasNonPublishedPhotosFilter, setHasNonPublishedPhotosFilter] = useState<boolean>(false);
    const [tagFilter, setTagFilter] = useState<any[]>([]);
    const [photoTags, setPhotoTags] = useState<ITag[]>([]);
    const [photoUploadMessages, setPhotoUploadMessages] = useState<IMessageType[]>([]);
    const [showPhotoModal, setShowPhotoModal] = useState<boolean>(false);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelectedNHID(selNHID);
        setTagFilter([]);    
    });

    const { Session }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const profileCompletenessState: IPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    const { SessionType: sessionType } = Session;
   
    const loadPropertyPhotos = async () => {
        if (!selectedNHID) {
            setPropertyPhotos(null);
            setBusyLoadingPhotos(true);
            try {
                const propertyPhotos = sessionType === SessionType.SessionTypeOrg
                    ? await getOrgPropertiesPhotos(orgNHID, hasNonPublishedPhotosFilter, profileCompletenessState, profileCompletenessFilterValue)
                    : await getPropertiesPhotos(nhIDs, hasNonPublishedPhotosFilter, profileCompletenessState, profileCompletenessFilterValue);

                setPropertyPhotos(propertyPhotos);
            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingPhotos(false);
            }
        } else {
            setBusyLoadingPhotos(false);
        }
    };

    useEffect(() => {
        const singleNHID = nhIDs && nhIDs.length === 1 ? nhIDs[0] : null;
        if (singleNHID) {
            setSelectedNHID(singleNHID);
        }
    }, [nhIDs, orgNHID]);

    useEffect(() => {
        if(photoTags.length === 0) {
            fetchPhotoTags();
        }

        if (!selectedNHID) {
            loadPropertyPhotos();
        }

        setBusyLoadingPhotos(true);
        (async () => {
            try {
                if (selectedNHID) {
                    const propertyPhotos = await getPropertyPhotos(
                        [selectedNHID], 
                        orgNHID, 
                        profileCompletenessState, 
                        hasNonPublishedPhotosFilter, 
                        profileCompletenessFilterValue
                    );
                    if (propertyPhotos) {
                        setPropertyPhotos(propertyPhotos);
                    }
                }
            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingPhotos(false);
            }
        })();
    }, [selectedNHID]);

    useEffect(() => {
        loadPropertyPhotos();
    }, [profileCompletenessFilterValue, hasNonPublishedPhotosFilter]);

    const fetchPhotoTags  = async () : Promise<void>  => {
        const data = await getPhotoTags();
            
        if (data) {
            setPhotoTags(data);
        }
    };
    
    return (
        <>
            <PageCommunitySelectWrapper
                label={'All Photos for'}
                handleSelect={handleSelect}
                selNHID={selectedNHID}
            />
            <ProfileCompletenessPhotoHeader
                selNHID={selectedNHID}
                nhIDs={nhIDs}
                orgNHID={orgNHID}
                handleProfileCompletenessFilter={setProfileCompletenessFilterValue}
                handlePhotoNotPublishedFilter={setHasNonPublishedPhotosFilter}
                nonPublished={hasNonPublishedPhotosFilter}
                profileCompletenessFilterValue={profileCompletenessFilterValue}
                profileScoreType={ProfileScoreType.Photo}
            />
            <section>
                <div className="container max-w-none 2xl:container mt-4 sm:mt-6 md:mt-8 2xl:mt-10">
                    {
                        nhIDs?.length !== 1 && !selectedNHID && propertyPhotos ?
                            <MultiOrgPropertyPhotos
                                handleSelectedNHID={setSelectedNHID}
                                propertyPhotos={propertyPhotos}
                                handleMultiPropertiesTagFilter={setTagFilter}
                                multiPropertiesTagFilterValue={tagFilter}
                                busyLoadingPhotos={busyLoadingPhotos}
                                setShowPhotoModal={setShowPhotoModal}
                            />
                    :
                        null
                    }
                    {
                        (nhIDs?.length === 1 || selectedNHID) && propertyPhotos ?
                            <IndividualPropertyPhotos
                                selectedNHID={selectedNHID}
                                nhIDs={nhIDs}
                                propertyPhotos={propertyPhotos}
                                setPropertyPhotos={setPropertyPhotos}
                                photoTags={photoTags}
                                busyLoadingPhotos={busyLoadingPhotos}
                                setBusyLoadingPhotos={setBusyLoadingPhotos}
                                photoUploadMessages={photoUploadMessages}
                                setPhotoUploadMessages={setPhotoUploadMessages}
                                handleReloadPropertyPhotos={getPropertyPhotos}
                                showPhotoModal={showPhotoModal}
                                setShowPhotoModal={setShowPhotoModal}
                            />
                        :
                            null
                    }
                </div>
            </section>
        </>
    );
};

export default PropertyPhotos;