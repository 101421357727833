const careOfferedFormHasUncompletedProfileCompletenessFields = (
    formData: any
) => {
    const careOffered = {
        LongTermCare: true,
        ShortTermCare: true,
        RespiteCare: true,
        MinAgeRequirement: true,
        MinAge: false,
        FacilityHasBehaviouralHealthUnit: true,
        FacilityHasMemoryCareUnit: true,
        OtherFacilityTypes: false,
        AffiliatedHospitals: false,
        AvgShortTermResidentsPc: true,
        AvgShortStayDuration: false,
        AvgLongTermResidentsPc: true,
        AvgLongTermStayDuration: false,
        AdmitPediatricResidents: true,
        PediatricAvgShortTermResidentsPc:
            formData['AdmitPediatricResidents'] === true,
        PediatricAvgShortStayDuration: false,
        PediatricAvgLongTermResidentsPc:
            formData['AdmitPediatricResidents'] === true,
        PediatricAvgLongTermStayDuration: false,
        PediatricMaxAge: false,
        PediatricMaxAgeRequirement: false,
        RoomTypeShared: true,
        RoomTypePrivateRoom: true,
        RoomTypeSemiPrivate: true,
        RoomTypePrivateSuite: true,
        RoomTypeOther: false
    };

    const fieldsIncludedInProfileCompleteness = Object.entries(careOffered)
        .map(([key, value]) => {
            return value === true ? key : null;
        })
        .filter(Boolean);

    return fieldsIncludedInProfileCompleteness.some(
        (fieldName: string) => formData[fieldName] === null
    );
};

const costFundingFormHasUncompletedProfileCompletenessFields = (
    formData: any
) => {
    const isLongTermCareSelected = formData['LongTermCare'] === true;
    const isRoomTypeSharedSelected = formData['RoomTypeShared'] === true;
    const isRoomTypePrivateRoomSelected =
        formData['RoomTypePrivateRoom'] === true;
    const isRoomTypeSemiPrivateSelected =
        formData['RoomTypeSemiPrivate'] === true;
    const isLongTermSharedCostPerDayNAChecked =
        formData['LongTermSharedCostPerDayNA'];
    const isLongTermPrivateCostPerDayNAChecked =
        formData['LongTermPrivateCostPerDayNA'];
    const isLongTermSemiPrivateCostPerDayNAChecked =
        formData['LongTermSemiPrivateCostPerDayNA'] === true;

    const canUseSharedRoomLongTerm =
        isLongTermCareSelected &&
        isRoomTypeSharedSelected &&
        !isLongTermSharedCostPerDayNAChecked;
    const canUsePrivateRoomLongTerm =
        isLongTermCareSelected &&
        isRoomTypePrivateRoomSelected &&
        !isLongTermPrivateCostPerDayNAChecked;
    const canUseSemiPrivateRoomLongTerm =
        isLongTermCareSelected &&
        isRoomTypeSemiPrivateSelected &&
        !isLongTermSemiPrivateCostPerDayNAChecked;

    const isShortTermCareSelected = formData['ShortTermCare'] === true;
    const isShortTermSharedCostPerDayNAChecked =
        formData['ShortTermSharedCostPerDayNA'];
    const isShortTermPrivateCostPerDayNAChecked =
        formData['ShortTermPrivateCostPerDayNA'];
    const isShortTermSemiPrivateCostPerDayNAChecked =
        formData['ShortTermSemiPrivateCostPerDayNA'];

    const canUseSharedRoomShortTerm =
        isShortTermCareSelected &&
        isRoomTypeSharedSelected &&
        !isShortTermSharedCostPerDayNAChecked;
    const canUsePrivateRoomShortTerm =
        isShortTermCareSelected &&
        isRoomTypePrivateRoomSelected &&
        !isShortTermPrivateCostPerDayNAChecked;
    const canUseSemiPrivateRoomShortTerm =
        isShortTermCareSelected &&
        isRoomTypeSemiPrivateSelected &&
        !isShortTermSemiPrivateCostPerDayNAChecked;
    const isRespiteCareSelected = formData['RespiteCare'] === true;


    const isRespiteSharedCostPerDayNAChecked =
        formData['RespiteSharedCostPerDayNA'];
    const isRespitePrivateCostPerDayNAChecked =
        formData['RespitePrivateCostPerDayNA'];
    const isRespiteSemiPrivateCostPerDayNAChecked =
        formData['RespiteSemiPrivateCostPerDayNA'];
    const canUseSharedRoomRespite =
        isRespiteCareSelected &&
        isRoomTypeSharedSelected &&
        !isRespiteSharedCostPerDayNAChecked;
    const canUsePrivateRoomRespite =
        isRespiteCareSelected &&
        isRoomTypePrivateRoomSelected &&
        !isRespitePrivateCostPerDayNAChecked;
    const canUseSemiPrivateRoomRespite =
        isRespiteCareSelected &&
        isRoomTypeSemiPrivateSelected &&
        !isRespiteSemiPrivateCostPerDayNAChecked;

    const costFunding = {
        LongTermSharedCostPerDay: canUseSharedRoomLongTerm,
        LongTermSharedCostPerDayNA: false,
        LongTermPrivateCostPerDay: canUsePrivateRoomLongTerm,
        LongTermPrivateCostPerDayNA: false,
        LongTermSemiPrivateCostPerDay: canUseSemiPrivateRoomLongTerm,
        LongTermSemiPrivateCostPerDayNA: false,
        LongTermPrivateSuiteCostPerDay: false,
        LongTermRatesAllInclusive: false,
        LongTermRatePackagesAvailable: false,
        ShortTermSharedCostPerDay: canUseSharedRoomShortTerm,
        ShortTermSharedCostPerDayNA: false,
        ShortTermPrivateCostPerDay: canUsePrivateRoomShortTerm,
        ShortTermPrivateCostPerDayNA: false,
        ShortTermSemiPrivateCostPerDay: canUseSemiPrivateRoomShortTerm,
        ShortTermSemiPrivateCostPerDayNA: false,
        ShortTermPrivateSuiteCostPerDay: false,
        ShortTermRatesAllInclusive: false,
        ShortTermRatePackagesAvailable: false,
        RespiteSharedCostPerDay: canUseSharedRoomRespite,
        RespiteSharedCostPerDayNA: false,
        RespitePrivateCostPerDay: canUsePrivateRoomRespite,
        RespitePrivateCostPerDayNA: false,
        RespiteSemiPrivateCostPerDay: canUseSemiPrivateRoomRespite,
        RespiteSemiPrivateCostPerDayNA: false,
        RespitePrivateSuiteCostPerDay: false,
        RespiteMinStayRequirement: false,
        RespiteMinStay: false,
        RespiteRatesAllInclusive: false,
        RespiteRatePackagesAvailable: false,
        BedsAllDualLicenced: true,
        BedsMedicaidCount: true,
        BedsMedicareCount: true,
        PrivateInsurancePc: false,
        ManagedCareContractsInNetwork: true,
        AcceptMedicareAdvantage: true,
        AcceptMedicaidPending: true
    };

    const fieldsIncludedInProfileCompleteness = Object.entries(costFunding)
        .map(([key, value]) => {
            return value === true ? key : null;
        })
        .filter(Boolean);

    return fieldsIncludedInProfileCompleteness.some(
        (fieldName: string) => {
            if (formData[fieldName] === null) console.log(fieldName);
            return formData[fieldName] === null;
        }
    );
};

export {
    careOfferedFormHasUncompletedProfileCompletenessFields,
    costFundingFormHasUncompletedProfileCompletenessFields,
};
