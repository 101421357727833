import React, { ReactNode, useState } from 'react';
import {
    daysOptions,
    prepareFormValuesForSubmission,
    timeOptions
} from '../helpers';
import cx from 'classnames';
import { ITourFormData, TourType } from '../types';
import { Form } from '../../../../components/common/form';
import { FormSelect } from '../../../../components/common/form_select';
import { FormTextArea } from '../../../../components/common/form_text_area';
import { FormCheckBoxGroup } from '../../../../components/common/form_checkbox_group';
import { schema } from './book_a_tour_form.validation';
import { FormTourTypeSelect } from '../components/form_tour_type_select';
import { UseFormReturn } from 'react-hook-form';

interface Props {
    defaultFormValues: ITourFormData;
    handleSubmit: (payload: any) => void;
    id?: string;
    actionButton?: ReactNode | ((formMethods: UseFormReturn<ITourFormData>) => ReactNode)
}

const physicalTourDescription = (
    <div className="mt-3 grid gap-2">
        <p>
            <strong>Please include</strong> typical length of the tour, maximum
            number of participants, and any other information you think may help (eg
            arrive 10 minutes before, brief details about the tour / what to expect
            etc).
        </p>
        <p>
            <strong>Please Do Not include</strong> Phone Numbers / Email Addresses /
            Website Addresses, or non-informative text such as &apos;please contact
            us for more information&apos; - these will all be edited out.
        </p>
    </div>
);

const virtualTourDescription = (
    <div className="mt-3 grid gap-2">
        <p>
            <strong>Please include</strong> typical length of the tour and what
            Application(s) are used (eg Zoom / Teams / Facetime / WhatsApp etc), any
            other information you think may help (eg brief details about the tour /
            what to expect etc).
        </p>
        <p>
            <strong>Please Do Not include</strong> Phone Numbers / Email Addresses /
            Website Addresses, or non-informative text such as &apos;please contact
            us for more information&apos; - these will all be edited out.
        </p>
    </div>
);

function BookATourForm(props: Props) {
    const { actionButton, defaultFormValues } = props;
    const { NHID: nhID } = defaultFormValues;
    const [wasFormClicked, setWasFormClicked] = useState(false);

    const handleSubmit = (formValues: ITourFormData) => {
        props.handleSubmit(prepareFormValuesForSubmission(nhID, formValues));
    };

    const { InheritedAvailabilityDetailsFromOrg, TourTypeID } = defaultFormValues;
    const parentOrgHasTourDetails = !!TourTypeID;

    const inheritedInfo = (
        <span className="msg msg-info mb-6 lg:mb-8 2xl:mb-10">
            The tour availability details here have been inherited from {defaultFormValues?.ParentOrgName}. 
            You can add availability details below which will override the details set by {defaultFormValues?.ParentOrgName}.
        </span>
    );

    return (
        <>
            {InheritedAvailabilityDetailsFromOrg && parentOrgHasTourDetails && !wasFormClicked && inheritedInfo}
            <Form
                onSubmit={handleSubmit}
                validationSchema={schema}
                id={props.id || 'tourForm'}
                defaultValues={props.defaultFormValues}
            >
                {(formVals: UseFormReturn<ITourFormData>) => {
                    const tourType = formVals.watch('TourTypeID');
                    return (
                        <div
                            onClick={() => setWasFormClicked(true)}
                            className={cx('grid gap-6 lg:gap-8 2xl:gap-10', {
                                'opacity-50':
                                    InheritedAvailabilityDetailsFromOrg && !wasFormClicked
                            })}
                        >
                            <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap">
                                <strong className="font-medium lg:w-[250px] lg:text-lg">
                                    Please Select a Tour Type *
                                </strong>
                                <div className="flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10">
                                    <FormTourTypeSelect 
                                        asRadioGroup={true} 
                                    />                                    
                                </div>      
                            </div>
                            {tourType && tourType !== TourType.No_tours ? (
                                <div className="msg msg-info">
                                    The Days &amp; Time Ranges
                                    selected below will dictate
                                    what Days and Time can be
                                    selected on the tour booking
                                    form on your profile page.
                                    These are guideline Day and
                                    Time ranges, and you will be
                                    required to confirm
                                    availability directly with
                                    the contact who filled in
                                    the booking form (the form
                                    submission will be emailed
                                    to you).
                                </div>
                            ) : null}

                            {[
                                TourType.Both_onsite_remote,
                                TourType.Onsite
                            ].includes(tourType) ? (
                                <>
                                    <strong className="text-lg lg:text-xl xl:text-2xl block leading-tight">
                                        Onsite Tours - Tours conducted at the Facility
                                    </strong>
                                    <FormCheckBoxGroup
                                        groupLabel="Available Days"
                                        name="PhysicalTourDays"
                                        options={daysOptions}
                                        inline={true}
                                        inlineLabelWidth="large"
                                    />
                                    <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap">
                                        <strong className="font-medium lg:min-w-[250px] lg:max-w-[250px] lg:text-lg">
                                            Available Time Range *
                                        </strong>
                                        <div className="flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10 [&>div]:flex-row [&>div]:gap-4 [&>div]:items-center [&>div>label]:mb-0">
                                            <FormSelect
                                                name="PhysicalTourStartTime"
                                                id="PhysicalTourStartTime"
                                                label="From:"
                                                options={timeOptions}
                                                maxMenuHeight={150}
                                                inlineLabel={false}
                                                isSearchable={false}
                                            />
                                            <FormSelect
                                                name="PhysicalTourEndTime"
                                                id="PhysicalTourEndTime"
                                                label="To:"
                                                options={timeOptions}
                                                maxMenuHeight={150}
                                                inlineLabel={false}
                                                isSearchable={false}
                                            />                                   
                                        </div>      
                                    </div>                  
                                    <FormTextArea
                                        rows={6}
                                        name="PhysicalDescription"
                                        label="Onsite Tours Info"
                                        description={physicalTourDescription}
                                    />
                                </>
                            ) : null}
                            {[
                                TourType.Remote,
                                TourType.Both_onsite_remote
                            ].includes(tourType) ? (
                                <>
                                    <strong className="text-lg lg:text-xl xl:text-2xl block leading-tight">
                                        Remote Tours - Tours conducted via Zoom/WhatsApp etc
                                    </strong>
                                    <FormCheckBoxGroup
                                        groupLabel="Available Days"
                                        name="VirtualTourDays"
                                        options={daysOptions}
                                        inline={true}
                                        inlineLabelWidth="large"
                                    />
                                    <div className="flex flex-col gap-1 lg:flex-row lg:gap-2 lg:items-center lg:flex-wrap">
                                        <strong className="font-medium lg:min-w-[250px] lg:max-w-[250px] lg:text-lg">
                                            Available Time Range *
                                        </strong>
                                        <div className="flex flex-col gap-1.5 sm:flex-row sm:items-center sm:gap-6 lg:gap-10 [&>div]:flex-row [&>div]:gap-4 [&>div]:items-center [&>div>label]:mb-0">
                                            <FormSelect
                                                name="VirtualTourStartTime"
                                                id="VirtualTourStartTime"
                                                label="From:"
                                                options={timeOptions}
                                                maxMenuHeight={150}
                                                inlineLabel={false}
                                                isSearchable={false}
                                            />
                                            <FormSelect
                                                name="VirtualTourEndTime"
                                                id="VirtualTourEndTime"
                                                label="To:"
                                                options={timeOptions}
                                                maxMenuHeight={150}
                                                inlineLabel={false}
                                                isSearchable={false}
                                            />                                   
                                        </div>      
                                    </div>                  
                                    <FormTextArea
                                        rows={6}
                                        name="VirtualDescription"
                                        label="Remote Tours Info"
                                        description={virtualTourDescription}
                                    />
                                </>
                            ) : null}
                            { typeof actionButton === 'function' ? actionButton(formVals) : actionButton }
                        </div>
                    );
                }}
            </Form>
        </>
    );
}

export default BookATourForm;
