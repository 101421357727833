import { usePropertyCoreDetailsTableData } from '../../../../hooks/use_property_core_details_table_data';
import { DataTableSortOrder } from '../../../../../../components/common/data_table';
import { ProfileScoreType } from '../../../../../../interfaces/property';

export const useCareOffered = (nhID: number, keyName: string) => {
    const { filteredData } = usePropertyCoreDetailsTableData(
        'Name',
        DataTableSortOrder.Ascending,
        nhID,
        null,
        keyName as ProfileScoreType
    );

    return { filteredData };
}; 