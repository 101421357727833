import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ISessionState } from '../../interfaces/session';
import { LoadingDots } from '../common/loading_dots';
import DisplayAsset from '../../components/assets/display_asset';
import { NHAssetType, IPortalPropertyAsset, IPortalPropertyAssetObj} from '../../interfaces/asset';
import { getClientFormattedDate } from '../../utils/date';
import cx from 'classnames';
import ProfileCompletenessText from '../../components/profile_completeness/profile_completeness_text';
import { ProfileScoreType } from '../../interfaces/property';
import VideoUploadModal from './video_upload_modal';
import { Button } from '../common/button';

interface IProps {
    orgNHID: number | null;
    nhIDs: number[];    
    profileCompletenessFilterValue: boolean | null;
    propertyVideos: IPortalPropertyAsset[];
    setPropertyVideos: any
    busyLoadingAsset: boolean;
    setBusyLoadingAsset: any;
    handleReloadPropertyVideos: any;
}

const MultiOrgPropertyVideos = ({
    orgNHID,
    nhIDs,
    propertyVideos,
    setPropertyVideos,
    busyLoadingAsset,
    setBusyLoadingAsset,
    handleReloadPropertyVideos,
    profileCompletenessFilterValue
}: IProps) => {

    const [ showVideoModal, setShowVideoModal ] = useState<boolean>(false);
    const [ updateBrochureNHID, setUpdateBrochureNHID ] = useState<number>(null);
    const [ individualPropertyVideos, setIndividualPropertyVideos] = useState<any[]>([]);

    const sessionState: ISessionState = useSelector(
        ({SessionState}: any) => SessionState
    );

    const isOrgVideoExists: boolean = (propertyVideos && propertyVideos[0]?.Asset || []).length > 0 ? true : false;

    const handleOpenModal = async(
        nhID: number, 
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        const individaulPropertyVideos = await handleReloadPropertyVideos([nhID], null, profileCompletenessFilterValue, orgNHID);
        if (propertyVideos) {
            setIndividualPropertyVideos(individaulPropertyVideos);
        }
        setUpdateBrochureNHID(nhID);
        setShowVideoModal(true);
    };

    const renderMultiPropertiesVideos = () => {
        return (
            <>
                <div className="grid gap-3 sm:gap-5 md:gap-6 2xl:gap-7">
                    { propertyVideos?.map((property: IPortalPropertyAsset) => {
                        const { WebsiteURL, StateSlug, CitySlug, Slug } = property.Property;
                        const propertyIsAnOrg = orgNHID && orgNHID === property.NHID;
                        const url = propertyIsAnOrg ? `${WebsiteURL}/provider/${Slug}` : 
                        `${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`;
                        return (
                            <div className="ring-1 ring-brand_grey overflow-hidden rounded-md lg:rounded-lg flex flex-col" key={property.Property.Name}>
                                <div className="bg-brand_faint-blue px-3 sm:px-4 2xl:px-5 py-3 min-h-14 flex flex-col items-start xl:items-center xl:justify-between gap-2 xl:flex-row h-full">
                                    <div className="flex flex-wrap gap-y-1 items-center gap-x-2 w-full [&>em]:w-full">
                                        <h4 className="font-semibold leading-tight text-lg lg:text-xl">
                                            {property.Property.Name}
                                        </h4>
                                        { !propertyIsAnOrg ? 
                                            <span className="inline-block text-xs lg:text-sm leading-tight text-brand_grey-medium">
                                                {property.Property.Address}
                                            </span> : null
                                        }
                                        <a
                                            className="link text-xs bg-white px-2 py-1 inline-block leading-none rounded-lg"
                                            href={url}
                                            target="_blank" 
                                        >
                                            view on website
                                        </a>    
                                        { !propertyIsAnOrg ? 
                                            <ProfileCompletenessText
                                                NHID={property.Property.NHID}
                                                profileScoreType={ProfileScoreType.Video}
                                            /> : null
                                        }
                                    </div>
                                    <div className="xl:ms-auto">
                                        <Button 
                                            key={`update-property-video-${property.Property.NHID}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleOpenModal(property.Property.NHID, e);
                                            }}
                                        >
                                            {property.Asset.length > 0 ? 'Update Video' : 'Add Video'}
                                        </Button>
                                    </div>
                                </div>
                                <div className="p-3 sm:p-4 2xl:p-5 flex flex-wrap gap-3">
                                    {
                                        property.Asset.length > 0 ? 
                                        <ul className="gap-3 flex overflow-x-auto scrollbar scrollbar-full scrollbar-media [&>li]:max-w-72 [&>li]:bg-white [&>li]:relative [&>li>video]:object-cover [&>li>video]:rounded-t-md [&>li>video]:min-w-72 [&>li>video]:max-w-72 [&>li>video]:h-44">
                                            {property.Asset.map((video:IPortalPropertyAssetObj) => {
                                                return (
                                                    <li key={video.AssetID}>
                                                        <DisplayAsset
                                                            TransformedAssetUrl={video.TransformedAssetUrl}
                                                            FileName={video.FileName}
                                                            AssetType={NHAssetType.Videos} 
                                                        />
                                                        <div className="p-2 text-2xs border-x border-b border-brand_grey rounded-b-md">
                                                            Date uploaded: {video.CreatedDate ? getClientFormattedDate(video.CreatedDate) : ''}
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul> :
                                        <span className={cx('msg', isOrgVideoExists ? 'msg-info' : 'msg-warning')}>
                                            {isOrgVideoExists && orgNHID !== property.NHID ?
                                                'The uploaded Provider video will show on this Facility page' :
                                                ' No video uploaded'
                                            }
                                        </span>
                                    }
                                    {   
                                        propertyIsAnOrg ?
                                        <span className="msg msg-info">
                                            { isOrgVideoExists ? 
                                                'This Provider video will show on all Facility pages where no Facility video has been uploaded' :
                                                'If a Provider video is uploaded it will show on all Facility pages where no Facility video has been uploaded'
                                            }
                                        </span> : null
                                    }
                                </div>
                            </div>
                            );
                        })
                    }
                </div>
                {
                    showVideoModal ?
                        <VideoUploadModal
                            profileCompletenessFilterValue={profileCompletenessFilterValue}
                            handleClose={() => setShowVideoModal(false)}
                            handleReloadPropertyVideos={handleReloadPropertyVideos}
                            orgNHID={orgNHID}
                            nhIDs={nhIDs}
                            selNHID={updateBrochureNHID}
                            propertyVideos={individualPropertyVideos}
                            setPropertyVideos={setPropertyVideos}
                            busyLoadingAsset={busyLoadingAsset}
                            setBusyLoadingAsset={setBusyLoadingAsset}
                        />
                    : null
                }
            </>
        );
    };

    return sessionState.Org || (sessionState.Properties || []).length > 1 ? 
        <>
            <LoadingDots show={busyLoadingAsset} /> 
            { renderMultiPropertiesVideos() }
        </> : null;
};

export default MultiOrgPropertyVideos;