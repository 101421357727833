import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
    pcFull: number;
    width?: number;
}

const ProfileCompletenessIcon = ({ pcFull, width = 24 }: IProps) => {
    return (
        <svg viewBox="0 0 36 36" className={`mx-auto block min-w-${width} w-${width} h-${width} md:mx-0`}>
            <path className="fill-white stroke-brand_grey-light stroke-[4]" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
            <path className="fill-none stroke-brand_secondary stroke-[4] animate-[progress_1s_ease-out_forwards]" strokeDasharray={`${pcFull},100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
            <text x="18" y="11" className="text-[4px] fill-brand_secondary font-bold tracking-tighter text-anchor-middle">Profile</text>
            <text x="18" y="21.5" className="text-[10px] fill-brand_secondary font-bold tracking-tighter text-anchor-middle">{pcFull}%</text>
            <text x="18" y="27" className="text-[4px] fill-brand_secondary font-bold tracking-tighter text-anchor-middle">Complete</text>
        </svg>
    );
};

export default ProfileCompletenessIcon;
